import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import asyncService from '../../../datahub/asyncService';
import selectUser from '../../store/selectors/appSelector';
import { PRIMARY_PURPLE } from '../../theme/colorConstants';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const DropdownSearchBar = ({
  additionalFilters,
  onSubmitApiCallData,
  optionDisplayField,
  placeholder,
  sx,
  value,
  fullWidth,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = get('token', user);
  const refreshToken = get('refreshToken', user);

  const [loading, setLoading] = useState(open && options.length === 0);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      const filters = additionalFilters || [];

      const fieldsFilter = filters.find((f) => f.field === optionDisplayField);
      if (fieldsFilter) {
        filters.pop();
      }

      if (searchTerm) {
        filters.push({
          field: optionDisplayField,
          operation: 'icontains',
          value: searchTerm,
        });
      }

      const searchData = {
        filters,
      };

      setLoading(true);

      asyncService({
        ...onSubmitApiCallData,
        data: searchData,
        onSuccess: (d) => {
          const rawApiQueryResult = get(['data', 'documents'], d);

          setOptions(rawApiQueryResult);
          setLoading(false);
        },
        onError: () => {
          setOptions([]);
        },
        dispatch,
        token,
        refreshToken,
      });
    }, 750);

    return () => clearTimeout(delayedSearch);
  }, [
    searchTerm,
    dispatch,
    token,
    onSubmitApiCallData,
    optionDisplayField,
    refreshToken,
    additionalFilters,
  ]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, v) => option._id === v._id || option._id === v.ID}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          margin="normal"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            sx: { ...sx },
            endAdornment: (
              <Box>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Box>
            ),
          }}
          onChange={(e) => {
            setOptions([]);
            setSearchTerm(e.target.value);
          }}
        />
      )}
      sx={{
        '& .MuiAutocomplete-tag': {
          backgroundColor: PRIMARY_PURPLE[70],
          color: 'white',
        },
      }}
      onChange={onChange}
    />
  );
};

DropdownSearchBar.propTypes = {
  additionalFilters: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmitApiCallData: PropTypes.shape({}),
  optionDisplayField: PropTypes.string,
  placeholder: PropTypes.string,
  sx: PropTypes.shape({}),
  value: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};

export { DropdownSearchBar, SEARCHBAR_BACKGROUND };
