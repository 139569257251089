import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import AddDeleteAccordionListCard from '../../../../../components/AddDeleteAccordionListCard';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';
import { updateUser } from '../../../usersListReducer';

import { skillsAutoComplete } from './FormFieldData/UserDetailAccordionFormFieldData';
import { emptyContentTitleTextStyle } from './UserDetailAccordionCardsStyles';

const SkillWorkersAccordionCard = ({ userSkillList, isProcessing }) => {
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const skillListIds = userSkillList?.map((skills) => skills._id);

  const skillContentList = userSkillList?.map((content) => ({
    key: content._id,
    content: <Box>{content.name} </Box>,
    deleteApiRequest: {
      id,
      skillId: content._id,
    },
  }));

  const addContentFormComponentBody = () => (
    <Grid>
      <Box>{buildFormField(skillsAutoComplete({ userSkills: skillListIds }))}</Box>
    </Grid>
  );

  const submitAddSkill = (value) => {
    const submitSkillReqBody = {
      id,
      fields: {
        candidate: {
          skills: [...skillListIds, value.skills._id],
        },
      },
    };
    dispatch(updateUser(submitSkillReqBody));
  };
  const deleteSkill = (value) => {
    const deleteSkillsReqBody = {
      id,
      fields: {
        candidate: {
          skills: skillListIds.filter((ids) => ids !== value.skillId),
        },
      },
    };
    dispatch(updateUser(deleteSkillsReqBody));
  };

  const modalContent = {
    title: 'corporation.accordion.deleteSkillModalTitle',
    body: 'corporation.accordion.deleteModalBody',
  };

  const skillsDetailEmptyContentBody = (
    <Box>
      <Typography sx={emptyContentTitleTextStyle}>
        {intl.formatMessage({ id: 'userDetail.skillsAccordion.emptyTitleText' })}
      </Typography>
    </Box>
  );
  return (
    <AddDeleteAccordionListCard
      addButtonName="corporation.accordion.addSkills"
      addContentFormBody={addContentFormComponentBody()}
      accordionDetailEmptyContentBody={skillsDetailEmptyContentBody}
      accordionDetailContent={skillContentList}
      handleOnDelete={deleteSkill}
      modalContent={modalContent}
      isAddDeleteEnabled
      accordionSummaryTitle="userDetail.skills.summaryTitle"
      isProcessing={isProcessing}
      onAddFormSubmit={submitAddSkill}
    />
  );
};

SkillWorkersAccordionCard.propTypes = {
  userSkillList: PropTypes.arrayOf(PropTypes.shape({})),
  isProcessing: PropTypes.bool,
};
export default SkillWorkersAccordionCard;
