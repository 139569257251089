import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';

import { BLACK, PRIMARY_COLOR } from '../../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../../../utils';
import { PENDING_PLACEMENT_STATUS } from '../../../UsersConstants';

const Title = ({ text }) => (
  <Typography
    sx={{
      fontSize: '12px',
      color: BLACK[70],
      textTransform: 'uppercase',
      fontFamily: PRIMARY_FONT[300],
    }}
  >
    {text}
  </Typography>
);

Title.propTypes = {
  text: PropTypes.string,
};

const RowCards = ({ placements = [] }) =>
  placements.length > 0 ? (
    placements?.map((placement) => (
      <Grid
        container
        sx={{
          border: `1px solid ${BLACK[40]}`,
          borderRadius: '5px',
        }}
        key={placement?._id}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            height: '43px',
            backgroundColor: BLACK[10],
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[500],
              paddingLeft: '12px',
            }}
          >
            {LanguageConverter('userDetail.pendingWorkerPlacements.status')}
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[400],
              paddingLeft: '12px',
            }}
          >
            {(PENDING_PLACEMENT_STATUS.INVITE === placement?.state?.status &&
              LanguageConverter('userDetail.pendingWorkerPlacements.invitePending')) ||
              (PENDING_PLACEMENT_STATUS.APPLICATION === placement?.state?.status &&
                LanguageConverter('userDetail.pendingWorkerPlacements.applicationPending'))}
          </Typography>
        </Grid>
        <Grid container direction="row" flexWrap="nowrap" sx={{ padding: '12px' }}>
          <Grid container alignItems="center" direction="row" flexWrap="nowrap">
            <Grid container direction="column">
              <Title text={LanguageConverter('userDetail.pendingWorkerPlacements.company')} />
              <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                {placement?.corporation?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.pendingWorkerPlacements.position')} />
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: PRIMARY_FONT[300],
                color: PRIMARY_COLOR[70],
                textDecoration: 'underline',
              }}
            >
              {placement.jobOrder.name}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Title
              text={LanguageConverter('userDetail.pendingWorkerPlacements.startDateEndDate')}
            />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {`${epochToDateInReadableFormat(placement.start)}-${epochToDateInReadableFormat(
                placement.end
              )}`}
            </Typography>
          </Grid>
          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.pendingWorkerPlacements.times')} />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {`${epochToTimeInReadableFormat(placement.start)}-${epochToTimeInReadableFormat(
                placement.end
              )}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))
  ) : (
    <Grid
      container
      justifyContent="center"
      sx={{
        backgroundColor: BLACK[10],
        borderRadius: '5px',
        height: '40px',
      }}
    />
  );
RowCards.propTypes = {
  placements: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RowCards;
