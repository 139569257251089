export const DARKLY_TOGGLE_NAMES = {
  isMaintenanceModeEnabled: 'isMaintenanceModeEnabled',
  isTimesheetEnabled: 'isTimesheetEnabled',
  isAdminMetricsPageEnabled: 'isAdminMetricsPageEnabled',
  isEnterpriseDashboardEnabled: 'isEnterpriseDashboardEnabled',
  isSideBarDashboardButtonEnabled: 'isSideBarDashboardButtonEnabled',
  isLongTermJobCreationEnabled: 'isLongTermJobCreationEnabled',
  isRegisterEmployerEnabledOnSignUp: 'isRegisterEmployerEnabledOnSignUp',
  isRegisterEmployeeEnabledOnSignUp: 'isRegisterEmployeeEnabledOnSignUp',
  isRecruiterEmployeeCertificationBlocked: 'isRecruiterEmployeeCertificationBlocked',
  isCandidateCreationViaWorkflowEnabled: 'isCandidateCreationViaWorkflowEnabled',
  isWaeBhDecoupled: 'isWaeBhDecoupled',
  isLongTermJobDetailInMaintenanceMode: 'isLongTermJobDetailInMaintenanceMode',
  isGPSEnforcementPageInPTCreateDrawerOn: 'isGPSEnforcementPageInPTCreateDrawerOn',
  isShiftGroupToggleInJobCreateDrawerEnabled: 'isShiftGroupToggleInJobCreateDrawerEnabled',
  isWorkableGroupsV2Active: 'isWorkableGroupsV2Active',
  isJobApplicationSystemEnabled: 'isJobApplicationSystemEnabled',
  isRecruiterForbiddenToCreateCancelPlacements: 'isRecruiterForbiddenToCreateCancelPlacements',
  isNotificationCenterEnabled: 'isNotificationCenterEnabled',
  isSkillSectionForCandidateUserDetailEnabled: 'isSkillSectionForCandidateUserDetailEnabled',
  isPayrollV2Enabled: 'isPayrollV2Enabled',
  isHackathonTimesheetsViewEnabled: 'isHackathonTimesheetsViewEnabled',
  usePlacementsReadV2: 'usePlacementsReadV2',
};

const launchDarklyToggles = (flags, toggleName) =>
  ({
    // Enables or disables the admin's ability to view the metrics page
    [DARKLY_TOGGLE_NAMES.isAdminMetricsPageEnabled]: flags?.adminMetricsPageIsEnabled ?? true,

    [DARKLY_TOGGLE_NAMES.isEnterpriseDashboardEnabled]:
      flags?.isEnterpriseDashboardEnabled ?? false,

    // Enables or disables dashboard navigation on sidebar menu
    // toggles between true or false
    // By default, dashboard select will be disabled, darkly return value: false.
    [DARKLY_TOGGLE_NAMES.isSideBarDashboardButtonEnabled]: flags?.dashboardToggle ?? false,

    // Enables or disables the creation of long-term jobs
    // toggles between true or false
    // By default, the creation of long-term jobs will be enabled, darkly return value: true.
    [DARKLY_TOGGLE_NAMES.isLongTermJobCreationEnabled]: flags?.longTermJobCreate ?? true,

    // Enables or disables employer select option on register page
    // toggles between true or false
    // By default, employer registration will be enabled, darkly return value: true.
    [DARKLY_TOGGLE_NAMES.isRegisterEmployerEnabledOnSignUp]: flags?.registerEmployer ?? true,

    // Enables or disables employee select option on register page
    // toggles between true or false
    // By default, employee registration will be disabled, darkly return value: false.
    [DARKLY_TOGGLE_NAMES.isRegisterEmployeeEnabledOnSignUp]: flags?.registerEmployee ?? false,

    // Enables or disables timesheets
    // toggles between true or false
    // By default, timesheets will be disabled, darkly return value: false.
    [DARKLY_TOGGLE_NAMES.isTimesheetEnabled]: flags?.timesheetToggle ?? false,

    // toggles between true or false
    // By default, the maintenance page will be disabled, darkly return value: false.
    [DARKLY_TOGGLE_NAMES.isMaintenanceModeEnabled]: flags?.maintenanceMode ?? false,

    // toggles between true or false
    [DARKLY_TOGGLE_NAMES.isRecruiterEmployeeCertificationBlocked]:
      flags?.recruitersForbiddenToEditCertsOnCandidate ?? false,

    // toggles between true false for candidate creation via workflow.
    // By default, candidate creation via workflow is disabled.
    [DARKLY_TOGGLE_NAMES.isCandidateCreationViaWorkflowEnabled]:
      flags?.candidateCreationViaWorkflowToggle ?? false,

    // toggles between true false for Bullhorn decouple
    // By default, value is true.
    [DARKLY_TOGGLE_NAMES.isWaeBhDecoupled]: flags?.isWaeBhDecoupled ?? false,

    // toggles between true false for long-term job detail job maintenance mode.
    // By default, long-term job maintenance mode is disabled.
    [DARKLY_TOGGLE_NAMES.isLongTermJobDetailInMaintenanceMode]:
      flags?.longTermJobDetailsMaintenanceMode ?? false,

    // toggles between true false for GPS Enforcement Page on Position Template Create Drawer.
    // By default, GPS enforcement is disabled.
    [DARKLY_TOGGLE_NAMES.isGPSEnforcementPageInPTCreateDrawerOn]:
      flags?.ptCreateDrawerGpsEnforcementPageToggle ?? false,

    // toggles between true false for disabling the Shift Group Toggle on Job Create Drawer.
    // By default, Shift Group Toggle is disabled.
    [DARKLY_TOGGLE_NAMES.isShiftGroupToggleInJobCreateDrawerEnabled]:
      flags?.jobCreateDrawerShiftGroupToggle ?? false,

    // toggles between true false for Workable Groups V2.
    // By default, Workable Groups V2 is disabled.
    [DARKLY_TOGGLE_NAMES.isWorkableGroupsV2Active]: flags?.isWorkableGroupsV2Active ?? false,

    // toggles between true false for enabling the allow applications field on PTs.
    // By default, job applications are not allowed.
    [DARKLY_TOGGLE_NAMES.isJobApplicationSystemEnabled]:
      flags?.isJobApplicationSystemEnabled ?? false,

    // toggles whether a recruiter can add and remove candidates from job placements.
    [DARKLY_TOGGLE_NAMES.isRecruiterForbiddenToCreateCancelPlacements]:
      flags?.recruitersForbiddenToCreateAndCancelPlacements ?? false,

    // toggles between true false for disabling the Skill Section on Candidate User Detail.
    // By default, the Skill Section is disabled.
    [DARKLY_TOGGLE_NAMES.isSkillSectionForCandidateUserDetailEnabled]: flags?.skillSection ?? false,

    // toggles between true false for disabling the Shift Group Toggle on Job Create Drawer.
    // By default, Notification Center is disabled.
    [DARKLY_TOGGLE_NAMES.isNotificationCenterEnabled]: flags?.notificationCenterIsEnabled ?? false,

    // toggles between true false for Payroll V2.
    // By default, Payroll V2 is disabled.
    [DARKLY_TOGGLE_NAMES.isPayrollV2Enabled]: flags?.isPayrollV2Enabled ?? false,

    // toggles between true false for Hackathon Timesheets View.
    // By default, Hackathon Timesheets View is disabled.
    [DARKLY_TOGGLE_NAMES.isHackathonTimesheetsViewEnabled]:
      flags?.isHackathonTimesheetsViewEnabled ?? false,

    // toggles between true false for placements/read and v2/placements/read endpoint.
    // By default, uses placements/read.
    [DARKLY_TOGGLE_NAMES.usePlacementsReadV2]: flags?.usePlacementsReadV2 ?? false,
  }[toggleName]);

export default launchDarklyToggles;
