import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';

import {
  selectCertifications,
  selectCertificationsIsProcessing,
  selectDNAData,
  selectDNADataIsProcessing,
  selectDNRData,
  selectDNRDataIsProcessing,
  selectIsLoadingUsers,
} from '../../../../../../main-deprecated/store/selectors/userSelectors';
import { getSkills } from '../../../../../../main-deprecated/views/skillsAndCerts';
import {
  getCertificationsForUsers,
  getUserHistory,
} from '../../../../../../main-deprecated/views/users/reducer';
import {
  selectFavoriteCorporations,
  selectIsFavoriteCorporationsProcessing,
} from '../../../../../store/selectors/favoriteCorporationSelector';
import theme from '../../../../../theme';

import { getFavoriteCorporationList } from './Reducers/favoriteCorporationReducer';
import CertificationWorkerAccordionCard from './CertificationWorkerAccordionCard';
import DNAWorkersAccordionCard from './DNAWorkersAccordionCard';
import DNRWorkersAccordionCard from './DNRWorkersAccordionCard';
import FavoritesWorkersAccordionCard from './FavoritesWorkersAccordionCard';
import SkillWorkerAccordionCard from './SkillWorkerAccordionCard';

const UserDetailAccordionCards = ({ selectedUser }) => {
  const usersCerts = selectedUser?.candidate?.certifications;
  const usersSkills = selectedUser?.candidate?.skills;

  const favoriteCorporations = useSelector(selectFavoriteCorporations);
  const isFavoriteCorporationsProcessing = useSelector(selectIsFavoriteCorporationsProcessing);
  const isLoadingUsers = useSelector(selectIsLoadingUsers);
  const isUpdateDNALoading = useSelector(selectDNADataIsProcessing);
  const isUpdateDNRLoading = useSelector(selectDNRDataIsProcessing);
  const DNR = useSelector(selectDNRData);
  const DNA = useSelector(selectDNAData);
  const certifications = useSelector(selectCertifications);
  const IsProcessingCertifications = useSelector(selectCertificationsIsProcessing);
  const dispatch = useDispatch();
  const { id } = useParams();

  const styles = theme?.components?.userDetail;

  useEffect(() => {
    batch(() => {
      // Call for user favorites company by corporation
      dispatch(
        getFavoriteCorporationList({
          filters: [
            {
              field: 'favorite.waeID',
              value: id,
              operation: 'equalsID',
            },
          ],
        })
      );
      // Call for ALL certifications
      dispatch(getCertificationsForUsers());
      // Call for All Skills
      dispatch(getSkills());
    });
  }, [id, dispatch]);

  useEffect(() => {
    // Calls for User Summary (DNA/DNR)
    const userHistoryFilter = {
      page: 0,
      limit: 3,
      users: [id],
    };
    dispatch(getUserHistory(userHistoryFilter));
  }, [dispatch, id, isUpdateDNALoading, isUpdateDNRLoading]);

  // Generate all certification that the current viewed user has
  const generateUserCertificationList = useCallback(
    () =>
      usersCerts
        ?.map((certificateId) =>
          certifications.find((certificate) => certificate.id === certificateId)
        )
        .filter((res) => res !== undefined),
    [certifications, usersCerts]
  );

  return (
    <Grid sx={{ margin: theme.spacing(5, 0, 0, 0) }}>
      <Typography sx={{ ...styles.headerStyle, marginLeft: 2 }}>
        {LanguageConverter('userDetail.additionalInformation.header')}
      </Typography>
      <Grid container item direction="row" spacing={3.375}>
        <Grid item>
          <FavoritesWorkersAccordionCard
            favoriteList={favoriteCorporations}
            isProcessing={isFavoriteCorporationsProcessing}
          />
        </Grid>
        <Grid item>
          <DNAWorkersAccordionCard
            dnaList={DNA?.details}
            isProcessing={isLoadingUsers || isUpdateDNALoading}
          />
        </Grid>
        <Grid item>
          <DNRWorkersAccordionCard
            dnrList={DNR?.details}
            isProcessing={isLoadingUsers || isUpdateDNRLoading}
          />
        </Grid>
        <Grid item>
          <CertificationWorkerAccordionCard
            userCertificationList={generateUserCertificationList()}
            isProcessing={IsProcessingCertifications}
          />
        </Grid>
        <Grid item>
          <SkillWorkerAccordionCard
            userSkillList={usersSkills}
            isProcessing={IsProcessingCertifications}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

UserDetailAccordionCards.propTypes = {
  selectedUser: PropTypes.shape({
    candidate: PropTypes.shape({
      skills: PropTypes.arrayOf(PropTypes.shape({})),
      certifications: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};
export default UserDetailAccordionCards;
