import {
  composeValidators,
  isAnObject,
  isDuplicateByID,
} from '../../../../../../components/Form/validations';
import SearchBarSingle from '../../../../../../components/SearchBarV3/SearchBarSingle';
import { SEARCHBAR_BACKGROUND } from '../../../../../../components/SearchBarV3/styles';

export const corporationAutoComplete = (value) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'corporation',
  testId: 'corporation',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Corporation',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'corporations/read',
  },
  optionDisplayField: 'name',
  validate: isAnObject(value),
});

// Reason
export const reasonAutoComplete = ({ reasons, value }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'reason',
  testId: 'reason',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Reason',
  fullWidth: true,
  dropDownOptions: reasons,
  optionDisplayField: 'label',
  validate: isAnObject(value),
});

// Certs
export const certificationAutoComplete = ({ userCerts, value }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'certifications',
  testId: 'certifications',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Search Certifications',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'certifications/read',
  },
  optionDisplayField: 'name',
  validate: composeValidators(isAnObject(value), isDuplicateByID(userCerts)),
});

// Skills
export const skillsAutoComplete = ({ userSkills, value }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'skills',
  testId: 'skills',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Search Skills',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'skills/read',
  },
  optionDisplayField: 'name',
  validate: composeValidators(isAnObject(value), isDuplicateByID(userSkills)),
});
