import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Stack } from '@mui/material';

import StatusIcon from '../../../../main/assets/icons/StatusIconInFilters.svg';
import FreeTextSearchFilter, {
  SEARCHBAR_BACKGROUND,
} from '../../../../main/components/FreeTextSearchFilter';
import getStepData from '../../../../main/components/RemainingActionsCounterCard/getStepData';
import RemainingActionsCounterCard from '../../../../main/components/RemainingActionsCounterCard/RemainingActionsCounterCard';
import { SelectDropdownFilter } from '../../../../main/components/SelectDropdownFilter/SelectDropdownFilter';
import TimekeepingOverviewCard from '../../../../main/components/TimekeepingOverviewCard/TimekeepingOverviewCard';
import selectUser from '../../../../main/store/selectors/appSelector';
import {
  selectIsReloadDailyTimecards,
  selectIsTimekeepingOverviewDataLoading,
  selectIsWeeklyTimecardsLoading,
  selectTimekeepingOverviewData,
} from '../../../store/selectors/timeSheetSelector';
import { getTimekeepingOverviewData, getWeeklyTimecards } from '../reducer';

import LabeledField from './components/LabeledField';
import TimekeepingDayFilter from './components/TimekeepingDayFilter';
import WorkerTimecardSet from './components/WorkerTimecardSet';

const DailyTimekeeping = () => {
  const statusOptions = [
    { value: 'pending', name: LanguageConverterV2('timekeeping.status.pending') },
    { value: 'resolved', name: LanguageConverterV2('timekeeping.status.resolved') },
    { value: 'disputed', name: LanguageConverterV2('timekeeping.status.disputed') },
  ];

  const tagOptions = [
    { value: 'disputed', name: LanguageConverterV2('timekeeping.daily.tags.disputed') },
    { value: 'missingPunch', name: LanguageConverterV2('timekeeping.daily.tags.missingPunches') },
    { value: 'looksGood', name: LanguageConverterV2('timekeeping.daily.tags.looksGood') },
    { value: 'suspicious', name: LanguageConverterV2('timekeeping.daily.tags.suspicious') },
  ];

  const dispatch = useDispatch();
  const [startEpoch, setStartEpoch] = useState(Date.now());
  const [pageSize] = useState(5);

  const isWeeklyTimecardsLoading = useSelector(selectIsWeeklyTimecardsLoading);
  const isReloadDailyTimecards = useSelector(selectIsReloadDailyTimecards);
  const timekeepingOverviewData = useSelector(selectTimekeepingOverviewData);
  const isTimekeepingOverviewDataLoading = useSelector(selectIsTimekeepingOverviewDataLoading);
  const user = useSelector(selectUser);

  const [status, setStatus] = useState(null);
  const [tag, setTag] = useState(null);
  const [employeeName, setEmployeeName] = useState('');
  const [positionName, setPositionName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [filterAndSortData, setFilterAndSortData] = useState({
    page: 0,
    pageSize,
    startEpoch,
    filters: [],
  });

  const handleBackButtonClick = () => {
    const date = new Date(startEpoch);
    date.setDate(date.getDate() - 1);
    const newEpoch = date.getTime();
    setStartEpoch(newEpoch);
    setFilterAndSortData((prev) => ({ ...prev, startEpoch: newEpoch }));
  };

  const handleForwardButtonClick = () => {
    const date = new Date(startEpoch);
    date.setDate(date.getDate() + 1);
    const newEpoch = date.getTime();
    setStartEpoch(newEpoch);
    setFilterAndSortData((prev) => ({ ...prev, startEpoch: newEpoch }));
  };

  const handleStatusChange = useCallback(
    (newStatus) => {
      setStatus(newStatus);

      const excludedStatusFilters = filterAndSortData.filters.filter(
        (filter) => filter.field !== 'timecard.status'
      );
      if (!newStatus) {
        setFilterAndSortData((prev) => ({ ...prev, filters: excludedStatusFilters }));
      } else {
        const includedStatusFilters = [
          ...excludedStatusFilters,
          { value: newStatus, field: 'timecard.status', operation: 'equals' },
        ];
        setFilterAndSortData((prev) => ({ ...prev, filters: includedStatusFilters }));
      }
    },
    [filterAndSortData]
  );

  const handleTagChange = useCallback(
    (newTag) => {
      setTag(newTag);

      const excludedTagFilters = filterAndSortData.filters.filter(
        (filter) => filter.field !== 'timecard.tag'
      );
      if (!newTag) {
        setFilterAndSortData((prev) => ({ ...prev, filters: excludedTagFilters }));
      } else {
        const includedTagFilters = [
          ...excludedTagFilters,
          { value: newTag, field: 'timecard.tag', operation: 'equals' },
        ];
        setFilterAndSortData((prev) => ({ ...prev, filters: includedTagFilters }));
      }
    },
    [filterAndSortData]
  );

  const handleEmployeeSearchChange = useCallback(
    (newEmployeeFilter) => {
      const employeeSearchTerm = newEmployeeFilter?.[0]?.value;
      if (employeeSearchTerm !== employeeName) {
        setEmployeeName(employeeSearchTerm);

        const excludedEmployeeFilters = filterAndSortData.filters.filter(
          (filter) => filter.field !== 'jobOrder.name' // TODO: define!
        );
        if (!employeeSearchTerm) {
          setFilterAndSortData((prev) => ({ ...prev, filters: excludedEmployeeFilters }));
        } else {
          const includedEmployeeFilters = [
            ...excludedEmployeeFilters,
            { value: employeeSearchTerm, field: 'jobOrder.name', operation: 'icontains' },
          ];
          setFilterAndSortData((prev) => ({ ...prev, filters: includedEmployeeFilters }));
        }
      }
    },
    [filterAndSortData, employeeName]
  );

  const handlePositionSearchChange = useCallback(
    (newPositionFilter) => {
      const positionSearchTerm = newPositionFilter?.[0]?.value;
      if (positionSearchTerm !== positionName) {
        setPositionName(positionSearchTerm);

        const excludedPositionFilters = filterAndSortData.filters.filter(
          (filter) => filter.field !== 'jobOrder.name'
        );
        if (!positionSearchTerm) {
          setFilterAndSortData((prev) => ({ ...prev, filters: excludedPositionFilters }));
        } else {
          const includedPositionFilters = [
            ...excludedPositionFilters,
            { value: positionSearchTerm, field: 'jobOrder.name', operation: 'icontains' },
          ];
          setFilterAndSortData((prev) => ({ ...prev, filters: includedPositionFilters }));
        }
      }
    },
    [filterAndSortData, positionName]
  );

  const handleLocationSearchChange = useCallback(
    (newLocationFilter) => {
      const locationSearchTerm = newLocationFilter?.[0]?.value;
      if (locationSearchTerm !== locationName) {
        setLocationName(locationSearchTerm);

        const excludedLocationFilters = filterAndSortData.filters.filter(
          (filter) => filter.field !== 'jobOrder.name' // TODO: define!
        );
        if (!locationSearchTerm) {
          setFilterAndSortData((prev) => ({ ...prev, filters: excludedLocationFilters }));
        } else {
          const includedLocationFilters = [
            ...excludedLocationFilters,
            { value: locationSearchTerm, field: 'jobOrder.name', operation: 'icontains' },
          ];
          setFilterAndSortData((prev) => ({ ...prev, filters: includedLocationFilters }));
        }
      }
    },
    [filterAndSortData, locationName]
  );

  const handleDepartmentSearchChange = useCallback(
    (newDepartmentFilter) => {
      const departmentSearchTerm = newDepartmentFilter?.[0]?.value;
      if (departmentSearchTerm !== departmentName) {
        setDepartmentName(departmentSearchTerm);

        const excludedDepartmentFilters = filterAndSortData.filters.filter(
          (filter) => filter.field !== 'jobOrder.name' // TODO: define!
        );
        if (!departmentSearchTerm) {
          setFilterAndSortData((prev) => ({ ...prev, filters: excludedDepartmentFilters }));
        } else {
          const includedDepartmentFilters = [
            ...excludedDepartmentFilters,
            { value: departmentSearchTerm, field: 'jobOrder.name', operation: 'icontains' },
          ];
          setFilterAndSortData((prev) => ({ ...prev, filters: includedDepartmentFilters }));
        }
      }
    },
    [filterAndSortData, departmentName]
  );

  const previousFiltersRef = useRef();
  useEffect(() => {
    if (!isWeeklyTimecardsLoading) {
      const payload = {
        filters: filterAndSortData.filters,
        limit: filterAndSortData.pageSize,
        page: filterAndSortData.page,
      };
      if (!isEqual(previousFiltersRef.current, payload) || isReloadDailyTimecards) {
        previousFiltersRef.current = payload;
        dispatch(getWeeklyTimecards(payload));
      }
    }
  }, [dispatch, isWeeklyTimecardsLoading, filterAndSortData, isReloadDailyTimecards]);

  useEffect(() => {
    dispatch(getTimekeepingOverviewData({ start: 1742702400000, end: 1743307200000 }));
  }, [dispatch]);

  const counts = {
    step1: {
      count: timekeepingOverviewData?.timecardCounts?.readyToApproveOrResolve,
    },
    step2: {
      count: timekeepingOverviewData?.timecardCounts?.suspiciousOrMissingPunches,
    },
    step3: {
      count: timekeepingOverviewData?.timecardCounts?.disputed,
    },
    step4: {
      count: timekeepingOverviewData?.timecardCounts?.readyToFinalize,
    },
  };

  const stepData = getStepData(counts, user.role);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <TimekeepingDayFilter
          startEpoch={startEpoch}
          handleBackButtonClick={handleBackButtonClick}
          handleForwardButtonClick={handleForwardButtonClick}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <TimekeepingOverviewCard
          isLoading={isTimekeepingOverviewDataLoading}
          titleText={LanguageConverterV2('timekeeping.overviewCard.dailyOverview')}
          numOfTimeCards={timekeepingOverviewData?.timecardCounts?.total}
          regularHours={timekeepingOverviewData?.workedTimeEpoch?.regular}
          overTimeHours={timekeepingOverviewData?.workedTimeEpoch?.overtime}
          totalHours={timekeepingOverviewData?.workedTimeEpoch?.total}
        />
        {stepData?.map((step) => (
          <RemainingActionsCounterCard
            isLoading={isTimekeepingOverviewDataLoading}
            header={step?.header}
            subHeader={step?.subHeader}
            body={step?.body}
            count={step?.count}
            color={step?.color}
            buttonText={step?.buttonText}
            isCompleted={step?.isCompleted}
            key={step.header}
          />
        ))}
      </Box>
      <Stack gap="12px" mb="24px">
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <Box>
            <LabeledField
              title={LanguageConverterV2('timekeeping.filter.status')}
              field={
                <SelectDropdownFilter
                  disabled={isWeeklyTimecardsLoading}
                  placeholder={LanguageConverterV2('timekeeping.filter.status')}
                  onValueChange={handleStatusChange}
                  options={statusOptions}
                  getOptionLabel={(option) => option.name}
                  startAdornmentIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 16,
                      }}
                      alt="Status"
                      src={StatusIcon}
                    />
                  }
                  initialValue={status}
                />
              }
            />
          </Box>
          <Box>
            <LabeledField
              title={LanguageConverterV2('timekeeping.filter.tags')}
              field={
                <SelectDropdownFilter
                  disabled={isWeeklyTimecardsLoading}
                  placeholder={LanguageConverterV2('timekeeping.filter.tags')}
                  onValueChange={handleTagChange}
                  options={tagOptions}
                  getOptionLabel={(option) => option.name}
                  startAdornmentIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 16,
                      }}
                      alt="Status"
                      src={StatusIcon}
                    />
                  }
                  initialValue={tag}
                />
              }
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" gap="12px">
          <LabeledField
            title={LanguageConverterV2('timekeeping.filter.employeeName')}
            field={
              <FreeTextSearchFilter
                disabled={isWeeklyTimecardsLoading}
                placeholder={LanguageConverterV2('timekeeping.filter.employeeName')}
                onValueChange={handleEmployeeSearchChange}
                field="name"
                operation="icontains"
                background={SEARCHBAR_BACKGROUND.DEFAULT}
                initialValue={{ value: positionName }}
              />
            }
          />
          <LabeledField
            title={LanguageConverterV2('timekeeping.filter.positionName')}
            field={
              <FreeTextSearchFilter
                disabled={isWeeklyTimecardsLoading}
                placeholder={LanguageConverterV2('timekeeping.filter.positionName')}
                onValueChange={handlePositionSearchChange}
                field="name"
                operation="icontains"
                background={SEARCHBAR_BACKGROUND.DEFAULT}
                initialValue={{ value: positionName }}
              />
            }
          />
          <LabeledField
            title={LanguageConverterV2('timekeeping.filter.location')}
            field={
              <FreeTextSearchFilter
                disabled={isWeeklyTimecardsLoading}
                placeholder={LanguageConverterV2('timekeeping.filter.location')}
                onValueChange={handleLocationSearchChange}
                field="name"
                operation="icontains"
                background={SEARCHBAR_BACKGROUND.DEFAULT}
                initialValue={{ value: positionName }}
              />
            }
          />
          <LabeledField
            title={LanguageConverterV2('timekeeping.filter.department')}
            field={
              <FreeTextSearchFilter
                disabled={isWeeklyTimecardsLoading}
                placeholder={LanguageConverterV2('timekeeping.filter.department')}
                onValueChange={handleDepartmentSearchChange}
                field="name"
                operation="icontains"
                background={SEARCHBAR_BACKGROUND.DEFAULT}
                initialValue={{ value: positionName }}
              />
            }
          />
        </Box>
      </Stack>
      <WorkerTimecardSet />
    </Box>
  );
};

export default DailyTimekeeping;
