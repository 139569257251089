import theme from '../../../theme';

export const onboardingHeaderStyle = {
  ...theme?.components?.dashboardOnboarding?.header?.typography,
  ...theme?.components?.dashboardOnboarding?.header?.palette,
  margin: theme.spacing(0, 1, 0, 0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: 39,
};

export const copyButtonStyle = {
  cursor: 'pointer',
  marginRight: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 0, 0.5, 0),
  height: 39,
};
