import * as React from 'react';

import ActiveAdminIcon from '../../assets/icons/ActiveAdminIcon';
import ActiveEmployerIcon from '../../assets/icons/ActiveEmployerIcon';
import ActiveRecruiterIcon from '../../assets/icons/ActiveRecruiterIcon';
import ActiveUserIcon from '../../assets/icons/ActiveUserIcon';
import DeactivatedAdminIcon from '../../assets/icons/DeactivatedAdminIcon';
import DeactivatedEmployerIcon from '../../assets/icons/DeactivatedEmployerIcon';
import DeactivatedRecruiterIcon from '../../assets/icons/DeactivatedRecruiterIcon';
import DeactivatedUserIcon from '../../assets/icons/DeactivatedUserIcon';
import DNAUserIcon from '../../assets/icons/DNAUserIcon';
import SuspendUserIcon from '../../assets/icons/SuspendUserIcon';
import UnverifiedAdminIcon from '../../assets/icons/UnverifiedAdminIcon';
import UnverifiedEmployerIcon from '../../assets/icons/UnverifiedEmployerIcon';
import UnverifiedRecruiterIcon from '../../assets/icons/UnverifiedRecruiterIcon';
import WaitingOnboardUserIcon from '../../assets/icons/WaitingBobardUserIcon';
import WaitingVerificationUserIcon from '../../assets/icons/WaitingVerificationUserIcon';
import { BLACK, FEED_BACK, PRIMARY_PURPLE } from '../../theme/colorConstants';

export const SHIFT_TYPES = {
  MORNING: 'morning',
  EVENING: 'evening',
  NIGHT: 'night',
  CUSTOM: 'custom',
};

export const SHIFT_GROUP = {
  MORNING_START: 6,
  MORNING_END: 14,
  EVENING_START: 14,
  EVENING_END: 22,
  NIGHT_START: 22,
  NIGHT_END: 6,
};

export const PRESET_SHIFTS = {
  MORNING: {
    start: SHIFT_GROUP.MORNING_START,
    end: SHIFT_GROUP.MORNING_END,
    type: SHIFT_TYPES.MORNING,
  },
  EVENING: {
    start: SHIFT_GROUP.EVENING_START,
    end: SHIFT_GROUP.EVENING_END,
    type: SHIFT_TYPES.EVENING,
  },
  NIGHT: {
    start: SHIFT_GROUP.NIGHT_START,
    end: SHIFT_GROUP.NIGHT_END,
    type: SHIFT_TYPES.NIGHT,
  },
};

export const SHIFT_HOUR_OPTIONS = {
  0: '12:00 AM',
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
};

export const ROLE_ICONS = {
  employer: {
    active: <ActiveEmployerIcon />,
    deactivated: <DeactivatedEmployerIcon />,
    unverified: <UnverifiedEmployerIcon />,
  },
  admin: {
    active: <ActiveAdminIcon />,
    deactivated: <DeactivatedAdminIcon />,
    unverified: <UnverifiedAdminIcon />,
  },
  recruiter: {
    active: <ActiveRecruiterIcon />,
    deactivated: <DeactivatedRecruiterIcon />,
    unverified: <UnverifiedRecruiterIcon />,
  },
};

export const CANDIDATE_STATUS_CONFIG = {
  active: {
    displayName: 'userCard.status.active',
    color: FEED_BACK.GREEN_70,
    icon: <ActiveUserIcon />,
  },
  suspended: {
    displayName: 'userCard.status.suspended',
    color: FEED_BACK.RED_75,
    icon: <SuspendUserIcon />,
  },
  deactivated: {
    displayName: 'userCard.status.deactivated',
    color: FEED_BACK.RED_75,
    icon: <DeactivatedUserIcon />,
  },
  unverified: {
    displayName: 'userCard.status.unverified',
    color: FEED_BACK.ORANGE_50,
    icon: <WaitingVerificationUserIcon />,
  },
  onboardingIncomplete: {
    displayName: 'userCard.status.onboardingIncomplete',
    color: PRIMARY_PURPLE[90],
    icon: <WaitingOnboardUserIcon />,
  },
  DNA: {
    displayName: 'userCard.status.DNA',
    color: BLACK[70],
    icon: <DNAUserIcon />,
  },
};
