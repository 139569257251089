import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AddAdminIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24C8.525 23.3 5.65625 21.705 3.39375 19.215C1.13125 16.725 0 13.96 0 10.92V3.6L12 0L24 3.6V10.92C24 13.96 22.8688 16.725 20.6063 19.215C18.3438 21.705 15.475 23.3 12 24ZM12 21.48C14.6 20.82 16.75 19.5 18.45 17.52C20.15 15.54 21 13.34 21 10.92V5.25L12 2.55L3 5.25V10.92C3 13.34 3.85 15.54 5.55 17.52C7.25 19.5 9.4 20.82 12 21.48ZM9 16.8H15C15.425 16.8 15.7813 16.685 16.0688 16.455C16.3563 16.225 16.5 15.94 16.5 15.6V12C16.5 11.66 16.3563 11.375 16.0688 11.145C15.7813 10.915 15.425 10.8 15 10.8V9.6C15 8.94 14.7063 8.375 14.1188 7.905C13.5313 7.435 12.825 7.2 12 7.2C11.175 7.2 10.4688 7.435 9.88125 7.905C9.29375 8.375 9 8.94 9 9.6V10.8C8.575 10.8 8.21875 10.915 7.93125 11.145C7.64375 11.375 7.5 11.66 7.5 12V15.6C7.5 15.94 7.64375 16.225 7.93125 16.455C8.21875 16.685 8.575 16.8 9 16.8ZM10.5 10.8V9.6C10.5 9.26 10.6438 8.975 10.9313 8.745C11.2188 8.515 11.575 8.4 12 8.4C12.425 8.4 12.7813 8.515 13.0688 8.745C13.3563 8.975 13.5 9.26 13.5 9.6V10.8H10.5Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);

export default AddAdminIcon;
