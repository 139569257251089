import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Box, Grid, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';

import Button, { BUTTON_VARIANT } from '../Button';

import { modalBodyStyle, modalStyle, modalTitleStyle } from './AddDeleteAccordionListCardStyles';

const AddDeleteAccordionModalComponent = ({
  isDeleteModalOpen,
  handleModalClose,
  handleDeleteConfirmOnClick,
  deleteApiRequestBody,
  modalContent,
}) => {
  const intl = useIntl();

  return (
    <Modal open={isDeleteModalOpen} onBackdropClick={handleModalClose}>
      <Box sx={modalStyle}>
        <Box sx={modalTitleStyle}>{intl.formatMessage({ id: modalContent?.title })}</Box>
        <Typography sx={modalBodyStyle}>
          {intl.formatMessage({ id: modalContent?.body })}
        </Typography>

        <Grid container item direction="row" sx={{ justifyContent: 'flex-end' }}>
          <Button variant={BUTTON_VARIANT.OUTLINED} onClick={() => handleModalClose()}>
            {intl.formatMessage({ id: 'corporation.accordion.modalCancelButton' })}
          </Button>
          <Button
            onClick={() => handleDeleteConfirmOnClick(deleteApiRequestBody)}
            sx={{ marginLeft: '24px' }}
          >
            {intl.formatMessage({ id: 'corporation.accordion.modalDeleteButton' })}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

AddDeleteAccordionModalComponent.propTypes = {
  deleteApiRequestBody: PropTypes.shape({}),
  isDeleteModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleDeleteConfirmOnClick: PropTypes.func,
  modalContent: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};

export default AddDeleteAccordionModalComponent;
