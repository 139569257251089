import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import { PRIMARY_FONT } from '../../../../../theme/fontConstants';

const AntiSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 45,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      marginLeft: 7,

      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '40px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {},
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 40,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const WorkerPlacementsFilters = ({ isChecked, isLoading, onChange }) => (
  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
    <Typography
      sx={{
        fontSize: '20px',
        fontFamily: PRIMARY_FONT[500],
      }}
    >
      {LanguageConverter('userDetail.workerPlacements.workHistory')}
    </Typography>
    <AntiSwitch sx={{ m: 1 }} checked={isChecked} disabled={isLoading} onChange={onChange} />
    <Typography
      sx={{
        fontSize: '20px',
        fontFamily: PRIMARY_FONT[500],
      }}
    >
      {LanguageConverter('userDetail.workerPlacements.upcomingShifts')}
    </Typography>
  </Stack>
);

WorkerPlacementsFilters.propTypes = {
  isChecked: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
};
export default WorkerPlacementsFilters;
