import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';

import PaginatedListWrapper from '../../../../../components/PagedFilterList';
import {
  selectIsLoadingWorkerPlacements,
  selectWorkerPlacements,
} from '../../../../../store/selectors/userListSelector';
import { FILTERS } from '../../../UsersConstants';
import { getWorkerPlacements } from '../../../usersListReducer';

import RowCards from './RowCards';
import WorkerPlacementsFilters from './WorkerPlacementsFilters';

const LIMIT = 5;

const WorkerPlacements = ({ user }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoadingWorkerPlacements);
  const workerPlacements = useSelector(selectWorkerPlacements);
  const INITIAL_FILTERS = {
    filters: [
      FILTERS.UPCOMING,
      {
        value: user.id,
        field: 'candidate',
        operation: 'equalsID',
      },
      { field: 'state.status', operation: 'equals', value: 'active' },
    ],
    page: 0,
    limit: LIMIT,
    sortBy: [{ field: 'start', descending: false }],
  };
  const [filterAndSortData, setFilterAndSortData] = useState(INITIAL_FILTERS);

  const handleOnFilterChange = (e) => {
    const newFilter = e.target.checked ? FILTERS.UPCOMING : FILTERS.COMPLETED;

    setFilterAndSortData((prevFilters) => ({
      filters: [
        newFilter,
        {
          value: user?.id,
          field: 'candidate',
          operation: 'equalsID',
        },
        { field: 'state.status', operation: 'equals', value: 'active' },
      ],
      page: INITIAL_FILTERS.page,
      limit: prevFilters.limit,
      sortBy: [{ field: 'start', descending: newFilter === FILTERS.COMPLETED }],
    }));
  };

  useEffect(() => {
    dispatch(getWorkerPlacements(filterAndSortData));
  }, [dispatch, filterAndSortData]);

  const handleOnPageChange = (newPage) => {
    setFilterAndSortData((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const title = LanguageConverter('userDetail.workerPlacements.title');

  const isChecked = !!filterAndSortData.filters.find(
    (filter) =>
      filter.field === FILTERS.UPCOMING.field && filter.operation === FILTERS.UPCOMING.operation
  );

  return (
    <Box>
      <PaginatedListWrapper
        isLoading={isLoading}
        content={<RowCards placements={workerPlacements?.documents} />}
        limit={LIMIT}
        filters={
          <WorkerPlacementsFilters
            isChecked={isChecked}
            isLoading={isLoading}
            onChange={handleOnFilterChange}
          />
        }
        title={title}
        currentPageNum={filterAndSortData.page}
        totalListCount={workerPlacements?.count}
        handleOnPageChange={handleOnPageChange}
      />
    </Box>
  );
};
WorkerPlacements.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default WorkerPlacements;
