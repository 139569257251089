import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';

import theme from '../../theme/theme';
import { isValidNumber, millisecondsToHours } from '../../utils';

const styles = theme?.components?.timeCardOverviewCard;

const getMillisecondsToHoursDisplayValue = (milliseconds) =>
  isValidNumber(milliseconds)
    ? millisecondsToHours(milliseconds).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : '--';

const CardItem = ({ name, value }) => (
  <Box display="flex" justifyContent="space-between">
    <Typography sx={styles.itemNameText} data-testid="subHeader">
      {name}
    </Typography>
    <Typography sx={styles.itemValueText} data-testid="subHeader">
      {value}
    </Typography>
  </Box>
);

CardItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

const TimekeepingOverviewCard = ({
  isLoading,
  titleText,
  numOfTimeCards,
  regularHours,
  overTimeHours,
  totalHours,
}) => (
  <Box>
    <Card
      variant="elevation"
      sx={{
        width: '350px',
        height: '228px',
        backgroundColor: 'white',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      {isLoading ? (
        <Skeleton height="inherit" variant="rectangular" />
      ) : (
        <CardContent sx={{ height: '100%' }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <Typography sx={styles.headerStyle} data-testid="header">
              {titleText}
            </Typography>
            <Box>
              <CardItem
                name={LanguageConverterV2('timekeeping.overviewCard.timeCards')}
                value={
                  isValidNumber(numOfTimeCards) ? numOfTimeCards.toLocaleString('en-US') : '--'
                }
              />
              <CardItem
                name={LanguageConverterV2('timekeeping.overviewCard.regularHours')}
                value={getMillisecondsToHoursDisplayValue(regularHours)}
              />
              <CardItem
                name={LanguageConverterV2('timekeeping.overviewCard.overtimeHours')}
                value={getMillisecondsToHoursDisplayValue(overTimeHours)}
              />
            </Box>
            <CardItem
              name={LanguageConverterV2('timekeeping.overviewCard.totalHours')}
              value={getMillisecondsToHoursDisplayValue(totalHours)}
            />
          </Box>
        </CardContent>
      )}
    </Card>
  </Box>
);

TimekeepingOverviewCard.propTypes = {
  isLoading: PropTypes.bool,
  titleText: PropTypes.string,
  numOfTimeCards: PropTypes.number,
  regularHours: PropTypes.number,
  overTimeHours: PropTypes.number,
  totalHours: PropTypes.number,
};

export default TimekeepingOverviewCard;
