import * as React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

const KeyboardArrowDownIcon = ({ color = '#7A23FF' }) => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.66699 10L11.667 16L17.667 10"
        stroke="url(#paint0_linear_8_926)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_926"
          x1="9.79743"
          y1="14.5"
          x2="17.5119"
          y2="12.1582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

KeyboardArrowDownIcon.propTypes = {
  color: PropTypes.string,
};

export default KeyboardArrowDownIcon;
