import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';
import { useIntl } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
  Zoom,
} from '@mui/material';

import accordionDown from '../../assets/accordionDown.svg';
import accordionUp from '../../assets/accordionUp.svg';
import TrashCan from '../../assets/trashcan.svg';

import AddDeleteAccordionListCardFormComponent from './AddDeleteAccordionListCardFormComponent';
import {
  accordionCardStyles,
  accordionTrashIconStyle,
  accordionUpDownIconStyle,
  addContentBoxStyles,
  addPopupButtonStyle,
  bodyContentAndAndDeleteStyle,
  bodyContentAndAndDeleteStyleWithoutBorder,
  titleStyles,
} from './AddDeleteAccordionListCardStyles';
import AddDeleteAccordionModalComponent from './AddDeleteAccordionModalComponent';

const AddDeleteAccordionListCard = ({
  accordionDetailContent,
  accordionDetailEmptyContentBody, // Detail content body when there is no content
  accordionSummaryTitle,
  addButtonName,
  addContentFormBody, // Form Component for the add pop up
  handleOnDelete, // delete button onClick in modal
  icon,
  isProcessing,
  isAccordionOpenByDefault = false,
  isAddDeleteEnabled, // true or false, enables the add and delete features of the accordion
  modalContent, // Content inside modal
  onAddFormSubmit, // On submit function to the add pop up
  testId,
  wrapDetailContentInBorder, // Boolean check if content in detail body should be wrapped in a border. Only works with isAddDeleteEnabled = true
}) => {
  const intl = useIntl();
  const [accordionOpen, setAccordionOpen] = React.useState(true);
  const [createBoxIsOpen, setCreateBoxIsOpen] = React.useState(false);
  const [deleteApiRequestBody, setDeleteApiRequestBody] = React.useState({});

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  // Toggle the accordion open/close state
  const handleAccordionOnChange = () => {
    setAccordionOpen((prev) => !prev);
  };

  // Toggle the add content box open/close state
  const handleAddButtonOnClick = () => {
    setCreateBoxIsOpen((prev) => !prev);
  };

  // Handle form submission for adding content
  const rffOnSubmit = (val) => {
    setCreateBoxIsOpen(false);
    onAddFormSubmit(val);
  };

  // Handle delete confirmation click in the modal
  const handleDeleteConfirmOnClick = (requestBody) => {
    handleOnDelete(requestBody);
    setIsDeleteModalOpen(false);
  };

  // Open the delete confirmation modal and set the delete request body
  const handleWhenModalOpens = (apiRequest) => {
    setDeleteApiRequestBody(apiRequest);
    setIsDeleteModalOpen(true);
  };

  // Close the delete confirmation modal
  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <Accordion
      defaultExpanded={isAccordionOpenByDefault}
      data-testid={testId}
      sx={accordionCardStyles}
      onChange={handleAccordionOnChange}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Box display="flex" alignItems="center" flexGrow={1}>
          {icon && (
            <Box
              component="img"
              sx={{ height: 24, width: 24, marginLeft: 2 }}
              alt="Accordion Summary"
              src={icon}
            />
          )}
          <Typography sx={titleStyles}>
            {intl.formatMessage({ id: accordionSummaryTitle })}
          </Typography>
        </Box>
        {accordionOpen ? (
          <Box component="img" sx={accordionUpDownIconStyle} alt="Accordion Up" src={accordionUp} />
        ) : (
          <Box
            component="img"
            sx={accordionUpDownIconStyle}
            alt="Accordion Down"
            src={accordionDown}
          />
        )}
      </AccordionSummary>
      {isProcessing ? (
        <AccordionDetails>
          <CircularProgress />
        </AccordionDetails>
      ) : (
        <AccordionDetails>
          {/* Prop isAddDeleteEnabled determines if you want to have the add and delete to list component in accordion */}
          {isAddDeleteEnabled && (
            <Box>
              <Grid
                container
                item
                direction="row"
                sx={{ justifyContent: createBoxIsOpen ? 'flex-end' : 'flex-start' }}
              >
                {!createBoxIsOpen ? (
                  <Typography onClick={handleAddButtonOnClick} sx={addPopupButtonStyle}>
                    {intl.formatMessage({ id: addButtonName })}
                  </Typography>
                ) : (
                  <Typography onClick={handleAddButtonOnClick} sx={addPopupButtonStyle}>
                    {intl.formatMessage({ id: 'corporation.accordion.close' })}
                  </Typography>
                )}
              </Grid>
              {/* Add Pop up box, pass in RFF fields */}
              {createBoxIsOpen && (
                <Zoom in={createBoxIsOpen}>
                  <Grid container item sx={addContentBoxStyles}>
                    <RFForm
                      onSubmit={rffOnSubmit}
                      initialValues={{}}
                      destroyOnUnregister
                      render={({ handleSubmit, pristine }) => (
                        <AddDeleteAccordionListCardFormComponent
                          handleSubmit={handleSubmit}
                          addContentFormBody={addContentFormBody}
                          pristine={pristine}
                          isProcessing={isProcessing}
                        />
                      )}
                    />
                  </Grid>
                </Zoom>
              )}
            </Box>
          )}

          {accordionDetailContent?.length > 0
            ? accordionDetailContent.map((bodyContent) => (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                  key={bodyContent.key}
                >
                  <Box
                    sx={
                      (isAddDeleteEnabled &&
                        wrapDetailContentInBorder &&
                        bodyContentAndAndDeleteStyle) ||
                      bodyContentAndAndDeleteStyleWithoutBorder
                    }
                  >
                    {bodyContent.content}

                    {isAddDeleteEnabled && (
                      <Grid container item sx={{ justifyContent: 'flex-end', marginTop: -3 }}>
                        <Box
                          component="img"
                          alt="Trash Can"
                          sx={accordionTrashIconStyle}
                          src={TrashCan}
                          onClick={() => handleWhenModalOpens(bodyContent.deleteApiRequest)}
                        />
                      </Grid>
                    )}
                  </Box>
                </Box>
              ))
            : !createBoxIsOpen && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  {accordionDetailEmptyContentBody}
                </Box>
              )}
        </AccordionDetails>
      )}
      {isAddDeleteEnabled && (
        <AddDeleteAccordionModalComponent
          isDeleteModalOpen={isDeleteModalOpen}
          handleModalClose={handleModalClose}
          handleDeleteConfirmOnClick={handleDeleteConfirmOnClick}
          deleteApiRequestBody={deleteApiRequestBody}
          modalContent={modalContent}
        />
      )}
    </Accordion>
  );
};

AddDeleteAccordionListCard.propTypes = {
  addButtonName: PropTypes.string,
  accordionSummaryTitle: PropTypes.string,
  accordionDetailEmptyContentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  accordionDetailContent: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.element,
      deleteApiRequest: PropTypes.shape({}),
    })
  ),
  addContentFormBody: PropTypes.element,
  onAddFormSubmit: PropTypes.func,
  handleOnDelete: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isProcessing: PropTypes.bool,
  isAccordionOpenByDefault: PropTypes.bool,
  modalContent: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
  isAddDeleteEnabled: PropTypes.bool,

  testId: PropTypes.string,
  wrapDetailContentInBorder: PropTypes.bool,
};

export default AddDeleteAccordionListCard;
