import * as React from 'react';
import PropTypes from 'prop-types';

import { convertReliabilityScoreToDisplayValue } from '@careerstart/wae-common/src/main/helperFunction/convertReliabilityScoreToDisplayValue';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';

import stringAvatar from '../../../../main-deprecated/components/NavigationBar/SubComponents/stringAvatar';
import ActiveUserIcon from '../../../assets/icons/ActiveUserIcon';
import CopyLinkIcon from '../../../assets/icons/CopyLinkIcon';
import DeactivatedUserIcon from '../../../assets/icons/DeactivatedUserIcon';
import DNAUserIcon from '../../../assets/icons/DNAUserIcon';
import SuspendUserIcon from '../../../assets/icons/SuspendUserIcon';
import WaitingOnboardUserIcon from '../../../assets/icons/WaitingBobardUserIcon';
import WaitingVerificationUserIcon from '../../../assets/icons/WaitingVerificationUserIcon';
import theme from '../../../theme';
import { BLACK, FEED_BACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { convertPreferredTimesFromApiToUiFormat, formatPhoneNumber } from '../helpers';

import Details from './Details';

const styles = theme?.components?.userCard;

const CandidateCard = ({ user, onCopyId, onClick }) => {
  const CANDIDATE_STATUS_CONFIG = {
    active: {
      displayName: LanguageConverter('userCard.status.active'),
      color: FEED_BACK.GREEN_70,
      icon: <ActiveUserIcon />,
    },
    suspended: {
      displayName: LanguageConverter('userCard.status.suspended'),
      color: FEED_BACK.RED_75,
      icon: <SuspendUserIcon />,
    },
    deactivated: {
      displayName: LanguageConverter('userCard.status.deactivated'),
      color: FEED_BACK.RED_75,
      icon: <DeactivatedUserIcon />,
    },
    unverified: {
      displayName: LanguageConverter('userCard.status.unverified'),
      color: FEED_BACK.ORANGE_50,
      icon: <WaitingVerificationUserIcon />,
    },
    onboardingIncomplete: {
      displayName: LanguageConverter('userCard.status.onboardingIncomplete'),
      color: PRIMARY_PURPLE[90],
      icon: <WaitingOnboardUserIcon />,
    },
    DNA: {
      displayName: LanguageConverter('userCard.status.DNA'),
      color: BLACK[70],
      icon: <DNAUserIcon />,
    },
  };

  const updatedPreferredShiftTimes = user?.candidate?.preferredShiftTimes
    ? convertPreferredTimesFromApiToUiFormat(user?.candidate?.preferredShiftTimes)
    : [];

  const userInfo = {
    status: user?.status,
    role: user?.role,
    email: user?.email,
    name: user?.name,
    lastLogin: user?.lastLoginDate || null,
    reliabilityScore: convertReliabilityScoreToDisplayValue(
      user?.candidate?.metrics?.reliabilityScore
    ),
    attendance: user?.rating?.suspendUntil > Date.now() ? 3 : user?.rating?.infractions?.length,
    preferredShiftTimes: updatedPreferredShiftTimes,
    industry: user?.candidate?.industry,
    phoneNumber: formatPhoneNumber(user?.candidate?.phoneNumber),
    location:
      user?.candidate?.preferredLocation?.city && user?.candidate?.preferredLocation?.state
        ? `${user.candidate.preferredLocation.city}, ${user.candidate.preferredLocation.state}`
        : '',
  };

  const currentStatus = CANDIDATE_STATUS_CONFIG[userInfo?.status];

  return (
    <Card sx={styles.cardStyle}>
      <CardHeader
        sx={styles.cardHeader}
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ fontFamily: PRIMARY_FONT[500] }}>
              {LanguageConverter('userCard.header.status')}
            </Typography>
            {currentStatus?.icon}
            <Typography sx={{ fontFamily: PRIMARY_FONT[500], color: currentStatus?.color }}>
              {currentStatus?.displayName}
            </Typography>
          </Box>
        }
      />

      <Divider />
      <CardContent>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar {...stringAvatar(user)} sx={{ width: 80, height: 80 }} />
          <Box>
            <Typography sx={styles.workerTitle}>
              {LanguageConverter('userCard.subTitle.worker')}
            </Typography>
            <Typography sx={styles.roleName} onClick={onClick}>
              {userInfo?.name}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              onClick={onCopyId}
              sx={{ cursor: 'pointer', gap: 1 }}
            >
              <Typography sx={styles.copyUser}>
                {LanguageConverter('userCard.subTitle.copyUserId')}
              </Typography>
              <CopyLinkIcon />
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Box display="flex" alignItems="baseline" gap={1}>
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.subTitle.phone')}
            </Typography>
            <Typography sx={styles.contentStyle}> {userInfo?.phoneNumber}</Typography>
          </Box>
          <Box display="flex" alignItems="baseline" gap={1}>
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.subTitle.email')}
            </Typography>
            <Typography sx={styles.emailStyle}>{userInfo?.email}</Typography>
          </Box>
          <Box display="flex" alignItems="baseline" gap={1}>
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.subTitle.location')}
            </Typography>
            <Typography sx={styles.contentStyle}>{userInfo?.location}</Typography>
          </Box>
        </Box>
        <Details userInfo={userInfo} />
      </CardContent>
      {userInfo?.lastLogin && (
        <CardActions
          sx={{
            p: 2,
            justifyContent: 'flex-start',
            bottom: 0,
            width: '100%',
            mt: 'auto',
          }}
        >
          <Typography sx={styles.lastLoginHeader}>
            {LanguageConverter('userCard.subTitle.lastLoggedIn')}
          </Typography>
          <Typography sx={styles.lastLoginTime}>{userInfo?.lastLogin}</Typography>
        </CardActions>
      )}
    </Card>
  );
};
CandidateCard.propTypes = {
  user: PropTypes.shape({
    candidate: PropTypes.shape({
      preferredShiftTimes: PropTypes.shape({}),
      preferredLocation: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
      industry: PropTypes.arrayOf(PropTypes.string),
      phoneNumber: PropTypes.string,
      metrics: PropTypes.shape({
        reliabilityScore: PropTypes.number,
      }),
    }),
    role: PropTypes.string,
    status: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    lastLoginDate: PropTypes.string,
    reliabilityScore: PropTypes.number,
    attendance: PropTypes.number,
    phoneNumber: PropTypes.string,
    location: PropTypes.string,
    rating: PropTypes.shape({
      suspendUntil: PropTypes.number,
      strikeOuts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
      infractions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    }),
  }),
  onCopyId: PropTypes.func,
  onClick: PropTypes.func,
};

export default CandidateCard;
