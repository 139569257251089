import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import WaeButton, { BUTTON_VARIANT } from '../../../components/Button';
import { PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { ROUTE_CONSTANTS } from '../../app/RouteBuilding/routeConstants';

const buttonStyle = { fontFamily: PRIMARY_FONT[700], fontSize: '16px', textTransform: 'none' };

const AgenciesView = ({ isLoading, content, filters, title, styles }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', borderRadius: '8px', gap: '8px' }}>
      <>
        <Typography sx={{ fontSize: '20px', fontFamily: PRIMARY_FONT[700], marginBottom: '10px' }}>
          {title}
        </Typography>
        <Grid container justifyContent="space-between" direction="row" flexWrap="nowrap">
          {filters}
          <Grid item>
            <Grid container sx={{ gap: '8px' }}>
              <WaeButton
                variant={BUTTON_VARIANT.DEFAULT}
                actionColor={PRIMARY_COLOR[70]}
                onClick={() => navigate(`/${ROUTE_CONSTANTS.AGENCY_CREATE}`)}
                sx={buttonStyle}
              >
                {LanguageConverter('dashboard.agencies.addAnAgency')}
              </WaeButton>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', borderRadius: '8px', gap: '8px', ...styles }}>
          <Grid container gap={3} sx={{ paddingBlock: '18px' }}>
            {content}
          </Grid>
        </Box>
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </>
    </Box>
  );
};
AgenciesView.propTypes = {
  isLoading: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.element),
  filters: PropTypes.element,
  title: PropTypes.string,
  styles: PropTypes.shape({}),
};
export default AgenciesView;
