import * as React from 'react';
import { Form as RFForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import onboardingLinkReq from '@careerstart/wae-common/schema/onboarding/onboardingLink/post.req.json';

import { validateSchema } from '../../../components/Form/validations';

import DashboardOnboardingFormComponent from './DashboardOnboardingFormComponent';
import { dashboardOnboardingEmail } from './dashboardOnboardingReducer';

const DashboardOnboarding = () => {
  const dispatch = useDispatch();

  const handleOnSubmit = (value) => {
    const onboardingFields = {
      fields: value,
    };

    const schemaErrors = validateSchema(onboardingFields, onboardingLinkReq);
    if (Object.keys(schemaErrors).length === 0) {
      dispatch(dashboardOnboardingEmail(onboardingFields));
    }
  };

  return (
    <RFForm
      onSubmit={handleOnSubmit}
      initialValues={{}}
      destroyOnUnregister
      render={({ handleSubmit }) => (
        <DashboardOnboardingFormComponent handleSubmit={handleSubmit} />
      )}
    />
  );
};

export default DashboardOnboarding;
