import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import { ACTION_BLUE, FEED_BACK } from '../../theme/colorConstants';

const readyToFinalizeStepData = (counts, role) =>
  role === UserRole.RECRUITER || role === UserRole.ADMIN
    ? [
        {
          color: FEED_BACK.ORANGE_20,
          onSubmit: () => counts.step4.onSubmit(),
          header: 'timekeeping.counterCards.step4',
          subHeader: 'timekeeping.counterCards.step4.subHeader',
          body: 'timekeeping.counterCards.step4.body',
          buttonText: 'timekeeping.counterCards.viewButtonText',
          count: counts.step4.count,
          isCompleted: counts.step4.count === 0,
        },
      ]
    : [];

const getStepData = (counts, role) => [
  {
    color: FEED_BACK.GREEN_70,
    onSubmit: () => counts.step1.onSubmit(),
    header: 'timekeeping.counterCards.step1',
    subHeader:
      role === UserRole.EMPLOYER
        ? 'timekeeping.counterCards.step1.subHeaderApprove'
        : 'timekeeping.counterCards.step1.subHeaderResolve',
    body: 'timekeeping.counterCards.step1.body',
    buttonText:
      role === UserRole.EMPLOYER
        ? 'timekeeping.counterCards.selectButtonTextApprove'
        : 'timekeeping.counterCards.selectButtonTextResolve',
    count: counts.step1.count,
    isCompleted: counts.step1.count === 0,
  },
  {
    color: ACTION_BLUE.INDIGO_BLUE_REST,
    onSubmit: () => counts.step2.onSubmit(),
    header: 'timekeeping.counterCards.step2',
    subHeader: 'timekeeping.counterCards.step2.subHeader',
    body: 'timekeeping.counterCards.step2.body',
    buttonText: 'timekeeping.counterCards.viewButtonText',
    count: counts.step2.count,
    isCompleted: counts.step2.count === 0,
  },
  {
    color: FEED_BACK.ORANGE_20,
    onSubmit: () => counts.step3.onSubmit(),
    header: 'timekeeping.counterCards.step3',
    subHeader: 'timekeeping.counterCards.step3.subHeader',
    body: 'timekeeping.counterCards.step3.body',
    buttonText: 'timekeeping.counterCards.viewButtonText',
    count: counts.step3.count,
    isCompleted: counts.step3.count === 0,
  },
  ...readyToFinalizeStepData(counts, role),
];

export default getStepData;
