import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import { epochToDateInReadableFormat } from '../../../../../../utils';
import { contentBodyNameTextStyle, contentTitleTextStyle } from '../UserDetailAccordionCardsStyles';

const DNRBodyContent = ({ id, timestamp, name, reason, requester }) => (
  <Box key={`${id} ${timestamp}`}>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.company')}
    </Typography>
    <Typography sx={contentBodyNameTextStyle}>{name}</Typography>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.reason')}
    </Typography>
    <Typography sx={contentBodyNameTextStyle}>{reason}</Typography>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.submittedBy')}
    </Typography>
    <Typography sx={contentBodyNameTextStyle}>{requester}</Typography>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.dateEntered')}
    </Typography>
    <Typography sx={{ ...contentBodyNameTextStyle, marginBottom: 0 }}>
      {epochToDateInReadableFormat(timestamp)}
    </Typography>
  </Box>
);

DNRBodyContent.propTypes = {
  id: PropTypes.string,
  timestamp: PropTypes.number,
  name: PropTypes.string,
  reason: PropTypes.string,
  requester: PropTypes.string,
};

export default DNRBodyContent;
