import { isValidNumber } from '../../utils';

/* eslint-disable import/prefer-default-export */
export const getFillRate = (activePlacements, numOpenings) => {
  if (numOpenings === 0) {
    return '--';
  }
  if (isValidNumber(activePlacements) && isValidNumber(numOpenings)) {
    return Math.round((activePlacements * 100) / numOpenings);
  }
  return '--';
};
