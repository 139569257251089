import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CopyLinkIcon = () => (
  <SvgIcon sx={{ fontSize: 16 }}>
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect y="0.5" width="12" height="12" fill="url(#pattern0_1877_64385)" />
      <defs>
        <pattern
          id="pattern0_1877_64385"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1877_64385" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_1877_64385"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAA2xJREFUeF7tnU2O00AQhauCRBYguAKLbNIm4gyAWMA1QAIhBOLnHDMgNMACzsGCnzOwcDvbOQMDG6S4kaURipCIzTxXlx2/bMevq/v7up0Zt8atwo8rAXWtzuJCAc6TgAIowJmAc3muAApwJuBcnitgbAJWq9Vqs9ncU9WbInJFRC44j8G0fIzRdJJ2bnyxWMzn8/mhiNwXkZnpqAfU+CAEnML/KCLXB8QmS1cGISCE8FZVH2QZ8cCKuAto7vl1XX+b0m1new64CwghvFLVxwObmNm64y6gKIooIiHbiAdWaAgCTkTk4sC4ZOvOEASkXaO17mA20k6FWv8OKIqCAgzlUIAh3C5NU0AXSobXUIAh3C5NU0AXSobXUIAh3C5NU0AXSobXZBewh/sJP0XkWEQ+13X9fr1el//jK5uAiewn1CLyLqX0tKqqX11EZBEwwf2Eryml210kZBEw0f2Eoxjjo7ZVYC5gwvsJm5TStaqqmqfJ//yYC5j4fsJhjPGZq4Ap7yeoalmW5cpbwJT3E05ijJe8Bez142z0cb35dwDawbbfIrx/jo6PAkCDFAACROMUgBIE8xQAAkTjFIASBPMUAAJE4xSAEgTzFAACROMUgBIE8xQAAkTjFIASBPMUAAJE4xSAEgTzFAACROMUgBIE8xQAAkTjFIASBPMUAAJE4xSAEgTzFAACROMUgBIE8xQAAkTjFIASBPMUAAJE4xSAEgTzFAACROMUgBIE8xQAAkTjFIASBPMUAAJE4xSAEgTzFAACROMUgBIE8xQAAkTjFIASBPMUAAJE4xSAEgTzFAACROPmArw7iNa3zlOANeGW9imAAnYTQGeIM9/W8uj4Wv9TvrUHxksUrW+dpwBrwsYTjCsAFMgVAAJE42MQMOUXNn2PMV7eJTnHLWiyR6D08sqyHpboSxF5grYz0vxBjPG56wpYLpdXZ7NZcwzWuZFCPGu3+3lt5Vmrb+eKojgSkYd9tDWWNlT1dVmWrcd/mX8HNMBCCOdVtTkK8cZYAIL9/JJSutPLq4vBjvyJn0o4EJHmSMR9vR1tVPVNXdcvusBv4GRZAdsSQwiFqt5V1VsppeY43LGfUfZDVY9TSp9SSh/aXlX894TOLqCvFbUv7VCAs0kKoABnAs7luQIowJmAc3muAApwJuBc/jdUowiOAuDdxgAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  </SvgIcon>
);
export default CopyLinkIcon;
