import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';

import AgencySelectionModal from './AgencySelectionModal';

const styles = theme?.components?.userCard;
const AgencyCard = ({ agency, userName, userId, userRole }) => {
  const [isAgencySelectionModalOpen, setIsAgencySelectionModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ mt: 2, mb: 1 }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography sx={styles.subTitleStyle}>{LanguageConverter('userCard.agency')} </Typography>
          <Typography sx={styles.agencyStyle}>{agency?.name}</Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={styles.deactivateButtonStyle}
          onClick={() => setIsAgencySelectionModalOpen(true)}
        >
          {LanguageConverter('userCard.changeAgency')}
        </Button>
      </Box>
      <AgencySelectionModal
        userAgency={agency}
        userName={userName}
        open={isAgencySelectionModalOpen}
        onClose={() => setIsAgencySelectionModalOpen(false)}
        userId={userId}
        userRole={userRole}
      />
    </>
  );
};

AgencyCard.propTypes = {
  agency: PropTypes.shape({
    name: PropTypes.string,
    ID: PropTypes.string,
  }),
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string,
  userRole: PropTypes.string,
};

export default AgencyCard;
