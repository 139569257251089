import React from 'react';
import PropTypes from 'prop-types';

import { convertReliabilityScoreToDisplayValue } from '@careerstart/wae-common/src/main/helperFunction/convertReliabilityScoreToDisplayValue';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip, Typography } from '@mui/material';

import { BLACK, PRIMARY_COLOR } from '../../theme/colorConstants';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`;
};

export const ScoreChart = ({ label, score, description, displayTooltip = true, styles }) => {
  const radius = styles?.radius || 80;
  const greyStrokeWidth = 10;
  const purpleStrokeWidth = 15;
  const center = radius + purpleStrokeWidth;
  const percentage = convertReliabilityScoreToDisplayValue(score);
  const percentageNum = percentage === '--' ? 0 : parseInt(percentage, 10);

  const arc = describeArc(center, center, radius, 270, 270 + 180 * (percentageNum / 100));

  const chartHeight = radius + purpleStrokeWidth - 10;
  const chartWidth = radius * 2 + purpleStrokeWidth;

  return (
    <Box sx={{ position: 'relative' }}>
      <svg height={chartHeight} width={chartWidth} viewBox={`0 0 ${center * 2} ${center}`}>
        <defs>
          <clipPath id="clip">
            <rect x="0" y="0" width={center * 2} height={center} />
          </clipPath>
        </defs>
        <g clipPath="url(#clip)">
          <path
            d={describeArc(center, center, radius, 270, 450)}
            stroke={BLACK[40]}
            strokeWidth={greyStrokeWidth}
            fill="transparent"
          />
          <path
            d={arc}
            stroke={PRIMARY_COLOR[70]}
            strokeWidth={purpleStrokeWidth}
            strokeLinecap="round"
            fill="transparent"
          />
        </g>
      </svg>

      <Box
        sx={{
          position: 'absolute',
          top: `${chartHeight / 2 - 10}px`,
          left: `${chartWidth / 2 - 50}px`,
          height: '60px',
          width: '100px',
        }}
      >
        <Typography
          sx={
            styles?.percentageStyle || {
              fontFamily: PRIMARY_FONT[500],
              fontSize: `42px`,
              lineHeight: `58px`,
              fontWeight: 'bold',
              justifySelf: 'center',
            }
          }
        >
          {percentage}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography
          sx={
            styles?.labelStyles || {
              fontFamily: PRIMARY_FONT[400],
              fontSize: '24px',
              fontWeight: '400',
              lineHeight: '28px',
            }
          }
        >
          {label}
        </Typography>
        {displayTooltip && (
          <Tooltip
            title={description}
            arrow
            componentsProps={{
              tooltip: {
                sx: { background: 'white', border: `1px solid ${BLACK[40]}`, color: BLACK[100] },
              },
              arrow: { sx: { color: BLACK[40] } },
            }}
          >
            <InfoOutlinedIcon color="primary" />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

ScoreChart.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.number,
  description: PropTypes.string,
  displayTooltip: PropTypes.bool,
  styles: PropTypes.shape({
    radius: PropTypes.number,
    percentageStyle: PropTypes.shape({}),
    labelStyles: PropTypes.shape({}),
  }),
};

export default ScoreChart;
