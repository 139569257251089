import { BLACK, PRIMARY_PURPLE } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

const styles = {
  rootBox: { width: '340px' },
  card: { borderRadius: '16px' },
  roleName: {
    fontSize: '20px',
    fontFamily: PRIMARY_FONT[700],
    color: BLACK[80],
    wordWrap: 'break-word',
    overflowWrap: 'anywhere',
  },
  workerTitle: { fontSize: '14px', color: BLACK[70] },
  copyUser: {
    cursor: 'pointer',
    fontFamily: PRIMARY_FONT[400],
    fontSize: '10px',
    color: BLACK[80],
  },
  subTitleStyle: {
    fontFamily: PRIMARY_FONT[500],
    fontSize: '16px',
    color: BLACK[80],
  },
  emailStyle: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '14px',
    color: BLACK[100],
    minWidth: 0,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
  lastLoginHeader: { fontFamily: PRIMARY_FONT[400], fontSize: '10px' },
  lastLoginTime: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '10px',
  },
  deactivateButtonStyle: {
    width: '242px',
    mt: 1,
    backgroundColor: PRIMARY_PURPLE[70],
    color: 'white',
    fontFamily: PRIMARY_FONT[700],
    fontSize: '16px',
    textTransform: 'none',
    '&:hover': { backgroundColor: `${PRIMARY_PURPLE[90]}` },
  },
  corporationStyle: {
    color: PRIMARY_PURPLE[70],
    fontSize: '16px',
    textDecoration: 'underline',
    wordWrap: 'break-word',
    overflowWrap: 'anywhere',
  },
  corporationTitleStyle: {
    color: BLACK[70],
    fontFamily: PRIMARY_FONT[500],
    fontSize: '16px',
  },
};
export default styles;
