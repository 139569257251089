import * as React from 'react';
import PropTypes from 'prop-types';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';

import { BLACK, PRIMARY_COLOR } from '../../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  epochToTimeInReadableFormat,
} from '../../../../../utils';
import { ROUTE_CONSTANTS } from '../../../../app/RouteBuilding/routeConstants';

const Title = ({ text }) => (
  <Typography
    sx={{
      fontSize: '12px',
      color: BLACK[70],
      textTransform: 'uppercase',
      fontFamily: PRIMARY_FONT[300],
    }}
  >
    {text}
  </Typography>
);

Title.propTypes = {
  text: PropTypes.string,
};

const RowCards = ({ placements = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return placements.length > 0 ? (
    placements?.map((placement) => (
      <Grid
        container
        sx={{
          border: `1px solid ${BLACK[40]}`,
          borderRadius: '5px',
        }}
        key={placement?._id}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            height: '43px',
            backgroundColor: BLACK[10],
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[500],
              paddingLeft: '12px',
            }}
          >
            {epochToDayOfWeek(placement.start)}
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[400],
              paddingLeft: '12px',
            }}
          >
            {epochToDateInReadableFormat(placement.start)}
          </Typography>
        </Grid>
        <Grid container direction="row" flexWrap="nowrap" sx={{ padding: '12px' }}>
          <Grid container alignItems="center" direction="row" flexWrap="nowrap">
            <Grid container direction="column">
              <Title text={LanguageConverter('userDetail.workerPlacements.company')} />
              <Typography
                data-testid="company-name"
                sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}
              >
                {placement?.corporation?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.workerPlacements.position')} />
            <Typography
              data-testid="position-name"
              sx={{
                fontSize: '16px',
                fontFamily: PRIMARY_FONT[300],
                color: PRIMARY_COLOR[70],
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                const jobIdPath = generatePath(`/${ROUTE_CONSTANTS.JOB_ORDERS}/:id`, {
                  id: placement?.jobOrder?._id,
                });
                navigate(`${jobIdPath}${location.search}`);
              }}
            >
              {placement.jobOrder.name}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.workerPlacements.times')} />
            <Typography
              data-testid="time-range"
              sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}
            >
              {`${epochToTimeInReadableFormat(placement.start)}-${epochToTimeInReadableFormat(
                placement.end
              )}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))
  ) : (
    <Grid
      container
      justifyContent="center"
      sx={{
        backgroundColor: BLACK[10],
        borderRadius: '5px',
        height: '40px',
      }}
    />
  );
};
RowCards.propTypes = {
  placements: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RowCards;
