import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Grid, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { buildFormField } from '../../../components/Form/formFieldFactory';
import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';

import onboardingFormTextField from './DashboardOnboardingFormField';
import { onboardingHeaderStyle } from './DashboardOnboardingStyling';

const DashboardOnboardingFormComponent = ({ handleSubmit }) => {
  const formState = useFormState();
  const form = useForm();
  const onClickHandleSubmit = () => {
    handleSubmit();
    form.reset();
  };

  return (
    <Grid sx={{ height: '100%', width: '100%', marginTop: 4 }}>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
        }}
      >
        <Grid container direction="row">
          <Typography sx={onboardingHeaderStyle}>
            {LanguageConverter('dashboard.onboarding.title')}
          </Typography>
          <Box>{buildFormField(onboardingFormTextField())}</Box>
          <Button
            data-testid="dashboardOnboardingSend"
            text={LanguageConverter('dashboard.onboarding.sendButton')}
            onClick={onClickHandleSubmit}
            sx={{
              backgroundColor:
                Object.keys(formState.errors).length > 0 ? BLACK[60] : PRIMARY_PURPLE[70],
              height: 39,
              width: 76,
            }}
            disabled={Object.keys(formState.errors).length > 0}
          />
        </Grid>
      </form>
    </Grid>
  );
};

DashboardOnboardingFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
};
export default DashboardOnboardingFormComponent;
