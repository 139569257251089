import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Button, Card, CardContent, Skeleton, Typography } from '@mui/material';

import CircleCheckMarkIcon from '../../assets/icons/CircleCheckMarkIcon';
import CircularProgressArrowIcon from '../../assets/icons/CircularProgressArrowIcon';
import { PRIMARY_PURPLE } from '../../theme/colorConstants';
import theme from '../../theme/theme';

const styles = theme?.components?.remainingActionsCounterCard;

const RemainingActionsCounterCard = ({
  isLoading,
  header,
  subHeader,
  body,
  count,
  color,
  buttonText,
  isCompleted,
}) => {
  const [cardSelected, setCardSelected] = React.useState(false);

  const handleCardClick = () => {
    setCardSelected(!cardSelected);
  };

  return (
    <Box>
      <Card
        variant={cardSelected ? 'outlined' : 'elevation'}
        onClick={handleCardClick}
        sx={{
          width: '350px',
          height: '228px',

          backgroundColor: cardSelected ? PRIMARY_PURPLE[10] : 'white',
          boxShadow: cardSelected
            ? `0px 2px 6px 0px rgba(0, 0, 0, 0.25), 0 0 0 2px ${PRIMARY_PURPLE[70]}`
            : '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
          border: cardSelected ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
          '&:hover': {
            backgroundColor: PRIMARY_PURPLE[10],
          },
          opacity: isCompleted && 0.45,
          pointerEvents: isCompleted && 'none',
        }}
      >
        {isLoading ? (
          <Skeleton height="inherit" variant="rectangular" />
        ) : (
          <CardContent sx={{ textAlign: 'left' }}>
            <Typography sx={styles.headerStyle} data-testid="header">
              {LanguageConverterV2(header)}
            </Typography>
            <Typography sx={styles.subHeaderStyle} data-testid="subHeader">
              {LanguageConverterV2(subHeader)}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box
                sx={{
                  position: 'relative',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isCompleted ? (
                  <CircleCheckMarkIcon />
                ) : (
                  <CircularProgressArrowIcon color={color} number={count} />
                )}
                <Box sx={styles.textContainer} />
              </Box>
              <Box>
                <Typography sx={styles.bodyStyle} data-testid="body">
                  {LanguageConverterV2(body)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                disabled={isCompleted}
                variant="contained"
                sx={styles.buttonStyle}
                data-testid="button"
              >
                {LanguageConverterV2(buttonText)}
              </Button>
            </Box>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

RemainingActionsCounterCard.propTypes = {
  isLoading: PropTypes.bool,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  body: PropTypes.string,
  count: PropTypes.number,
  color: PropTypes.string,
  buttonText: PropTypes.string,
  isCompleted: PropTypes.bool,
};

export default RemainingActionsCounterCard;
