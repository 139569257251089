import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import DNRReasons from '@careerstart/wae-common/src/main/constants/DNR-reasons';
import { Box, Grid, Typography } from '@mui/material';

import { updateDNR } from '../../../../../../main-deprecated/views/users/reducer';
import AddDeleteAccordionListCard from '../../../../../components/AddDeleteAccordionListCard';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';

import DNRBodyContent from './AccordionBodyContent/DNRBodyContent';
import {
  corporationAutoComplete,
  reasonAutoComplete,
} from './FormFieldData/UserDetailAccordionFormFieldData';
import {
  emptyContentBodyTextStyle,
  emptyContentTitleTextStyle,
} from './UserDetailAccordionCardsStyles';

const dnrReasonOptions = [];

Object.keys(DNRReasons).forEach((key) => {
  dnrReasonOptions.push({ label: DNRReasons[key] });
});

const DNRWorkersAccordionCard = ({ dnrList, isProcessing }) => {
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const dnrContentList = dnrList?.map((content) => ({
    key: content.id,
    content: (
      <DNRBodyContent
        waeID={content.id}
        timestamp={content.timestamp}
        name={content.corpName}
        reason={content.reason}
        requester={content.requester}
      />
    ),
    deleteApiRequest: {
      id: content.corpId,
      userID: id,
      remove: true,
    },
  }));

  const addContentFormComponentBody = () => (
    <Grid>
      <Box>{buildFormField(corporationAutoComplete())}</Box>
      <Box>{buildFormField(reasonAutoComplete({ reasons: dnrReasonOptions }))}</Box>
    </Grid>
  );

  const submitAddDNR = (value) => {
    const submitDNR = {
      id: value?.corporation._id,
      userID: id,
      reason: value?.reason.label,
    };
    dispatch(updateDNR(submitDNR));
  };
  const deleteDNR = (apiRequestBody) => {
    dispatch(updateDNR(apiRequestBody));
  };

  const modalContent = {
    title: 'corporation.accordion.deleteDNRModalTitle',
    body: 'corporation.accordion.deleteDNRModalBody',
  };

  const dnrDetailEmptyContentBody = (
    <Box>
      <Box>
        <Typography sx={emptyContentTitleTextStyle}>
          {intl.formatMessage({ id: 'userDetail.DNRAccordion.emptyTitleText' })}
          <ul>
            <li>{intl.formatMessage({ id: 'userDetail.DNRAndDNAAccordion.viewingJobs' })}</li>
            <li>{intl.formatMessage({ id: 'userDetail.DNRAndDNAAccordion.signUpForJobs' })}</li>
            <li>
              {intl.formatMessage({ id: 'userDetail.DNRAndDNAAccordion.gettingInvitedToJobs' })}
            </li>
          </ul>
        </Typography>
      </Box>
      <Typography sx={emptyContentBodyTextStyle}>
        {intl.formatMessage({ id: 'userDetail.DNRAccordion.emptyBodyText' })}
      </Typography>
    </Box>
  );
  return (
    <AddDeleteAccordionListCard
      addButtonName="corporation.accordion.addDNR"
      addContentFormBody={addContentFormComponentBody()}
      accordionDetailEmptyContentBody={dnrDetailEmptyContentBody}
      accordionDetailContent={dnrContentList}
      handleOnDelete={deleteDNR}
      modalContent={modalContent}
      isAddDeleteEnabled
      accordionSummaryTitle="userDetail.DNR.summaryTitle"
      isProcessing={isProcessing}
      onAddFormSubmit={submitAddDNR}
      wrapDetailContentInBorder
    />
  );
};

DNRWorkersAccordionCard.propTypes = {
  dnrList: PropTypes.arrayOf(PropTypes.shape({})),
  isProcessing: PropTypes.bool,
};
export default DNRWorkersAccordionCard;
