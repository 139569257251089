import { createSelector } from 'reselect';

export const selectUsersList = (state) => state?.usersList;

export const selectUserListData = createSelector(selectUsersList, (state) => state);

export const selectUserDetail = createSelector(selectUsersList, (state) => state.userDetail);

export const selectHasUpdated = createSelector(selectUsersList, (state) => state.hasUpdated);

export const selectUpdateUserIsProcessing = createSelector(
  selectUsersList,
  (state) => state.isUpdateProcessing
);

export const selectWorkerPlacements = createSelector(
  selectUsersList,
  (state) => state.workerPlacements
);

export const selectIsLoadingWorkerPlacements = createSelector(
  selectUsersList,
  (state) => state.isLoadingWorkerPlacements
);
export const selectIsUserDetailLoading = createSelector(
  selectUsersList,
  (state) => state.isUserDetailLoading
);
export const selectPendingWorkerPlacements = createSelector(
  selectUsersList,
  (state) => state.pendingWorkerPlacements
);

export const selectIsLoadingPendingWorkerPlacements = createSelector(
  selectUsersList,
  (state) => state.isLoadingPendingWorkerPlacements
);
export const selectPendingOffers = createSelector(selectUsersList, (state) => state.pendingOffers);

export const selectIsLoadingPendingOffers = createSelector(
  selectUsersList,
  (state) => state.isLoadingPendingOffers
);
