import React, { useState } from 'react';
import { millisecondsToMinutes } from 'date-fns';
import PropTypes from 'prop-types';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Modal, Typography, useMediaQuery } from '@mui/material';

import SirenIcon from '../../../../../main/assets/icons/SirenIcon';
import WaeButton, { BUTTON_TYPE } from '../../../../../main/components/Button';
import { BLACK, PRIMARY_COLOR, PRIMARY_PURPLE } from '../../../../../main/theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../main/theme/fontConstants';
import { epochToTimeInReadableFormat } from '../../../../../main/utils';
import { BUTTON_VARIANT } from '../../../../components/Button';

const styles = {
  title: {
    fontSize: '20px',
    color: BLACK[80],
    fontFamily: PRIMARY_FONT[700],
  },
  sectionTitle: {
    fontSize: '16px',
    color: BLACK[80],
    fontFamily: PRIMARY_FONT[700],
  },
  itemTitle: {
    fontSize: '20px',
    color: BLACK[80],
    fontFamily: PRIMARY_FONT[300],
    lineHeight: '24px',
  },
  itemBody: {
    fontSize: '20px',
    color: BLACK[80],
    fontFamily: PRIMARY_FONT[400],
    lineHeight: '24px',
  },
  reason: {
    fontSize: '20px',
    color: BLACK[80],
    fontFamily: PRIMARY_FONT[300],
  },
};

const PunchCard = ({
  title,
  clockIn,
  clockOut,
  breakMins,
  hours,
  isClockInDifferent,
  isClockOutDifferent,
  isBreakDifferent,
}) => (
  <Box>
    <Typography sx={styles.sectionTitle}>{title}</Typography>
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Typography sx={styles.itemTitle}>
          {LanguageConverterV2('timekeeping.daily.disputeConfirmation.clockIn')}
        </Typography>
        <Typography
          sx={{ ...styles.itemBody, color: isClockInDifferent ? PRIMARY_PURPLE[70] : BLACK[80] }}
        >
          {clockIn}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography sx={styles.itemTitle}>
          {LanguageConverterV2('timekeeping.daily.disputeConfirmation.clockOut')}
        </Typography>
        <Typography
          sx={{ ...styles.itemBody, color: isClockOutDifferent ? PRIMARY_PURPLE[70] : BLACK[80] }}
        >
          {clockOut}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography sx={styles.itemTitle}>
          {LanguageConverterV2('timekeeping.daily.disputeConfirmation.break')}
        </Typography>
        <Typography
          sx={{ ...styles.itemBody, color: isBreakDifferent ? PRIMARY_PURPLE[70] : BLACK[80] }}
        >
          {breakMins}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography sx={styles.itemTitle}>
          {LanguageConverterV2('timekeeping.daily.disputeConfirmation.totalTime')}
        </Typography>
        <Typography sx={styles.itemBody}>{hours}</Typography>
      </Box>
    </Box>
  </Box>
);
PunchCard.propTypes = {
  title: PropTypes.string,
  clockIn: PropTypes.string,
  clockOut: PropTypes.string,
  breakMins: PropTypes.string,
  hours: PropTypes.string,
  isClockInDifferent: PropTypes.bool,
  isClockOutDifferent: PropTypes.bool,
  isBreakDifferent: PropTypes.bool,
};

const DisputeConfirmationModal = ({
  onSubmit,
  isProcessing,
  currentClockIn,
  currentClockOut,
  currentBreak,
  currentTotalTime,
  disputedClockIn,
  disputedClockOut,
  disputedBreak,
  disputedTotalTime,

  reason,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const smallScreen = useMediaQuery('(max-width:1100px)');
  const onClose = () => setIsOpen(false);

  const handleOnClick = () => {
    onSubmit();
    onClose();
  };

  return (
    <>
      <WaeButton
        variant={BUTTON_VARIANT.DEFAULT}
        actionColor={PRIMARY_COLOR[70]}
        onClick={() => setIsOpen(true)}
        startIcon={<SirenIcon />}
      >
        {LanguageConverterV2('timekeeping.daily.disputeConfirmation.viewDispute')}
      </WaeButton>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          container
          justifyContent="center"
          sx={{
            position: 'relative',
            bgcolor: 'background.paper',
            borderRadius: 3,
            boxShadow: 24,
            p: 3,
            width: smallScreen ? '80vw' : '50vw',
            maxWidth: '800px',
            maxHeight: '90vh',
            overflow: 'hidden',
          }}
        >
          <Box display="flex" flexDirection="column" gap={3}>
            <Box>
              <Typography sx={styles.title}>
                {LanguageConverterV2('timekeeping.daily.disputeConfirmation.disputeDetails')}
              </Typography>
            </Box>
            <PunchCard
              title={LanguageConverterV2('timekeeping.daily.disputeConfirmation.currentTimeCard')}
              clockIn={epochToTimeInReadableFormat(currentClockIn)}
              clockOut={epochToTimeInReadableFormat(currentClockOut)}
              breakMins={`${millisecondsToMinutes(currentBreak)} Min`}
              hours={`${currentTotalTime} Hours`}
            />
            <PunchCard
              title={LanguageConverterV2('timekeeping.daily.disputeConfirmation.changeRequested')}
              clockIn={epochToTimeInReadableFormat(disputedClockIn)}
              clockOut={epochToTimeInReadableFormat(disputedClockOut)}
              breakMins={`${millisecondsToMinutes(disputedBreak)} ${LanguageConverterV2(
                'timekeeping.daily.disputeConfirmation.minute'
              )}`}
              hours={`${disputedTotalTime} ${LanguageConverterV2(
                'timekeeping.daily.disputeConfirmation.hours'
              )}`}
              isClockInDifferent={currentClockIn !== disputedClockIn}
              isClockOutDifferent={currentClockOut !== disputedClockOut}
              isBreakDifferent={currentBreak !== disputedBreak}
            />

            <Box>
              <Typography sx={styles.reason}>
                {`${LanguageConverterV2(
                  'timekeeping.daily.disputeConfirmation.reason'
                )}: ${reason}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <WaeButton
                text={LanguageConverterV2('timekeeping.daily.disputeConfirmation.close')}
                onClick={onClose}
                sx={{
                  color: BLACK[70],
                  backgroundColor: BLACK[0],
                  border: '1px solid black',
                }}
                hoverColor={PRIMARY_COLOR[20]}
              />
              <WaeButton
                data-testid="modalSubmitButton"
                type={BUTTON_TYPE.SUBMIT}
                text={LanguageConverterV2('timekeeping.daily.disputeConfirmation.acceptAndUpdate')}
                disabled={isProcessing}
                onClick={handleOnClick}
                sx={{
                  backgroundColor: PRIMARY_PURPLE[70],
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
DisputeConfirmationModal.propTypes = {
  onSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,

  currentClockIn: PropTypes.number,
  currentClockOut: PropTypes.number,
  currentBreak: PropTypes.number,
  currentTotalTime: PropTypes.number,

  disputedClockIn: PropTypes.number,
  disputedClockOut: PropTypes.number,
  disputedBreak: PropTypes.number,
  disputedTotalTime: PropTypes.number,

  reason: PropTypes.string,
};

export default DisputeConfirmationModal;
