import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { convertPreferredTimesFromApiToUiFormat } from '../../../../../components/UserCard/helpers';
import theme from '../../../../../theme';
import { formatTimePreference } from '../../../UsersHelpers';
/* eslint-disable react/no-array-index-key */
const styles = theme?.components?.userDetail;
const WorkPreferencesCard = ({ preference }) => {
  const timeObj = convertPreferredTimesFromApiToUiFormat(preference?.preferredShiftTimes || []);

  return (
    <Box data-testid="work-preferences-card">
      <Typography sx={styles.headerStyle}>
        {LanguageConverter('userDetail.preference.header')}
      </Typography>
      <Card variant="outlined" sx={styles.cardStyle}>
        <CardContent>
          <Typography sx={styles.subHeaderStyle}>
            {LanguageConverter('userDetail.preference.location')}
          </Typography>
          {timeObj?.map((time, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{
                ...styles.textStyle,
                my: 1,
              }}
            >
              {formatTimePreference(time)}
            </Typography>
          ))}
          <Typography sx={styles.subHeaderStyle} data-testid="work-preferences-card-distance">
            {LanguageConverter('userDetail.preference.distance')}
          </Typography>
          <Typography sx={styles.textStyle}>
            {`${preference?.distance} ${LanguageConverter('userDetail.preference.distance.miles')}`}
          </Typography>
          <Typography sx={styles.subHeaderStyle}>
            {LanguageConverter('userDetail.preference.payRange')}
          </Typography>
          <Typography sx={styles.textStyle} data-testid="work-preferences-card-minPay">
            {`${preference?.minPay} ${LanguageConverter('userDetail.preference.payRate.perHour')}`}
          </Typography>
          <Typography sx={styles.subHeaderStyle} data-testid="work-preferences-card-weeklyHours">
            {LanguageConverter('userDetail.preference.weeklyHours')}
          </Typography>
          <Typography sx={styles.textStyle}>
            {`${preference?.desiredWeeklyHours} ${LanguageConverter(
              'userDetail.preference.weeklyHours.hours'
            )}`}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

WorkPreferencesCard.propTypes = {
  preference: PropTypes.shape({
    preferredShiftTimes: PropTypes.shape({}),
    distance: PropTypes.number,
    minPay: PropTypes.number,
    desiredWeeklyHours: PropTypes.number,
  }),
};

export default WorkPreferencesCard;
