import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import Button, { BUTTON_VARIANT } from '../../../../../../main-deprecated/components/Button';
import CheckBox from '../../../../../../main-deprecated/components/CheckBox/CheckBox';
import Dialog from '../../../../../../main-deprecated/components/Dialog';
import theme from '../../../../../theme/theme';
import { updateUser } from '../../../usersListReducer';

const styles = theme?.components?.userDetail;
const WAEOnboardingStatusCard = ({ onboardingDoc, eVerify, selectedUserId }) => {
  const dispatch = useDispatch();

  const [valueForModal, setValueForModal] = useState(null);

  const getEVerifiedByName = (val) => val?.verifiedByName || '--';
  const handleOnChange = useCallback(
    (val) =>
      onboardingDoc && eVerify
        ? setValueForModal(val)
        : dispatch(updateUser({ id: selectedUserId, fields: { candidate: val } })),
    [dispatch, onboardingDoc, selectedUserId, eVerify]
  );
  const handleOnUnConfirm = useCallback(() => {
    dispatch(updateUser({ id: selectedUserId, fields: { candidate: valueForModal } }));
    setValueForModal(null);
  }, [setValueForModal, dispatch, selectedUserId, valueForModal]);

  const body = (
    <Grid container>
      <Typography
        sx={{
          color: theme?.candidate?.components?.readyToWorkUnConfirmModal?.typography?.body?.color,
        }}
      >
        {LanguageConverter('user.onboardingDocs.unconfirm.body')}
      </Typography>
    </Grid>
  );

  const dialogButtons = [
    <Button
      text={LanguageConverter('generic.goBack')}
      key="handle-go-back-button"
      onClick={() => setValueForModal(null)}
    />,

    <Button
      text={LanguageConverter('generic.unconfirm')}
      variant={BUTTON_VARIANT.DELETE}
      key="handle-unConfirm-button"
      onClick={handleOnUnConfirm}
    />,
  ];

  return (
    <Box>
      <Typography sx={styles.headerStyle} data-testid="wae-onboarding-status-card-header">
        {LanguageConverter('userDetail.waeAccess.header')}
      </Typography>
      <Card variant="outlined" sx={styles.cardStyle}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CheckBox
              onChange={(event) => handleOnChange({ onboardingDoc: event.target.checked })}
              checked={!!onboardingDoc}
              label="Onboarding Documentation Complete"
              name="onboardingDoc"
              variant="standard"
              fullWidth
              sx={{
                '& .MuiFormControlLabel-label': styles.checkBoxLabel,
              }}
            />
            <Typography sx={styles.nameStyle}>{getEVerifiedByName(onboardingDoc)}</Typography>
            <CheckBox
              onChange={(event) => handleOnChange({ eVerify: event.target.checked })}
              checked={!!eVerify}
              label="E-Verified"
              name="eVerified"
              variant="standard"
              fullWidth
              sx={{
                '& .MuiFormControlLabel-label': styles.checkBoxLabel,
              }}
            />
            <Typography sx={styles.nameStyle}>{getEVerifiedByName(eVerify)}</Typography>
          </Box>
          <Dialog
            open={!!valueForModal}
            dialogTitle={LanguageConverter('user.onboardingDocs.unconfirm.title')}
            dialogContentText={body}
            dialogActions={dialogButtons}
            onBackdropClick={() => setValueForModal(null)}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

WAEOnboardingStatusCard.propTypes = {
  onboardingDoc: PropTypes.shape({}),
  eVerify: PropTypes.shape({}),
  selectedUserId: PropTypes.string,
};

export default WAEOnboardingStatusCard;
