import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

import { BLACK, PRIMARY_PURPLE } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { INDUSTRY_OPTIONS } from '../constant';

const checkLabelStyle = { fontFamily: PRIMARY_FONT[700], fontSize: '18px' };
const checkBoxStyle = {
  color: PRIMARY_PURPLE[70],
  '&.Mui-checked': {
    color: PRIMARY_PURPLE[70],
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '50%',
  },
};

const checkContainerStyle = {
  justifyContent: 'space-between',
  marginLeft: 0,
  border: `1px solid ${BLACK[60]}`,
  borderRadius: '8px',
  paddingLeft: '10px',
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: PRIMARY_PURPLE[20],
  },
};

const industryHeaderStyle = { fontFamily: PRIMARY_FONT[700], fontSize: '20px' };
const IndustriesCard = ({ selectedIndustries, onChange, error, required }) => {
  const handleChange = (industry) => (event) => {
    const newIndustries = event.target.checked
      ? [...selectedIndustries, industry]
      : selectedIndustries.filter((item) => item !== industry);
    onChange(newIndustries);
  };

  return (
    <Card sx={{ width: '25%', mt: 2, p: 2, minWidth: '350px' }} data-testid="industry-picker-card">
      <CardContent>
        <Typography sx={industryHeaderStyle} gutterBottom>
          {LanguageConverterV2('dashboard.agency.create.industries.title')}
          {required && (
            <Typography style={{ color: PRIMARY_PURPLE[70], marginLeft: '4px' }}>*</Typography>
          )}
        </Typography>
        <Typography sx={{ fontSize: '16px' }} gutterBottom>
          {LanguageConverterV2('dashboard.agency.create.industries.subtitle')}
        </Typography>
        <FormGroup>
          <FormControlLabel
            labelPlacement="start"
            sx={checkContainerStyle}
            control={
              <Checkbox
                data-testid="manufacturing-checkbox"
                checked={selectedIndustries.includes('manufacturing')}
                onChange={handleChange('manufacturing')}
                name={INDUSTRY_OPTIONS.MANUFACTURING}
                sx={checkBoxStyle}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            label={
              <Typography sx={checkLabelStyle}>
                {LanguageConverterV2('dashboard.agency.create.industries.manufacturing')}
              </Typography>
            }
          />
          <FormControlLabel
            labelPlacement="start"
            sx={checkContainerStyle}
            control={
              <Checkbox
                data-testid="hospitality-checkbox"
                checked={selectedIndustries.includes('hospitality')}
                onChange={handleChange('hospitality')}
                name={INDUSTRY_OPTIONS.HOSPITALITY}
                sx={checkBoxStyle}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            label={
              <Typography sx={checkLabelStyle}>
                {LanguageConverterV2('dashboard.agency.create.industries.hospitality')}
              </Typography>
            }
          />
          <FormControlLabel
            labelPlacement="start"
            sx={checkContainerStyle}
            control={
              <Checkbox
                data-testid="healthcare-checkbox"
                checked={selectedIndustries.includes('healthcare')}
                onChange={handleChange('healthcare')}
                name={INDUSTRY_OPTIONS.HEALTHCARE}
                sx={checkBoxStyle}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            label={
              <Typography sx={checkLabelStyle}>
                {LanguageConverterV2('dashboard.agency.create.industries.healthcare')}
              </Typography>
            }
          />
        </FormGroup>
        {error && (
          <Typography
            sx={{
              color: 'error.main',
              fontSize: '14px',
              mt: 1,
            }}
            data-testid="industry-error-message"
          >
            {LanguageConverterV2(error)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

IndustriesCard.propTypes = {
  selectedIndustries: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
};

export default IndustriesCard;
