import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid } from '@mui/material';

import { BLACK, PRIMARY_PURPLE } from '../../theme/colorConstants';
import Button from '../Button';

const AddDeleteAccordionListCardFormComponent = ({ handleSubmit, addContentFormBody }) => {
  const formState = useFormState();

  return (
    <Grid sx={{ height: '100%', width: '100%' }}>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
        }}
      >
        <Grid container item direction="column">
          {addContentFormBody}
          <Grid container item direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button
              data-testid="modalSubmitButton"
              text={LanguageConverter('corporation.accordion.add')}
              onClick={handleSubmit}
              sx={{
                backgroundColor:
                  Object.keys(formState.errors).length > 0 ? BLACK[60] : PRIMARY_PURPLE[70],
                height: 28,
                width: 76,
              }}
              disabled={Object.keys(formState.errors).length > 0}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

AddDeleteAccordionListCardFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  addContentFormBody: PropTypes.element,
};

export default AddDeleteAccordionListCardFormComponent;
