import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { convertReliabilityScoreToDisplayValue } from '@careerstart/wae-common/src/main/helperFunction/convertReliabilityScoreToDisplayValue';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Card, CardContent } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import theme from '../../theme/theme';

import stringAvatar from './SubComponent/stringAvatar';

const CandidatePickerCard = ({ isSelected, onClick, sx, user }) => {
  const [selected, setSelected] = useState(isSelected || false);

  const handleCardClick = (candidate) => {
    setSelected((prevSelected) => !prevSelected);
    onClick && onClick(candidate);
  };

  const generateScoreColor = (score) => {
    const numericScore = parseFloat(score);
    if (numericScore >= 60) {
      return `${theme.components.candidatePickerCard.palette.reliableGreen}`;
    }
    if (numericScore > 40 && numericScore < 60) {
      return `${theme.components.candidatePickerCard.palette.reliableYellow}`;
    }
    return `${theme.components.candidatePickerCard.palette.reliableRed}`;
  };
  return (
    <Box>
      <Card
        sx={{
          width: 148,
          height: 160,
          backgroundColor: selected
            ? `${theme.components.candidatePickerCard.palette.backgroundColor}`
            : `${theme.components.candidatePickerCard.palette.defaultBackgroundColor}`,
          borderRadius: 4,
          ...sx,
        }}
        onClick={() => handleCardClick(user)}
        data-testid="invite-card"
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {user?.imageUrl ? (
              <Avatar alt={`${user.name}`} src={`${user.imageUrl}`} />
            ) : (
              <Avatar {...stringAvatar(user)} />
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {user?.favorite ? (
              <FavoriteIcon
                sx={{ color: theme.components.candidatePickerCard.palette.favoriteIconColor }}
              />
            ) : null}
            <Tooltip title={user?.name}>
              <Typography
                sx={{
                  fontFamily: `${theme.components.candidatePickerCard.typography.title.fontFamily}`,
                  fontSize: `${theme.components.candidatePickerCard.typography.title.fontSize}`,
                  width: 120,
                  textAlign: 'center',
                  textDecoration: 'underline',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {user?.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: `${theme.components.candidatePickerCard.typography.subtitle.fontFamily}`,
                fontSize: `${theme.components.candidatePickerCard.typography.subtitle.fontSize}`,
              }}
            >
              {LanguageConverter('component.candidatePickerCard.reliabilityScore')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: generateScoreColor(
                  convertReliabilityScoreToDisplayValue(user?.reliabilityScore)
                ),
              }}
            >
              {convertReliabilityScoreToDisplayValue(user?.reliabilityScore)}
            </Typography>
          </Box>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
              checked={selected}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  );
};

CandidatePickerCard.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
  user: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    favorite: PropTypes.bool,
    reliabilityScore: PropTypes.string,
  }),
};

export default CandidatePickerCard;
