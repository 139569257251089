export const AGENCY_CREATE_FIELDS = {
  NAME: 'name',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  INDUSTRIES: 'industries',
};

export const INDUSTRY_OPTIONS = {
  HOSPITALITY: 'hospitality',
  MANUFACTURING: 'manufacturing',
  HEALTHCARE: 'healthcare',
};
