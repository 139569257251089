import React from 'react';
import PropTypes from 'prop-types';

import { USER_STATUS } from '@careerstart/wae-common/src/main/constants/USER_EDITABLE_FIELDS';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import CopyLinkIcon from '../../../../assets/icons/CopyLinkIcon';
import AgencyCard from '../../../../components/UserCard/components/AgencyCard';
import { ROLE_ICONS } from '../../../../components/UserCard/constant';
import { FEED_BACK, PRIMARY_PURPLE } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

import ResendVerificationEmailBtn from './ResendVerificationEmailBtn';
import styles from './styles';

const RecruiterDetailCard = ({ selectedUser, onCopyId }) => {
  const NON_CANDIDATE_STATUS_CONFIG = {
    active: {
      displayName: LanguageConverterV2('userCard.status.active'),
      color: FEED_BACK.GREEN_70,
      getIcon: (role) => ROLE_ICONS[role]?.active || ROLE_ICONS.recruiter.active,
    },
    deactivated: {
      displayName: LanguageConverterV2('userCard.status.deactivated'),
      color: FEED_BACK.RED_75,
      getIcon: (role) => ROLE_ICONS[role]?.deactivated || ROLE_ICONS.recruiter.deactivated,
    },
    unverified: {
      displayName: LanguageConverterV2('userCard.status.incomplete'),
      color: PRIMARY_PURPLE[90],
      getIcon: (role) => ROLE_ICONS[role]?.unverified || ROLE_ICONS.recruiter.unverified,
    },
  };
  const currentStatus = NON_CANDIDATE_STATUS_CONFIG[selectedUser?.status];
  const statusIcon = currentStatus.getIcon(UserRole.RECRUITER);
  return (
    <Box sx={styles.rootBox}>
      <Card variant="outlined" sx={styles.card}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={1} paddingBottom={1}>
            <Typography sx={{ fontFamily: PRIMARY_FONT[500] }}>
              {LanguageConverterV2('recruiterDetailCard.header.status')}
            </Typography>
            {statusIcon}
            <Typography sx={{ fontFamily: PRIMARY_FONT[500], color: currentStatus?.color }}>
              {currentStatus?.displayName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Box>
              <Typography sx={{ ...styles.workerTitle, textTransform: 'capitalize' }}>
                {selectedUser?.role}
              </Typography>

              <Typography sx={styles.roleName}>{selectedUser?.name}</Typography>
              <Box
                display="flex"
                alignItems="center"
                onClick={onCopyId}
                sx={{ cursor: 'pointer', gap: 1 }}
              >
                <Typography sx={styles.copyUser}>
                  {LanguageConverterV2('recruiterDetailCard.subTitle.copyUserId')}
                </Typography>
                <CopyLinkIcon />
              </Box>
            </Box>
          </Box>
          <Box mt={2}>
            <Box display="flex" alignItems="baseline" gap={1}>
              <Typography sx={styles.subTitleStyle}>
                {LanguageConverterV2('recruiterDetailCard.subTitle.email')}
              </Typography>
              <Typography sx={styles.emailStyle}>{selectedUser?.email}</Typography>
            </Box>
          </Box>
          {selectedUser?.status === USER_STATUS.UNVERIFIED && (
            <ResendVerificationEmailBtn
              userId={selectedUser?.id}
              email={selectedUser.email}
              isVerificationEmailButtonDisabled={false}
              isUserDeactivated={selectedUser?.status === USER_STATUS.DEACTIVATED}
            />
          )}

          {selectedUser?.status !== USER_STATUS.DEACTIVATED && (
            <AgencyCard
              agency={selectedUser?.recruiter?.agency}
              userName={selectedUser?.name}
              userRole={selectedUser?.role}
              userId={selectedUser?.id}
            />
          )}
        </CardContent>
        {selectedUser?.lastLoginDate && (
          <CardActions
            sx={{
              p: 2,
              justifyContent: 'flex-start',
              bottom: 0,
              width: '100%',
              mt: 'auto',
            }}
          >
            <Typography sx={styles.lastLoginHeader}>
              {LanguageConverterV2('recruiterDetailCard.subTitle.lastLoggedIn')}
            </Typography>
            <Typography sx={styles.lastLoginTime}>{selectedUser?.lastLoginDate}</Typography>
          </CardActions>
        )}
      </Card>
    </Box>
  );
};

RecruiterDetailCard.propTypes = {
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lastLoginDate: PropTypes.string,
    corporationName: PropTypes.string,
    agency: PropTypes.string,
    recruiter: PropTypes.shape({
      agency: PropTypes.string,
    }),
  }).isRequired,
  onCopyId: PropTypes.func,
};

export default RecruiterDetailCard;
