import React from 'react';
import { useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';

import { selectUserDetail } from '../../../store/selectors/userListSelector';
import { USER_STATUS } from '../UsersConstants';

import OnboardingIncompleteCard from './components/OnboardingIncompleteCard';
import WaitingVerificationCard from './components/WaitingVerificationCard';
import ActiveUserView from './components/workerComponents/ActiveUserView';

const UserDetailRight = () => {
  const selectedUser = useSelector(selectUserDetail)?.[0];

  const renderCard = (status) => {
    switch (status) {
      case USER_STATUS.ONBOARDING_INCOMPLETE:
        return <OnboardingIncompleteCard />;
      case USER_STATUS.UNVERIFIED:
        return <WaitingVerificationCard />;
      case USER_STATUS.SUSPENDED:
      case USER_STATUS.DEACTIVATED:
      case USER_STATUS.DNA:
      case USER_STATUS.ACTIVE:
        return <ActiveUserView selectedUser={selectedUser} />;
      default:
        return <Box>{LanguageConverter('userDetail.unrecognized')}</Box>;
    }
  };

  return (
    <Box>
      <Box>{renderCard(selectedUser?.status)}</Box>
    </Box>
  );
};

export default UserDetailRight;
