import * as React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

const ThreeStrikesIcon = ({ sx }) => (
  <SvgIcon sx={sx}>
    <svg width="49" height="20" viewBox="0 0 49 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.3131 4.43546C5.41363 3.70011 6.70751 3.30762 8.03111 3.30762C8.90995 3.30762 9.78018 3.48072 10.5921 3.81703C11.4041 4.15335 12.1418 4.6463 12.7632 5.26773C13.3847 5.88916 13.8776 6.62691 14.2139 7.43885C14.5503 8.25079 14.7233 9.12102 14.7233 9.99986C14.7233 11.3235 14.3309 12.6173 13.5955 13.7179C12.8602 14.8184 11.815 15.6762 10.5921 16.1827C9.36927 16.6892 8.02369 16.8217 6.72552 16.5635C5.42735 16.3053 4.23491 15.6679 3.29898 14.732C2.36306 13.7961 1.72568 12.6036 1.46746 11.3054C1.20924 10.0073 1.34177 8.66169 1.84829 7.43885C2.35481 6.216 3.21257 5.17082 4.3131 4.43546Z"
        fill="#B23E3E"
      />
      <path
        d="M10.536 8.32145C10.7644 8.09311 10.7644 7.72291 10.536 7.49457C10.3077 7.26624 9.93748 7.26624 9.70915 7.49457L8.03043 9.17329L6.35164 7.49449C6.1233 7.26616 5.7531 7.26616 5.52477 7.49449C5.29643 7.72283 5.29643 8.09303 5.52477 8.32136L7.20356 10.0002L5.52481 11.6789C5.29647 11.9072 5.29647 12.2774 5.52481 12.5058C5.75314 12.7341 6.12334 12.7341 6.35168 12.5058L8.03043 10.827L9.7091 12.5057C9.93744 12.734 10.3076 12.734 10.536 12.5057C10.7643 12.2774 10.7643 11.9072 10.536 11.6788L8.8573 10.0002L10.536 8.32145Z"
        fill="white"
      />
      <path
        d="M20.3746 4.43546C21.4752 3.70011 22.769 3.30762 24.0926 3.30762C24.9715 3.30762 25.8417 3.48072 26.6536 3.81703C27.4656 4.15335 28.2033 4.6463 28.8248 5.26773C29.4462 5.88916 29.9391 6.62691 30.2755 7.43885C30.6118 8.25079 30.7849 9.12102 30.7849 9.99986C30.7849 11.3235 30.3924 12.6173 29.657 13.7179C28.9217 14.8184 27.8765 15.6762 26.6536 16.1827C25.4308 16.6892 24.0852 16.8217 22.787 16.5635C21.4889 16.3053 20.2964 15.6679 19.3605 14.732C18.4246 13.7961 17.7872 12.6036 17.529 11.3054C17.2708 10.0073 17.4033 8.66169 17.9098 7.43885C18.4163 6.216 19.2741 5.17082 20.3746 4.43546Z"
        fill="#B23E3E"
      />
      <path
        d="M26.5975 8.32145C26.8259 8.09311 26.8259 7.72291 26.5975 7.49457C26.3692 7.26624 25.999 7.26624 25.7707 7.49457L24.092 9.17329L22.4132 7.49449C22.1848 7.26616 21.8146 7.26616 21.5863 7.49449C21.358 7.72283 21.358 8.09303 21.5863 8.32136L23.2651 10.0002L21.5863 11.6789C21.358 11.9072 21.358 12.2774 21.5863 12.5058C21.8147 12.7341 22.1849 12.7341 22.4132 12.5058L24.092 10.827L25.7706 12.5057C25.999 12.734 26.3692 12.734 26.5975 12.5057C26.8258 12.2774 26.8258 11.9072 26.5975 11.6788L24.9188 10.0002L26.5975 8.32145Z"
        fill="white"
      />
      <path
        d="M36.4361 4.43546C37.5367 3.70011 38.8306 3.30762 40.1542 3.30762C41.033 3.30762 41.9032 3.48072 42.7152 3.81703C43.5271 4.15335 44.2649 4.6463 44.8863 5.26773C45.5077 5.88916 46.0007 6.62691 46.337 7.43885C46.6733 8.25079 46.8464 9.12102 46.8464 9.99986C46.8464 11.3235 46.4539 12.6173 45.7185 13.7179C44.9832 14.8184 43.938 15.6762 42.7152 16.1827C41.4923 16.6892 40.1467 16.8217 38.8486 16.5635C37.5504 16.3053 36.358 15.6679 35.422 14.732C34.4861 13.7961 33.8487 12.6036 33.5905 11.3054C33.3323 10.0073 33.4648 8.66169 33.9713 7.43885C34.4779 6.216 35.3356 5.17082 36.4361 4.43546Z"
        fill="#B23E3E"
      />
      <path
        d="M42.6591 8.32145C42.8874 8.09311 42.8874 7.72291 42.6591 7.49457C42.4307 7.26624 42.0605 7.26624 41.8322 7.49457L40.1535 9.17329L38.4747 7.49449C38.2463 7.26616 37.8761 7.26616 37.6478 7.49449C37.4195 7.72283 37.4195 8.09303 37.6478 8.32136L39.3266 10.0002L37.6479 11.6789C37.4195 11.9072 37.4195 12.2774 37.6479 12.5058C37.8762 12.7341 38.2464 12.7341 38.4747 12.5058L40.1535 10.827L41.8322 12.5057C42.0605 12.734 42.4307 12.734 42.659 12.5057C42.8874 12.2774 42.8874 11.9072 42.659 11.6788L40.9803 10.0002L42.6591 8.32145Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

ThreeStrikesIcon.propTypes = {
  sx: PropTypes.shape({}),
};

export default ThreeStrikesIcon;
