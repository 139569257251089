import * as React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import CorporationIcon from '../../../assets/icons/CorporationIcon.svg';
import { SEARCHBAR_BACKGROUND } from '../../../components/FreeTextSearchFilter';
import MultipleSelectDropdownChip from '../../../components/MultipleSelectDropdownChip/MultipleSelectDropdownChip';
import { SearchableSelectDropdownFilter } from '../../../components/SearchableSelectDropdownFilter/SearchableSelectDropdownFilter';

const corporationsAPICallback = {
  httpMethod: 'POST',
  route: 'corporations/read',
  generateBody: (searchTerm) => ({
    filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
  }),
};
const DashboardFilters = ({
  isLoading,
  handleCorporationChange,
  initialSelectedCorporation,
  shiftOptions,
  handleShiftOptionChange,
  initialSelectedShifts,
}) => (
  <>
    <SearchableSelectDropdownFilter
      disabled={isLoading}
      placeholder="Corporation"
      onValueChange={handleCorporationChange}
      optionsAPICallback={corporationsAPICallback}
      getOptionLabel={(option) => option.name}
      background={SEARCHBAR_BACKGROUND.DEFAULT}
      initialValue={initialSelectedCorporation}
    />
    <MultipleSelectDropdownChip
      disabled={isLoading}
      options={shiftOptions}
      placeholder="Shifts"
      startAdornmentIcon={
        <Box
          component="img"
          sx={{
            height: 16,
            width: 15,
          }}
          alt="Status"
          src={CorporationIcon}
        />
      }
      allOptionsLabel="All Shifts"
      onValueChange={handleShiftOptionChange}
      defaultSelectedOptions={initialSelectedShifts}
    />
  </>
);

DashboardFilters.propTypes = {
  isLoading: PropTypes.bool,
  handleCorporationChange: PropTypes.func,
  initialSelectedCorporation: PropTypes.string,
  shiftOptions: PropTypes.arrayOf(PropTypes.string),
  handleShiftOptionChange: PropTypes.func,
  initialSelectedShifts: PropTypes.arrayOf(PropTypes.string),
};
export default DashboardFilters;
