import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';

import KeyboardArrowDownIcon from '../../../../../main/assets/icons/KeyboardArrowDownIcon';
import {
  selectIsDisputedTimecardUpdating,
  // selectIsWeeklyTimecardsLoading,
  selectWeeklyTimecards,
} from '../../../../store/selectors/timeSheetSelector';
import { BLACK, PRIMARY_COLOR } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

import WorkerTimecard from './WorkerTimecard';

const WorkerTimecardSet = () => {
  const candidateTimecards = useSelector(selectWeeklyTimecards);
  const [expandedItems, setExpandedItems] = useState([]);
  const [checkedMap, setCheckedMap] = useState({});
  const allIds = candidateTimecards.map((t) => t.candidate._id);

  const handleOpenAllToggle = () => {
    setExpandedItems((prev) => (prev.length === candidateTimecards.length ? [] : allIds));
  };

  const handleAccordionChange = (id) => (_, isExpanded) => {
    setExpandedItems((prev) =>
      isExpanded ? [...prev, id] : prev.filter((itemId) => itemId !== id)
    );
  };

  const isAllOpen = expandedItems.length === candidateTimecards.length;
  // const isLoading = useSelector(selectIsWeeklyTimecardsLoading);
  const isDisputedTimecardUpdating = useSelector(selectIsDisputedTimecardUpdating);

  const accordions = candidateTimecards?.map((candidateTimecard) => {
    const id = candidateTimecard.candidate._id;
    return (
      <Accordion
        key={id}
        expanded={expandedItems.includes(id)}
        onChange={handleAccordionChange(id)}
      >
        <AccordionSummary
          aria-controls="department-content"
          id="department-header"
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{ background: BLACK[10] }}
        >
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Typography
              style={{
                color: PRIMARY_COLOR[70],
                fontFamily: PRIMARY_FONT[500],
                fontWeight: '600',
                marginRight: '10px',
                fontSize: '24px',
              }}
            >
              <Checkbox
                checked={checkedMap[id] ?? false}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setCheckedMap((prev) => ({
                    ...prev,
                    [id]: e.target.checked,
                  }));
                }}
              />
              {candidateTimecard?.candidate?.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <Typography
              sx={{
                fontFamily: PRIMARY_FONT[400],
                alignSelf: 'center',
                fontSize: '16px',
                marginRight: '12px',
              }}
            >
              {LanguageConverterV2('timekeeping.workerTimecard.otHours')}:
              <span style={{ fontFamily: PRIMARY_FONT[500] }}>
                {candidateTimecard?.overtimeHours}
              </span>
            </Typography>
            <Typography
              sx={{
                fontFamily: PRIMARY_FONT[400],
                alignSelf: 'center',
                fontSize: '16px',
                marginRight: '12px',
              }}
            >
              {LanguageConverterV2('timekeeping.overviewCard.totalHours')}:
              <span style={{ fontFamily: PRIMARY_FONT[500] }}>{candidateTimecard?.totalHours}</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: PRIMARY_FONT[400],
                alignSelf: 'center',
                fontSize: '14px',
                textTransform: 'uppercase',
                marginRight: '12px',
              }}
            >
              {LanguageConverterV2('button.close')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {candidateTimecard?.timecards?.map((day) => (
            <WorkerTimecard
              key={day.placementId}
              isChecked={checkedMap[candidateTimecard.candidate._id] ?? false}
              weekday={day.start}
              jobName={day.jobName}
              shiftStart={day.start}
              shiftEnd={day.end}
              timecard={day.timecard}
              gps={day?.gps}
              attendance={day?.attendance}
              placementId={day?.placementId}
              isDisputedTimecardUpdating={isDisputedTimecardUpdating}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Stack>
      {candidateTimecards && (
        <Box display="flex" justifyContent="end">
          <Typography
            onClick={handleOpenAllToggle}
            sx={{
              width: '112px',
              border: '1px solid #ccc',
              padding: '6px 24px',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {isAllOpen
              ? LanguageConverterV2('timekeeping.timecardList.closeAll')
              : LanguageConverterV2('timekeeping.timecardList.openAll')}
          </Typography>
        </Box>
      )}
      <Stack gap="12px">{accordions}</Stack>
    </Stack>
  );
};

export default WorkerTimecardSet;
