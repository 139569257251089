import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import agencyCreateSchema from '@careerstart/wae-common/schema/agency/create.post.req.json';
import US_STATES from '@careerstart/wae-common/src/main/constants/US_STATES';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { validateAddress } from '@careerstart/wae-common/src/main/helperFunction/profileHelperss';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { validateSchema } from '../../../../main-deprecated/components/Form/validations';
import FormCloseButton from '../../../components/Form/FormCloseButton';
import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { ROUTE_CONSTANTS } from '../../app/RouteBuilding/routeConstants';
import { createAgency } from '../dashboardReducer';

import IndustriesCard from './components/IndustryPickerCard';
import { AGENCY_CREATE_FIELDS } from './constant';

const INITIAL_FORM_STATE = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  industries: [],
};

const REQUIRED_FIELDS = [
  AGENCY_CREATE_FIELDS.NAME,
  AGENCY_CREATE_FIELDS.ADDRESS1,
  AGENCY_CREATE_FIELDS.CITY,
  AGENCY_CREATE_FIELDS.STATE,
  AGENCY_CREATE_FIELDS.ZIP,
  AGENCY_CREATE_FIELDS.INDUSTRIES,
];

const inputStyle = {
  width: '300px',
  '& .MuiInputLabel-root': {
    color: BLACK[100],
    fontFamily: PRIMARY_FONT[400],
    marginBottom: '8px',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: PRIMARY_PURPLE[70],
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: BLACK[100],
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiOutlinedInput-root': {
    marginTop: '8px',
  },
};

const validateZipCode = (zipcode) => {
  const basicZipRegex = /^\d{5}$/;
  const zipPlusFourRegex = /^\d{5}-\d{4}$/;

  if (!zipcode) return 'dashboard.agency.create.required';
  if (!basicZipRegex.test(zipcode) && !zipPlusFourRegex.test(zipcode)) {
    return 'dashboard.agency.create.zip.validate';
  }
  return null;
};

const AgencyCreatePage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};

    const isEmpty = (value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }

      return (
        value === null ||
        value === undefined ||
        value === '' ||
        (typeof value === 'string' && value.trim() === '')
      );
    };

    REQUIRED_FIELDS?.forEach((field) => {
      if (isEmpty(formData[field])) {
        newErrors[field] = 'dashboard.agency.create.required';
      }
    });

    const addressValidationResult = validateAddress({
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
    });

    if (!addressValidationResult.isValid) {
      Object.entries(addressValidationResult.errors || {}).forEach(([field, error]) => {
        if (error) {
          newErrors[field] = error;
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (field) => (event) => {
    const { value } = event.target;

    if (field === AGENCY_CREATE_FIELDS.ZIP) {
      const zipError = validateZipCode(value);
      setErrors((prev) => ({
        ...prev,
        [field]: zipError && zipError,
      }));
    } else if (errors[field]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }

    setFormData((prev) => {
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value,
          },
        };
      }
      return {
        ...prev,
        [field]: value,
      };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      if (
        Object.keys(
          validateSchema(
            {
              fields: {
                ...formData,
              },
            },
            agencyCreateSchema
          )
        ).length === 0
      ) {
        dispatch(
          createAgency({
            fields: { ...formData },
          })
        );
      }
      navigate(`/${ROUTE_CONSTANTS.DASHBOARD}`);
    }
  };

  const getError = (field) => errors[field];

  const getHelperText = (field) => {
    const error = getError(field);
    return error ? LanguageConverterV2(error) : '';
  };

  return (
    <Box
      sx={{
        height: '100vh',
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        gap: '36px',
      }}
    >
      <FormCloseButton
        onClose={() => navigate(`/${ROUTE_CONSTANTS.DASHBOARD}`)}
        message="buttonText.goBack"
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontFamily: PRIMARY_FONT[700], fontSize: '24px' }}>
          {LanguageConverterV2('dashboard.agencies.addAnAgency')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextField
          label={LanguageConverterV2('dashboard.agencies.agencyName')}
          required
          value={formData.name}
          onChange={handleChange(AGENCY_CREATE_FIELDS.NAME)}
          error={!!getError(AGENCY_CREATE_FIELDS.NAME)}
          helperText={getHelperText(AGENCY_CREATE_FIELDS.NAME)}
          sx={inputStyle}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            label={LanguageConverterV2('dashboard.agency.create.addressLine1')}
            value={formData.address1}
            onChange={handleChange(AGENCY_CREATE_FIELDS.ADDRESS1)}
            fullWidth
            sx={inputStyle}
            error={!!getError(AGENCY_CREATE_FIELDS.ADDRESS1)}
            helperText={
              getHelperText(AGENCY_CREATE_FIELDS.ADDRESS1) ||
              LanguageConverterV2('dashboard.agency.create.noPO')
            }
          />

          <TextField
            sx={inputStyle}
            label={LanguageConverterV2('dashboard.agency.create.addressLine2')}
            value={formData.address2}
            onChange={handleChange(AGENCY_CREATE_FIELDS.ADDRESS2)}
            fullWidth
            helperText={getHelperText(AGENCY_CREATE_FIELDS.ADDRESS2)}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'flex-start',
            }}
          >
            <TextField
              required
              label={LanguageConverterV2('dashboard.agency.create.city')}
              value={formData.city}
              onChange={handleChange(AGENCY_CREATE_FIELDS.CITY)}
              sx={{ ...inputStyle, width: '160px' }}
              error={getError(AGENCY_CREATE_FIELDS.CITY)}
              helperText={getHelperText(AGENCY_CREATE_FIELDS.CITY)}
            />

            <FormControl
              error={!!getError(AGENCY_CREATE_FIELDS.STATE)}
              required
              sx={{ ...inputStyle, width: '100px' }}
            >
              <InputLabel id="state-select-label">
                {LanguageConverterV2('dashboard.agency.create.state')}
              </InputLabel>
              <Select
                data-testid="state-select"
                labelId="state-select-label"
                value={formData.state}
                onChange={handleChange(AGENCY_CREATE_FIELDS.STATE)}
                label={LanguageConverterV2('dashboard.agency.create.state')}
              >
                {US_STATES?.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
              {!!getError(AGENCY_CREATE_FIELDS.STATE) && (
                <FormHelperText>{getHelperText(AGENCY_CREATE_FIELDS.STATE)}</FormHelperText>
              )}
            </FormControl>
            <TextField
              required
              label={LanguageConverterV2('dashboard.agency.create.zip.placeholder')}
              value={formData.zip}
              onChange={handleChange(AGENCY_CREATE_FIELDS.ZIP)}
              error={!!getError(AGENCY_CREATE_FIELDS.ZIP)}
              helperText={getHelperText(AGENCY_CREATE_FIELDS.ZIP)}
              sx={{ ...inputStyle, width: '200px' }}
            />
          </Box>
        </Box>
        <IndustriesCard
          selectedIndustries={formData.industries}
          error={errors.industries}
          onChange={(industries) =>
            setFormData((prev) => ({
              ...prev,
              industries,
            }))
          }
          required
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 4,
            pt: 2,
            width: '25%',
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              fontFamily: PRIMARY_FONT[700],
              fontSize: '16px',
              textTransform: 'none',
            }}
          >
            {LanguageConverterV2('dashboard.agencies.addAnAgency')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AgencyCreatePage;
