import React from 'react';
import PropTypes from 'prop-types';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import CopyLinkIcon from '../../../assets/icons/CopyLinkIcon';
import theme from '../../../theme';
import { FEED_BACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { ROLE_ICONS } from '../constant';

const styles = theme?.components?.userCard;

const AdminCard = ({ user, onCopyId, onClick, userRole }) => {
  const NON_CANDIDATE_STATUS_CONFIG = {
    active: {
      displayName: LanguageConverter('userCard.status.active'),
      color: FEED_BACK.GREEN_70,
      getIcon: (role) => ROLE_ICONS[role]?.active || ROLE_ICONS.admin.active,
    },
    deactivated: {
      displayName: LanguageConverter('userCard.status.deactivated'),
      color: FEED_BACK.RED_75,
      getIcon: (role) => ROLE_ICONS[role]?.deactivated || ROLE_ICONS.admin.deactivated,
    },
    unverified: {
      displayName: LanguageConverter('userCard.status.incomplete'),
      color: PRIMARY_PURPLE[90],
      getIcon: (role) => ROLE_ICONS[role]?.unverified || ROLE_ICONS.admin.unverified,
    },
  };

  const currentStatus = NON_CANDIDATE_STATUS_CONFIG[user?.status];
  const statusIcon = currentStatus.getIcon(UserRole.ADMIN);

  return (
    <Card sx={styles.cardStyle}>
      <CardHeader
        sx={styles.cardHeader}
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ fontFamily: PRIMARY_FONT[500] }}>
              {LanguageConverter('userCard.header.status')}
            </Typography>
            {statusIcon}
            <Typography sx={{ fontFamily: PRIMARY_FONT[500], color: currentStatus?.color }}>
              {currentStatus?.displayName}
            </Typography>
          </Box>
        }
      />

      <Divider />
      <CardContent>
        <Box display="flex" alignItems="center" gap={2}>
          <Box>
            <Typography sx={{ ...styles.workerTitle, textTransform: 'capitalize' }}>
              {user?.role}
            </Typography>
            <Typography
              sx={styles.roleName}
              onClick={() => userRole === UserRole.ADMIN && onClick()}
            >
              {user?.name}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              onClick={onCopyId}
              sx={{ cursor: 'pointer', gap: 1 }}
            >
              <Typography sx={styles.copyUser}>
                {LanguageConverter('userCard.subTitle.copyUserId')}
              </Typography>
              <CopyLinkIcon />
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Box display="flex" alignItems="baseline" gap={1}>
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.subTitle.email')}
            </Typography>
            <Typography sx={styles.emailStyle}>{user?.email}</Typography>
          </Box>
        </Box>
      </CardContent>
      {user?.lastLoginDate && (
        <CardActions
          sx={{
            p: 2,
            justifyContent: 'flex-start',
            bottom: 0,
            width: '100%',
            mt: 'auto',
          }}
        >
          <Typography sx={styles.lastLoginHeader}>
            {LanguageConverter('userCard.subTitle.lastLoggedIn')}
          </Typography>
          <Typography sx={styles.lastLoginTime}>{user?.lastLoginDate}</Typography>
        </CardActions>
      )}
    </Card>
  );
};

AdminCard.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lastLoginDate: PropTypes.string,
    corporationName: PropTypes.string,
  }).isRequired,
  onCopyId: PropTypes.func,
  onClick: PropTypes.func,
  userRole: PropTypes.string,
};

export default AdminCard;
