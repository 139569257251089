import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Modal, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { reqVerifyAccount } from '../../../../../../main-deprecated/views/PasswordVerificationOrReset';
import { BLACK } from '../../../../../theme/colorConstants';
import theme from '../../../../../theme/theme';

const styles = theme?.components?.userCard;

const ResendVerificationEmailModal = ({ userID, userEmail, isOpen, onClose }) => {
  const dispatch = useDispatch();

  const handleEmailVerifySubmit = useCallback(
    (userid) => {
      const verifyAccountBody = { userID: userid };
      dispatch(reqVerifyAccount(verifyAccountBody));
    },
    [dispatch]
  );

  return (
    <Box>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="verification-modal-title"
        aria-describedby="verification-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '40%',
            transform: 'translate(-60%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography sx={styles.headerStyle}>
            {LanguageConverterV2('userCard.resendVerificationEmail')}
          </Typography>

          <Typography sx={styles.detailSubTitleStyle}>
            {LanguageConverterV2('userCard.resendVerificationEmailDescription', {
              email: userEmail,
            })}
          </Typography>

          <Typography sx={{ ...styles.contentStyle, color: BLACK[70], marginY: 2 }}>
            {LanguageConverterV2('userCard.resendVerificationEmailDescription.resend')}
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose}>
              {LanguageConverter('buttonText.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleEmailVerifySubmit(userID);
                onClose();
              }}
            >
              {LanguageConverter('buttonText.send')}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

ResendVerificationEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userID: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResendVerificationEmailModal;
