/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { InfoOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import GPSStatusAbsentIcon from '../../../../../main/assets/icons/GPSStatusAbsentIcon';
import GPSStatusPresentIcon from '../../../../../main/assets/icons/GPSStatusPresentIcon';
import KeyboardArrowDownIcon from '../../../../../main/assets/icons/KeyboardArrowDownIcon';
import WaeButton from '../../../../../main/components/Button';
import { SEARCHBAR_BACKGROUND } from '../../../../../main/components/FreeTextSearchFilter';
import { SearchableSelectDropdownFilter } from '../../../../../main/components/SearchableSelectDropdownFilter/SearchableSelectDropdownFilter';
import { BLACK } from '../../../../../main/theme/colorConstants';
import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  epochToTimeInReadableFormat,
} from '../../../../../main/utils';
import { PRIMARY_COLOR } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { TIMEKEEPING_STATUSES } from '../../constants';
import { updateDisputedTimecard } from '../../reducer';
import DateTimeInputMaskedField from '../DateTimeInputMaskedField';

import DisputeConfirmationModal from './DisputeConfirmationModal';

const corporationsAPICallback = {
  httpMethod: 'POST',
  route: 'corporations/read',
  generateBody: (searchTerm) => ({
    filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
  }),
};

const WorkerTimecard = ({
  isChecked,
  weekday,
  jobName,
  shiftStart,
  shiftEnd,
  gps,
  attendance,
  timecard,
  placementId,
  isDisputedTimecardUpdating,
}) => {
  const [clockInEpoch, setClockInEpoch] = useState(timecard.punchIn);
  const [clockOutEpoch, setClockOutEpoch] = useState(timecard.punchOut);

  const dispatch = useDispatch();

  const handleOnSubmit = () => {
    dispatch(updateDisputedTimecard({ placementId }));
  };
  return (
    <Stack gap="16px">
      <Box display="flex" alignItems="center" gap="8px">
        <Checkbox checked={!!isChecked} onChange={() => {}} />
        <Typography sx={{ fontFamily: PRIMARY_FONT[600] }}>
          {epochToDayOfWeek(weekday)} {epochToDateInReadableFormat(weekday)} -
        </Typography>
        <Typography> {jobName} </Typography>
        <Typography>
          {epochToTimeInReadableFormat(shiftStart)} - {epochToTimeInReadableFormat(shiftEnd)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="start" gap="24px" flexWrap="wrap">
        <Box sx={{ width: '220px' }}>
          <DateTimeInputMaskedField
            label="Clock In"
            value={clockInEpoch}
            onChange={setClockInEpoch}
          />
        </Box>
        <Box sx={{ width: '220px' }}>
          <DateTimeInputMaskedField
            label="Clock Out"
            value={clockOutEpoch}
            onChange={setClockOutEpoch}
          />
        </Box>
        <TextField
          id="break-input"
          label="Break (min)"
          defaultValue="30"
          InputProps={{
            readOnly: true,
          }}
          sx={{ width: '100px' }}
        />
        <Stack height="60px" justifyContent="end">
          <Typography sx={{ fontWeight: 'bold' }}>
            {LanguageConverterV2('timekeeping.workerTimecard.dailyHrs')}
          </Typography>
          <Typography sx={{ color: PRIMARY_COLOR[50] }}>
            {timecard?.totalHours} {LanguageConverterV2('timekeeping.workerTimecard.hrs')}
          </Typography>
        </Stack>
        <Stack height="50px" justifyContent="end">
          <WaeButton>{LanguageConverterV2('button.update')}</WaeButton>
        </Stack>
        <Stack height="60px" justifyContent="space-between">
          <Typography sx={{ fontWeight: 'bold' }}>
            {LanguageConverterV2('timekeeping.workerTimecard.gpsStatus')}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" gap="4px">
            {gps ? <GPSStatusPresentIcon /> : <GPSStatusAbsentIcon />}
            <Typography>{gps?.status}</Typography>
          </Box>
        </Stack>
        <Stack height="60px" justifyContent="space-between">
          <Typography sx={{ fontWeight: 'bold' }}>
            {LanguageConverterV2('timekeeping.workerTimecard.attendance')}
          </Typography>
          <Typography> {attendance.status} </Typography>
        </Stack>
        {timecard?.status === TIMEKEEPING_STATUSES.DISPUTED && (
          <Stack height="50px" justifyContent="end">
            <DisputeConfirmationModal
              onSubmit={handleOnSubmit}
              isProcessing={isDisputedTimecardUpdating}
              currentClockIn={timecard?.punchIn}
              currentClockOut={timecard?.punchOut}
              currentBreak={timecard?.break}
              currentTotalTime={timecard?.totalHours}
              disputedClockIn={timecard?.dispute?.clockIn}
              disputedClockOut={timecard?.dispute?.clockOut}
              disputedBreak={timecard?.dispute?.break?.breakTime}
              disputedTotalTime={timecard?.totalHours}
              reason={timecard?.dispute?.disputeReason}
            />
          </Stack>
        )}
      </Box>
      <Box>
        <Accordion sx={{ boxShadow: 'none', justifyItems: 'start' }}>
          <AccordionSummary
            aria-controls="department-content"
            id="department-header"
            expandIcon={<KeyboardArrowDownIcon />}
            sx={{
              flexGrow: 'unset',
              gap: '12px',
              padding: 0,
              fontSize: '16px',
              fontFamily: PRIMARY_FONT[500],
            }}
          >
            {LanguageConverterV2('timekeeping.workerTimecard.departmentAssignment')}
          </AccordionSummary>
          <AccordionDetails
            sx={{ minWidth: '200px', minHeight: '200px', border: `1px ${BLACK[40]} solid` }}
          >
            <Stack gap="8px" sx={{ padding: '8px' }}>
              <Box>
                <Link component="button" variant="body2" onClick={() => {}}>
                  +{LanguageConverterV2('timekeeping.workerTimecard.addDepartment')}
                </Link>
              </Box>
              <Stack>
                <Box display="flex" gap="16px" alignItems="end">
                  <SearchableSelectDropdownFilter
                    disabled={false}
                    placeholder="Department"
                    onValueChange={() => {}}
                    optionsAPICallback={corporationsAPICallback}
                    getOptionLabel={(option) => option.name}
                    background={SEARCHBAR_BACKGROUND.DEFAULT}
                  />
                  <TextField
                    id="time-worked-input"
                    label="Time Worked"
                    defaultValue="4"
                    variant="standard"
                    sx={{ width: '100px' }}
                  />
                  <FormControlLabel
                    value
                    control={<Checkbox checked disabled sx={{ padding: 0 }} />}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                      >
                        <Typography color="black" fontSize="12px">
                          {LanguageConverterV2('timekeeping.workerTimecard.remainingTime')}
                        </Typography>
                        <Tooltip title="Any remaining time for this scheduled shift will be added to this department">
                          <InfoOutlined sx={{ color: PRIMARY_COLOR[50] }} />
                        </Tooltip>
                      </Box>
                    }
                    labelPlacement="top"
                    sx={{ alignItems: 'start' }}
                  />
                </Box>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Stack>
  );
};

WorkerTimecard.propTypes = {
  isChecked: PropTypes.bool,
  weekday: PropTypes.number,
  jobName: PropTypes.string,
  shiftStart: PropTypes.number,
  shiftEnd: PropTypes.number,
  gps: PropTypes.shape({
    status: PropTypes.string,
  }),
  attendance: PropTypes.shape({
    status: PropTypes.string,
  }),
  timecard: PropTypes.shape({
    punchIn: PropTypes.number,
    punchOut: PropTypes.number,
    break: PropTypes.number,
    totalHours: PropTypes.number,
    status: PropTypes.string,
    dispute: PropTypes.shape({
      clockIn: PropTypes.number,
      clockOut: PropTypes.number,
      break: PropTypes.shape({ breakTime: PropTypes.number }),
      disputeReason: PropTypes.string,
    }),
  }),
  placementId: PropTypes.string,
  isDisputedTimecardUpdating: PropTypes.bool,
};

export default WorkerTimecard;
