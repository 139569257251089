import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/CheckBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

// import asyncService from '../../../../../datahub/asyncService';
import theme from '../../../../theme';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

// import { epochToDateInReadableFormat } from '../../../../utils';
// import launchDarklyToggles, { DARKLY_TOGGLE_NAMES } from '../../../../utils/launchDarklyToggles';
// import { epochToDateInReadableFormat } from '../../../../utils';
import LinearCheckBox from './icons/LinearCheckBox';
import LinearCheckBoxOutlineBlank from './icons/LinearCheckBoxOutlineBlank';
import LinearExpandMore from './icons/LinearExpandMore';
import LinearFavoriteBorder from './icons/LinearFavoriteBorder';
import LinearFavoriteIcon from './icons/LinearFavoriteIcon';

const styleForAccordian = {
  backgroundColor: `${theme.palette.background.black}`,
  borderRadius: '25px',
  padding: theme.spacing(1, 0, 0, 0),
  justifyContent: 'space-between',
  margin: 0,
  boxShadow: 0,
  width: '100%',
};
const styleForDetails = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0),
};

const styleForHeart = {
  marginRight: theme.spacing(1),
  color: `${theme.button.palette.fill}`,
};
const styleForShiftLabels = {
  color: `${theme.palette.subtext.main}`,
  fontFamily: PRIMARY_FONT[400],
};

// const styleForLabelTextValue = {
//   color: `${theme.palette.text.secondary}`,
//   fontFamily: PRIMARY_FONT[600],
// };
const styleForIconButtons = {
  color: 'blue',
  fontSize: 30,
  cursor: 'pointer',
};
const styleForFilledIconButtons = {
  ...styleForIconButtons,
};

const styleForUpdateBanner = {
  backgroundImage: `linear-gradient(to right, ${theme.button.palette.primary}, ${theme.button.palette.secondary})`,
  backgroundColor: `${theme.button.palette.primary}`,
  color: '#FFF',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.25, 0),
  cursor: 'pointer',
  borderRadius: '0 0 25px 25px',
};
const styleForUpdateText = {
  fontFamily: PRIMARY_FONT[400],
  fontSize: 20,
  fontWeight: 'bold',
};

const FavoriteDNRAccordion = ({
  favoriteOrDNR,
  favoriteOrDNREmployee,
  // flags,
  handleOnUpdate,
  corporation,
  user,
}) => {
  // const empId = get('waeID', favoriteOrDNREmployee);
  const initFavorite = favoriteOrDNR === 'favorite';
  const initDNR = favoriteOrDNR === 'DNR';
  const [favorite, setFavorite] = useState(initFavorite);
  // const token = get('token', user);
  // const refreshToken = get('refreshToken', user);
  // const [totalShifts, setTotalShifts] = useState(null);
  // const [lastShiftDate, setLastShiftDate] = useState(null);
  const [dnr, setDnr] = useState(initDNR);
  const [dataUpdated, setDataUpdated] = useState(false);
  // const usePlacementsReadV2 = launchDarklyToggles(flags, DARKLY_TOGGLE_NAMES.usePlacementsReadV2);
  // const placementsReadEndpoint = usePlacementsReadV2 ? 'v2/placements/read' : 'placements/read';
  const handleFavoriteClick = () => {
    // waiting for BE API
    setFavorite(!!favorite);
  };

  const handleDNRClick = () => {
    // waiting for BE API
    setDnr(!!dnr);
  };

  useEffect(() => {
    if (favorite !== initFavorite || dnr !== initDNR) {
      setDataUpdated(true);
    } else {
      setDataUpdated(false);
    }
  }, [favorite, dnr, initFavorite, initDNR, setDataUpdated]);

  // useEffect(() => {
  //   asyncService({
  //     httpMethod: 'POST',
  //     route: 'placements/read',
  //     data: {
  //       page: 0,
  //       limit: 1,
  //       filters: [
  //         {
  //           operation: 'equalsID',
  //           field: 'candidate._id',
  //           value: empId,
  //         },
  //         {
  //           operation: 'onOrBefore',
  //           field: 'start',
  //           value: Date.now(),
  //         },
  //         {
  //           operation: 'equals',
  //           field: 'state.status',
  //           value: 'active',
  //         },
  //       ],
  //       sortBy: [
  //         {
  //           field: 'start',
  //           descending: false,
  //         },
  //       ],
  //     },
  //     onSuccess: (data) => {
  //       const last = get(['data', 'documents', '0', 'jobOrder', 'start'], data);
  //       const total = get(['data', 'count'], data);
  //       setTotalShifts(total);
  //       setLastShiftDate((last && epochToDateInReadableFormat(last)) || '-');
  //     },
  //     onError: () => {
  //       // default displayValue
  //       setTotalShifts(0);
  //       setLastShiftDate('-');
  //     },
  //     token,
  //     refreshToken,
  //   });
  // }, [empId, refreshToken, token]);

  const handleUpdateFavoriteDnr = () => {
    const favoriteDnrData = {
      fields: {
        id: get('id', corporation), // corp ID
        DNR: dnr
          ? [
              {
                bhID: get('bhID', favoriteOrDNREmployee),
                reason: '',
                requester: `${user.id}`,
              },
            ]
          : null,
        favorite: favorite
          ? [
              {
                bhID: get('bhID', favoriteOrDNREmployee),
              },
            ]
          : null,
      },
    };

    handleOnUpdate(favoriteDnrData);
  };

  return (
    <Accordion sx={styleForAccordian} square>
      <AccordionSummary
        expandIcon={<LinearExpandMore sx={styleForIconButtons} />}
        aria-controls="panel1a-content"
        sx={{ padding: theme.spacing(0, 2) }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex' }}>
            {favoriteOrDNR === 'favorite' && <FavoriteIcon sx={styleForHeart} />}
            {favoriteOrDNR === 'DNR' && <BlockIcon sx={styleForHeart} />}

            <Typography
              sx={{
                color: theme.components.detailView.typography.locationText.fontColor,
                fontFamily: theme.components.detailView.typography.locationText.fontFamily,
              }}
            >
              {favoriteOrDNREmployee.name}
            </Typography>
          </Box>

          {/* <Box sx={styleForDetails}> */}
          {/*  <Box sx={{ display: 'flex', width: '50%' }}> */}
          {/*    <Typography sx={styleForShiftLabels}>Shifts worked:</Typography> */}
          {/*    <Typography sx={styleForLabelTextValue}> {totalShifts}</Typography> */}
          {/*  </Box> */}
          {/*  <Box sx={{ display: 'flex', width: '50%' }}> */}
          {/*    <Typography sx={styleForShiftLabels}>Last Shift:</Typography> */}
          {/*    <Typography sx={styleForLabelTextValue}>{lastShiftDate}</Typography> */}
          {/*  </Box> */}
          {/* </Box> */}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0, padding: 0 }}>
        <Box sx={{ padding: theme.spacing(0, 2) }}>
          <Box sx={styleForDetails}>
            <Box onClick={handleFavoriteClick} sx={{ width: '50%' }}>
              <Typography sx={styleForShiftLabels}>Favorite</Typography>
              {favorite ? (
                <LinearFavoriteIcon sx={styleForFilledIconButtons} />
              ) : (
                <LinearFavoriteBorder sx={styleForIconButtons} />
              )}
            </Box>

            <Box onClick={handleDNRClick} sx={{ width: '50%' }}>
              <Typography sx={styleForShiftLabels}>DNR</Typography>
              {dnr ? (
                <LinearCheckBox sx={styleForFilledIconButtons} />
              ) : (
                <LinearCheckBoxOutlineBlank sx={styleForIconButtons} />
              )}
            </Box>
            <Box onClick={handleDNRClick} sx={{ width: '30px' }} />
          </Box>
        </Box>
        {dataUpdated && (
          <Box sx={styleForUpdateBanner} onClick={handleUpdateFavoriteDnr}>
            <CheckIcon sx={{ fontSize: 30, marginRight: theme.spacing(3) }} />
            <Typography sx={styleForUpdateText}>Update Employee</Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

FavoriteDNRAccordion.propTypes = {
  favoriteOrDNREmployee: PropTypes.shape({
    name: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  favoriteOrDNR: PropTypes.string,
  flags: PropTypes.shape({}),
  handleOnUpdate: PropTypes.func,
  corporation: PropTypes.shape({}),
};

export default withLDConsumer()(FavoriteDNRAccordion);
