import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';
import { capitalizeFirstLetter } from '../../../UsersHelpers';

import WorkExperienceModal from './WorkExperienceModal';
/* eslint-disable react/no-array-index-key */
const styles = theme?.components?.userDetail;
const WorkExperienceCard = ({ workExperience, industry }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box data-testid="work-experience-card">
      <Typography sx={styles.headerStyle}>
        {LanguageConverter('userDetail.experience.header')}
      </Typography>
      <Card variant="outlined" sx={styles.cardStyle}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={styles.subHeaderStyle}>
              {LanguageConverter('userDetail.experience.industrySelected')}
            </Typography>
            <Typography onClick={handleOpen} sx={styles.underlineStyle}>
              {LanguageConverter('userDetail.experience.seeExperience')}
            </Typography>
          </Box>
          {industry?.map((industryName, index) => (
            <Typography key={index} sx={styles.textStyle} data-testid="industry-name">
              {capitalizeFirstLetter(industryName)}
            </Typography>
          ))}
        </CardContent>
      </Card>
      <WorkExperienceModal
        open={open}
        onClose={handleClose}
        workExperience={workExperience}
        industry={industry}
      />
    </Box>
  );
};

WorkExperienceCard.propTypes = {
  workExperience: PropTypes.shape({}),
  industry: PropTypes.arrayOf(PropTypes.string),
};

export default WorkExperienceCard;
