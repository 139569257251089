import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectUsersList = (state) => get('users', state);

export const selectUsers = createSelector(selectUsersList, (users) => users.users);

export const selectIsLoadingUserUpcomingJobs = createSelector(
  selectUsersList,
  (users) => users.isLoadingSelectUserUpcomingJobs
);

export const selectDNRData = createSelector(selectUsersList, (users) => users.DNRData);
export const selectDNRDataIsProcessing = createSelector(
  selectUsersList,
  (users) => users.isLoadingUpdateDNR
);
export const selectDNAData = createSelector(selectUsersList, (users) => users.DNAData);
export const selectDNADataIsProcessing = createSelector(
  selectUsersList,
  (users) => users.isLoadingUpdateDNA
);

export const selectedUpcomingCount = createSelector(
  selectUsersList,
  (users) => users.selectedUpcomingCount
);
export const selectedCompletedCount = createSelector(
  selectUsersList,
  (users) => users.selectedCompletedCount
);

export const selectedUserCompletedJobs = createSelector(
  selectUsersList,
  (users) => users.selectedUserCompletedJobs
);

export const selectIsLoadingUsers = createSelector(
  selectUsersList,
  (users) => users.isLoadingUsers
);

export const selectRecruiterCorporations = createSelector(
  selectUsersList,
  (users) => users.corporations
);

export const selectTotalRowCount = createSelector(selectUsersList, (users) => users.totalRowCount);

export const selectIsLoadingRecruiterCorporations = createSelector(
  selectUsersList,
  (users) => users.isLoadingUsers
);
export const selectIsUpdateProcessing = createSelector(
  selectUsersList,
  (users) => users.isUpdateProcessing
);

export const selectCertifications = createSelector(
  selectUsersList,
  (users) => users.certifications
);
export const selectCertificationsIsProcessing = createSelector(
  selectUsersList,
  (users) => users.isLoadingCertifications
);

export const selectIsProcessingDeactivateUser = createSelector(
  selectUsersList,
  (users) => users.isProcessingDeactivateUser
);

export const selectSkills = createSelector(selectUsersList, (users) => users.skills);
