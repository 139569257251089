import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import PendingOffers from '../PendingOffers';
import PendingWorkerPlacements from '../PendingWorkerPlacements';
import UserDetailAccordionCards from '../UserDetailAccordionCards/UserDetailAccordionCards';
import WorkerPlacements from '../WorkerPlacements/WorkerPlacements';

import AttendanceStrikeBox from './AttendanceStrikeBox';

const ActiveUserView = ({ selectedUser }) => (
  <Box>
    <WorkerPlacements user={selectedUser} />
    <PendingWorkerPlacements user={selectedUser} />
    <PendingOffers user={selectedUser} />
    <AttendanceStrikeBox selectedUser={selectedUser} />
    <UserDetailAccordionCards selectedUser={selectedUser} />
  </Box>
);

ActiveUserView.propTypes = {
  selectedUser: PropTypes.shape({
    rating: PropTypes.shape({}),
    id: PropTypes.string,
  }),
};

export default ActiveUserView;
