import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import CandidateCard from '../../../../components/UserCard/components/CandidateCard';
import EditableCandidateCard from '../../../../components/UserCard/components/EditableCandidateCard';
import { showSnackbar } from '../../../app';

import AdminDetailCard from './AdminDetailCard';
import EmployerDetailCard from './EmployerDetailCard';
import RecruiterDetailCard from './RecruiterDetailCard';

const UserDetailCardView = ({ selectedUser, isEditable }) => {
  const dispatch = useDispatch();

  const copiedMessage = LanguageConverter('userCard.message.idCopied');

  const handleCopyUserId = () => {
    if (!selectedUser?.id) return;
    navigator.clipboard.writeText(selectedUser.id);
    dispatch(showSnackbar({ message: copiedMessage }));
  };

  const ROLE_CARD_MAP = {
    [UserRole.CANDIDATE]: isEditable ? (
      <EditableCandidateCard user={selectedUser} onCopyId={handleCopyUserId} />
    ) : (
      <CandidateCard user={selectedUser} onCopyId={handleCopyUserId} />
    ),
    [UserRole.RECRUITER]: (
      <RecruiterDetailCard selectedUser={selectedUser} onCopyId={handleCopyUserId} />
    ),
    [UserRole.ADMIN]: <AdminDetailCard selectedUser={selectedUser} onCopyId={handleCopyUserId} />,
    [UserRole.EMPLOYER]: (
      <EmployerDetailCard selectedUser={selectedUser} onCopyId={handleCopyUserId} />
    ),
  };

  const CardComponent = ROLE_CARD_MAP[selectedUser?.role];

  return CardComponent;
};

UserDetailCardView.propTypes = {
  selectedUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  isEditable: PropTypes.bool,
};

export default UserDetailCardView;
