import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';

import NoStrikeIcon from '../../../../../assets/icons/NoStrikeIcon';
import OneStrikeIcon from '../../../../../assets/icons/OneStrikeIcon';
import ThreeStrikesIcon from '../../../../../assets/icons/ThreeStrikesIcon';
import TwoStrikesIcon from '../../../../../assets/icons/TwoStrikesIcon';
import { selectUserListData } from '../../../../../store/selectors/userListSelector';
import theme from '../../../../../theme';
import { FEED_BACK } from '../../../../../theme/colorConstants';
import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  epochToTimeInReadableFormat,
} from '../../../../../utils';
import {
  INFRACTION_DISPLAY,
  MAX_STRIKES,
  SUSPENSION_NAMES,
  TYPE_SUSPENSION,
} from '../../../UsersConstants';
import { getCandidateAttendance } from '../../../usersListReducer';

const styles = theme?.components?.userDetail;

const getStrikeInfo = (strikeNumber) => {
  switch (strikeNumber) {
    case MAX_STRIKES:
      return {
        icon: <ThreeStrikesIcon sx={styles.iconStyle} />,
        subText: LanguageConverterV2('userDetail.attendance.threeStrikes'),
      };
    case 2:
      return {
        icon: <TwoStrikesIcon sx={styles.iconStyle} />,
        subText: LanguageConverterV2('userDetail.attendance.twoStrikes'),
      };
    case 1:
      return {
        icon: <OneStrikeIcon sx={styles.iconStyle} />,
        subText: LanguageConverterV2('userDetail.attendance.oneStrike'),
      };
    default:
      return {
        icon: <NoStrikeIcon sx={styles.iconStyle} />,
        subText: LanguageConverterV2('userDetail.attendance.noStrike'),
      };
  }
};
const AttendanceStrikeBox = ({ selectedUser }) => {
  const dispatch = useDispatch();
  const usersListData = useSelector(selectUserListData);
  const { attendanceData, isLoadingCandidateAttendance } = usersListData;

  const infractions = attendanceData?.attendance;
  const isUserSuspended = attendanceData?.suspendedUntil > Date.now();
  const strikeCount = isUserSuspended ? MAX_STRIKES : selectedUser?.rating?.infractions.length || 0;
  const strikeInfo = getStrikeInfo(strikeCount);

  useEffect(() => {
    if (selectedUser?.id) {
      dispatch(getCandidateAttendance({ candidateId: selectedUser?.id }));
    }
  }, [dispatch, selectedUser?.id]);

  const AttendanceCard = () => {
    let suspensionCount = 0;
    const suspensionsLength = infractions?.filter((item) => item.type === TYPE_SUSPENSION).length;
    const getSuspensionTimes = (num) => SUSPENSION_NAMES[suspensionsLength]?.[num - 1] || null;
    const generateInfraction = (type) => INFRACTION_DISPLAY[type];

    const InfractionCard = ({ reason }) => (
      <Card sx={styles.strikeCardStyle}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography sx={styles.subHeaderStyle}>
              {epochToDayOfWeek(reason?.date)} {epochToDateInReadableFormat(reason?.date)}
            </Typography>
            <Box sx={{ ...styles.subHeaderStyle, color: FEED_BACK.RED_75 }}>
              {LanguageConverterV2(generateInfraction(reason?.type))}
            </Box>
          </Box>
          <Typography sx={styles.textStyle}>
            {reason?.corporation} - {reason?.title}
          </Typography>
          {reason?.cancelNote && (
            <Box>
              <Typography sx={styles.subHeaderStyle}>
                {LanguageConverterV2('userDetail.attendance.cancellationReason')}
              </Typography>
              <Typography sx={styles.textStyle}>{reason?.cancelNote}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    );

    InfractionCard.propTypes = {
      reason: PropTypes.shape({
        date: PropTypes.number,
        type: PropTypes.string,
        corporation: PropTypes.string,
        title: PropTypes.string,
        cancelNote: PropTypes.string,
      }),
    };

    return (
      <Box>
        {infractions?.map((item) => {
          const isSuspension = item.type === TYPE_SUSPENSION;
          if (isSuspension) {
            suspensionCount += 1;
          }
          const suspensionContent = isSuspension ? (
            <Card key={suspensionCount} sx={styles.suspensionCardStyle}>
              <CardContent>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ ...styles.subHeaderStyle, color: FEED_BACK.RED_75 }}>
                    {LanguageConverterV2(getSuspensionTimes(suspensionCount))}
                  </Typography>
                  <Typography sx={styles.subHeaderStyle}>
                    {epochToDateInReadableFormat(item?.startDate)} -{' '}
                    {epochToDateInReadableFormat(item?.endDate)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ) : null;

          const reasonsContent = item?.reasons?.map((reason) => (
            <InfractionCard key={reason.date} reason={reason} />
          ));

          return (
            <Box key={item?.startDate}>
              {suspensionContent}
              {reasonsContent}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      {isLoadingCandidateAttendance ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <Box>
          <Typography sx={{ ...styles.headerStyle, marginLeft: 2 }} data-testid="attendance-header">
            {LanguageConverterV2('userDetail.attendance.header')}
          </Typography>
          <Card variant="outlined" sx={styles.attendanceContainer}>
            <CardContent sx={{ height: '100%', padding: 2 }}>
              <Box display="flex" alignItems="center" gap={1} sx={{ paddingX: 2 }}>
                <Typography sx={styles.headerStyle} data-testid="attendance-strike-box-header">
                  {LanguageConverterV2('userDetail.attendance.strike')} ({strikeCount}/{MAX_STRIKES}
                  )
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {strikeInfo?.icon}
                </Box>
              </Box>
              <Typography sx={{ ...styles.subText, paddingX: 2 }}>{strikeInfo?.subText}</Typography>
              <Typography />
              {isUserSuspended && (
                <Box sx={{ paddingX: 2 }}>
                  <Typography>
                    {LanguageConverterV2('userDetail.attendance.suspensionLift')}
                  </Typography>
                  <Typography sx={styles.subHeaderStyle}>
                    {LanguageConverterV2('userDetail.attendance.suspensionTime.end', {
                      date: epochToDateInReadableFormat(attendanceData?.suspendedUntil),
                      time: epochToTimeInReadableFormat(attendanceData?.suspendedUntil),
                    })}
                  </Typography>
                </Box>
              )}
              {infractions?.length > 0 && <AttendanceCard />}
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

AttendanceStrikeBox.propTypes = {
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    rating: PropTypes.shape({
      infractions: PropTypes.arrayOf(PropTypes.shape({})),
      strikeOuts: PropTypes.arrayOf(PropTypes.shape({})),
      suspendedUntil: PropTypes.number,
    }),
  }),
};

export default AttendanceStrikeBox;
