import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOnboardingProcessing: false };

export const dashboardOnboardingSlice = createSlice({
  name: 'dashboardOnboardingSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    dashboardOnboardingEmail: (state) => {
      state.isOnboardingProcessing = true;
    },
    dashboardOnboardingEmailProcessed: (state) => {
      state.isOnboardingProcessing = false;
    },
    dashboardOnboardingEmailError: (state) => {
      state.isOnboardingProcessing = false;
    },
  },
});

export const {
  dashboardOnboardingEmail,
  dashboardOnboardingEmailProcessed,
  dashboardOnboardingEmailError,
} = dashboardOnboardingSlice.actions;

export const dashboardOnboardingReducer = dashboardOnboardingSlice.reducer;
