import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import WaeButton from '../../components/Button';
import PaginatedDataGrid from '../../components/DataGrid/PaginatedDataGridV2';
import FreeSearchFilter from '../../components/Filters/FreeSearchFilter';
import {
  selectCertsList,
  selectCertsTotalRowCount,
  selectIsCertsLoading,
  selectIsSkillsLoading,
  selectSkillsList,
  selectSkillTotalRowCount,
} from '../../store/selectors/skillsAndCertsSelector';
import theme, { dataGridFiltersHeight, listHeaderHeight, topBarHeight } from '../../theme';
import { useDims } from '../../utils/customHooks';

import AddNewSkillOrCertModal from './subComponents/AddNewSkillOrCertModal';
import DeleteSkillOrCertsModal from './subComponents/DeleteSkillOrCertsModal';
import certColumnData from './certColumnData';
import TYPE_KEYS from './consts';
import { getCerts, getSkills } from './reducer';
import skillColumnData from './skillColumnData';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${topBarHeight})`,
  padding: theme.spacing(2),
}));
const additiveFilters = [
  {
    customFilter: {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Search skills...',
      type: FreeSearchFilter,
    },
  },
];

const certsAdditiveFilters = [
  {
    customFilter: {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Search certs...',
      type: FreeSearchFilter,
    },
  },
];

const SkillsAndCerts = () => {
  const skillsList = useSelector(selectSkillsList);
  const certsList = useSelector(selectCertsList);
  const dispatch = useDispatch();

  const largeScreen = useMediaQuery('(min-width:1910px)');

  const mainContainerRef = useRef();
  const rightButtonRef = useRef();
  const leftTabRef = useRef();

  const mainContainerDims = useDims(mainContainerRef);
  const rightButtonDims = useDims(rightButtonRef);
  const leftTabDims = useDims(leftTabRef);

  // Skills
  const isSkillsLoading = useSelector(selectIsSkillsLoading);
  const skillTotalRowCount = useSelector(selectSkillTotalRowCount);
  const isCertsLoading = useSelector(selectIsCertsLoading);

  const certsTotalRowCount = useSelector(selectCertsTotalRowCount);

  const [skillPageSize, setSkillPageSize] = useState(25);
  const [certPageSize, setCertPageSize] = useState(25);
  const [skillPage, setSkillPage] = useState(0);
  const [certPage, setCertPage] = useState(0);

  const [skillRowCount, setSkillRowCount] = useState(25);
  const [certRowCount, setCertRowCount] = useState(25);
  const [skillsListData, setSkillsListData] = useState(skillsList);
  const [certsListData, setCertsListData] = useState(certsList);

  const [createTypeOpen, setCreateTypeOpen] = useState(null);
  const [deleteTypeOpen, setDeleteTypeOpen] = useState(null);

  const [idToDelete, setIdToDelete] = useState(null);

  const skillListColumnData = useMemo(
    () =>
      skillColumnData((id) => {
        setDeleteTypeOpen(TYPE_KEYS.SKILL);
        setIdToDelete(id);
      }),
    []
  );

  const certListColumnData = useMemo(
    () =>
      certColumnData((id) => {
        setDeleteTypeOpen(TYPE_KEYS.CERT);
        setIdToDelete(id);
      }),
    []
  );

  useEffect(() => {
    setSkillsListData(skillsList);
    setCreateTypeOpen(null);
    setDeleteTypeOpen(null);
  }, [skillsList]);

  const skillsListPaginationQuery = useCallback(
    (params) => {
      dispatch(getSkills(params));
    },
    [dispatch]
  );

  useEffect(() => {
    setCertsListData(certsList);
    setCreateTypeOpen(null);
    setDeleteTypeOpen(null);
  }, [certsList]);

  const certsListPaginationQuery = useCallback(
    ({ ...params }) =>
      dispatch(
        getCerts({
          ...params,
          filters: [
            ...(params?.filters || []),
            {
              field: 'state.status',
              operation: 'equals',
              value: 'active',
            },
          ],
        })
      ),
    [dispatch]
  );

  return (
    <RootGrid>
      <Box>
        <Typography sx={{ fontSize: '24px', fontFamily: `${theme.dataGrid.default.boldFont}` }}>
          {LanguageConverter('certSkills.header')}
        </Typography>

        {/* skills */}
        <Box sx={{ marginBottom: `${theme.spacing(8)}` }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 500,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                // If the container is smaller than the tabs + buttons, move the buttons down
                flexDirection:
                  mainContainerDims.width - rightButtonDims.width - leftTabDims.width < 0
                    ? 'column'
                    : 'row',
                rowGap: theme.spacing(1),
              }}
            />
            <Grid
              container
              sx={{
                width: '100%',
                height: !largeScreen
                  ? `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight} )`
                  : `calc(100% - ${listHeaderHeight})`,
              }}
            >
              <PaginatedDataGrid
                additiveFilters={additiveFilters}
                columnData={skillListColumnData}
                FilterLeftComponent={
                  <WaeButton text="New Skill" onClick={() => setCreateTypeOpen(TYPE_KEYS.SKILL)} />
                }
                isLoading={isSkillsLoading}
                onSelectionModelChange={() => {}}
                page={skillPage}
                pageSize={skillPageSize}
                paginatedData={skillsListData}
                pagination
                paginationQuery={skillsListPaginationQuery}
                setPage={setSkillPage}
                setPageSize={setSkillPageSize}
                setRowCount={setSkillRowCount}
                sx={{
                  border: 0,
                  width: '100%',
                  paddingLeft: 1,
                }}
                totalRowCount={skillTotalRowCount}
                rowCount={skillRowCount}
                style={{ marginRight: theme.spacing(2) }}
              />
            </Grid>
          </Box>
        </Box>
        {createTypeOpen && (
          <AddNewSkillOrCertModal type={createTypeOpen} setTypeOpen={setCreateTypeOpen} />
        )}
        {deleteTypeOpen && (
          <DeleteSkillOrCertsModal
            type={deleteTypeOpen}
            setTypeOpen={setDeleteTypeOpen}
            skillOrCertId={idToDelete}
          />
        )}

        <Box sx={{ marginBottom: `${theme.spacing(8)}` }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 500,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                // If the container is smaller than the tabs + buttons, move the buttons down
                flexDirection:
                  mainContainerDims.width - rightButtonDims.width - leftTabDims.width < 0
                    ? 'column'
                    : 'row',
                rowGap: theme.spacing(1),
              }}
            />
            <Grid
              container
              sx={{
                width: '100%',
                height: !largeScreen
                  ? `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`
                  : `calc(100% - ${listHeaderHeight})`,
              }}
            >
              <PaginatedDataGrid
                additiveFilters={certsAdditiveFilters}
                columnData={certListColumnData}
                FilterLeftComponent={
                  <WaeButton text="New Cert" onClick={() => setCreateTypeOpen(TYPE_KEYS.CERT)} />
                }
                isLoading={isCertsLoading}
                onSelectionModelChange={() => {}}
                pageSize={certPageSize}
                page={certPage}
                paginatedData={certsListData}
                pagination
                paginationQuery={certsListPaginationQuery}
                setPageSize={setCertPageSize}
                setPage={setCertPage}
                setRowCount={setCertRowCount}
                sx={{
                  border: 0,
                  width: '100%',
                  paddingLeft: 1,
                }}
                totalRowCount={certsTotalRowCount}
                rowCount={certRowCount}
                style={{ marginRight: theme.spacing(2) }}
              />
            </Grid>
          </Box>
        </Box>
      </Box>
    </RootGrid>
  );
};

export default SkillsAndCerts;
