import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import { contentBodyNameTextStyle, contentTitleTextStyle } from '../UserDetailAccordionCardsStyles';

const DNABodyContent = ({ id, date, reason, requester }) => (
  <Box key={`${id} ${date}`}>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.reason')}
    </Typography>
    <Typography sx={contentBodyNameTextStyle}>{reason}</Typography>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.submittedBy')}
    </Typography>
    <Typography sx={contentBodyNameTextStyle}>{requester}</Typography>
    <Typography sx={contentTitleTextStyle}>
      {LanguageConverter('corporation.accordion.dateEntered')}
    </Typography>
    <Typography sx={{ ...contentBodyNameTextStyle, marginBottom: 0 }}>{date}</Typography>
  </Box>
);

DNABodyContent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  reason: PropTypes.string,
  requester: PropTypes.string,
};

export default DNABodyContent;
