import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const CircleCheckMarkIcon = ({ color = '#1DA83B', size = 93, sx = {} }) => (
  <Box
    sx={{
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: size,
      height: size,
      ...sx,
    }}
  >
    <svg viewBox="-4 0 101 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="90" height="90" rx="45" fill="white" />
      <rect x="1.5" y="1.5" width="90" height="90" rx="45" stroke={color} strokeWidth="3" />
      <path
        d="M39.7674 55.6113C38.9758 56.4643 37.626 56.4639 36.8348 55.6107L29.0942 47.2622C28.3046 46.4107 26.9583 46.4083 26.1658 47.2571L25.2701 48.2163C24.5543 48.9828 24.5522 50.172 25.2653 50.9411L36.8342 63.4183C37.6256 64.2718 38.9759 64.2718 39.7673 63.4183L67.7392 33.2502C68.4504 32.4831 68.4504 31.2976 67.7392 30.5306L66.8587 29.581C66.0676 28.7277 64.7178 28.7274 63.9262 29.5803L39.7674 55.6113Z"
        fill="#1DA83B"
      />
    </svg>
  </Box>
);

CircleCheckMarkIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.shape({}),
};

export default CircleCheckMarkIcon;
