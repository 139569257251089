import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import theme from '../../theme';
import { BLACK } from '../../theme/colorConstants';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const SELECT_DROPDOWN_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SelectDropdownFilter = ({
  disabled,
  options,
  disableClearable,
  isMultiSelectable,
  placeholder,
  onValueChange,
  getOptionLabel,
  startAdornmentIcon,
  initialValue,
  textboxSx,
  testId,
  wrapperSx,
}) => {
  const initialRenderRef = useRef(true);
  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = React.useState(false);

  const styleForFormControl = {
    '& .MuiOutlinedInput-root': {
      background: 'white',
      padding: `${value ? 0 : 2}px 2px !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme?.button?.palette?.primary,
      fontFamily: PRIMARY_FONT['400'],
      opacity: 1,
    },

    '& .MuiInputBase-inputAdornedEnd': isMultiSelectable
      ? { height: '6px', borderRadius: '6px' }
      : {
          background: value && theme?.button?.palette?.primary,
          color: value && 'white',
          height: '6px',
          borderRadius: '6px',
        },

    border: `1px solid ${theme?.searchBoxForFilter?.borderColor?.focus}`,
    borderRadius: '5px',
    justifyContent: 'center',
    height: isMultiSelectable ? 'auto' : '28px',
    minHeight: '28px',
    width: '150px',
  };

  const muiAutoCompleteStyles = {
    '.MuiAutocomplete-tag': {
      backgroundColor: '#F5EEFF',
      height: 23,
      borderRadius: '5px',
      fontFamily: PRIMARY_FONT[400],
      color: BLACK[70],
      fontSize: '16px',
    },
  };

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else if (initialValue === null) {
      setValue(null);
    }
  }, [initialValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (isMultiSelectable) {
      const values = (newValue && Array.isArray(newValue) && newValue.map((v) => v.value)) || [];
      onValueChange(values);
    } else {
      onValueChange(newValue?.value);
    }
  };

  return (
    <FormControl sx={{ ...styleForFormControl, ...wrapperSx }}>
      <Autocomplete
        disabled={disabled}
        multiple={isMultiSelectable}
        onChange={handleChange}
        value={value}
        disableClearable={disableClearable}
        popupIcon={null}
        clearIcon={null}
        ChipProps={{
          deleteIcon: <ClearIcon style={{ cursor: 'pointer', width: '12px', marginLeft: '2px' }} />,
        }}
        sx={muiAutoCompleteStyles}
        noOptionsText="No results found. Try searching for something else."
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
              marginTop: '2px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            data-testid={testId}
            {...params}
            sx={{ height: isMultiSelectable && 'auto', ...textboxSx }}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: startAdornmentIcon && (
                <>
                  <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {(isMultiSelectable ? value?.length > 0 : value) ? (
                    <ClearIcon
                      onClick={() => {
                        if (!disabled) handleChange(null, isMultiSelectable ? [] : null);
                      }}
                      style={{ cursor: 'pointer', width: '12px', marginLeft: '2px' }}
                    />
                  ) : (
                    <AddIcon
                      style={{
                        color: theme?.button?.palette?.primary,
                        opacity: 1,
                        width: '13px',
                        height: '13px',
                        marginLeft: '2px',
                      }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option.name === val.name}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SelectDropdownFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.shape([])]),
  isMultiSelectable: PropTypes.bool,
  onValueChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  startAdornmentIcon: PropTypes.node,
  textboxSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export { SELECT_DROPDOWN_BACKGROUND, SelectDropdownFilter };
