import { createSelector } from 'reselect';

export const selectFavoriteCorporationList = (state) => state?.favoriteCorporation;

export const selectFavoriteCorporations = createSelector(
  selectFavoriteCorporationList,
  (favoriteCorpList) => favoriteCorpList.corpFavoriteList
);

export const selectIsFavoriteCorporationsProcessing = createSelector(
  selectFavoriteCorporationList,
  (favoriteCorpList) => favoriteCorpList.isFavoriteCorpListLoading
);
