import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';

import PaginatedListWrapper from '../../../../../components/PagedFilterList';
import {
  selectIsLoadingPendingOffers,
  selectPendingOffers,
} from '../../../../../store/selectors/userListSelector';
import { getPendingOffers } from '../../../usersListReducer';

import RowCards from './RowCards';

const LIMIT = 5;

const PendingOffers = ({ user }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoadingPendingOffers);
  const pendingOffers = useSelector(selectPendingOffers);

  const [page, setPage] = useState(0);
  useEffect(() => {
    dispatch(
      getPendingOffers({
        filters: [
          {
            value: user?.id,
            field: 'offeredCandidates.candidateId',
            operation: 'equalsID',
          },
        ],
        page,
        limit: LIMIT,
      })
    );
  }, [dispatch, page, user.id]);

  const handleOnPageChange = (newPage) => {
    setPage(newPage);
  };

  const title = LanguageConverter('userDetail.pendingOffers.title');

  return (
    <Box>
      <PaginatedListWrapper
        isLoading={isLoading}
        content={<RowCards jobs={pendingOffers?.documents} />}
        limit={LIMIT}
        title={title}
        currentPageNum={page}
        totalListCount={pendingOffers?.count}
        handleOnPageChange={handleOnPageChange}
      />
    </Box>
  );
};
PendingOffers.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
};
export default PendingOffers;
