import { map } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  corpFavoriteList: [],
  isFavoriteCorpListLoading: false,
};
export const favoriteCorporationListSlice = createSlice({
  name: 'favoriteCorporationListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getFavoriteCorporationList: (state) => {
      state.isFavoriteCorpListLoading = true;
    },
    getFavoriteCorporationsListProcessed: (state, action) => {
      const { documents } = action.payload.data;
      const mappedCorporations = map((corp) => {
        const { _id, id, name, favorite } = corp;
        return {
          id: id || _id,
          name,

          favorite,
        };
      }, documents);
      state.corpFavoriteList = mappedCorporations;
      state.isFavoriteCorpListLoading = false;
    },
    getFavoriteCorporationsListError: (state) => {
      state.isFavoriteCorpListLoading = false;
    },
  },
});

export const favoriteCorporationReducer = favoriteCorporationListSlice.reducer;

export const {
  getFavoriteCorporationList,
  getFavoriteCorporationsListProcessed,
  getFavoriteCorporationsListError,
} = favoriteCorporationListSlice.actions;
