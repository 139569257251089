import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import PagedFilterList from '../../../components/PagedFilterList';
import {
  selectIsJobOrdersLoading,
  selectJobOrders,
} from '../../../store/selectors/dashboardSelector';
import { getTimeZone } from '../../../utils';
import { SEARCH_PARAM_KEYS, SHIFT_OPTION_KEYS } from '../../jobOrders/filterConstants';
import {
  FIVE_AM_IN_MIN,
  NINE_THIRTY_PM_IN_MIN,
  ONE_THIRTY_PM_IN_MIN,
} from '../../jobOrders/jobOrderConstants';
import {
  buildFilterPayloadFromUrl,
  getInitialSelectedShiftOptions,
  getNewShiftFilters,
} from '../../jobOrders/jobOrderHelpers';
import { getJobOrdersForDashboard } from '../dashboardReducer';

import DashboardFilters from './DashboardFilters';
import RowCards from './RowCards';

const shiftOptionsPayload = {
  [SHIFT_OPTION_KEYS.FIVE_AM_TO_ONE_THIRTY_PM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: FIVE_AM_IN_MIN, end: ONE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  [SHIFT_OPTION_KEYS.ONE_THIRTY_PM_TO_NINE_THIRTY_PM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: ONE_THIRTY_PM_IN_MIN, end: NINE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  [SHIFT_OPTION_KEYS.NINE_THIRTY_PM_TO_FIVE_AM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: NINE_THIRTY_PM_IN_MIN, end: FIVE_AM_IN_MIN, zone: getTimeZone() },
  },
};

const LIMIT = 5;

const shiftOptions = Object.keys(shiftOptionsPayload);

const DashboardJobs = () => {
  const previousFiltersRef = useRef();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsJobOrdersLoading);
  const jobOrders = useSelector(selectJobOrders);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterAndSortData, setFilterAndSortData] = useState();

  const handleCorporationChange = useCallback(
    (corporationId) => {
      if (corporationId !== searchParams.get(SEARCH_PARAM_KEYS.CORPORATION)) {
        const newParams = { ...Object.fromEntries(searchParams) };
        if (corporationId) {
          newParams[SEARCH_PARAM_KEYS.CORPORATION] = corporationId;
        } else {
          delete newParams[SEARCH_PARAM_KEYS.CORPORATION];
        }
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleShiftOptionChange = useCallback(
    (selectedShifts) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      const selectedShiftFilters = selectedShifts.map((shift) => shiftOptionsPayload[shift]);
      const parsedShiftTime = getNewShiftFilters(selectedShiftFilters);
      const shiftStart = parsedShiftTime?.[0]?.value?.start;
      const shiftEnd = parsedShiftTime?.[0]?.value?.end;

      if (shiftStart !== undefined && shiftEnd !== undefined) {
        newParams[SEARCH_PARAM_KEYS.SHIFT_START] = shiftStart;
        newParams[SEARCH_PARAM_KEYS.SHIFT_END] = shiftEnd;
      } else {
        delete newParams[SEARCH_PARAM_KEYS.SHIFT_START];
        delete newParams[SEARCH_PARAM_KEYS.SHIFT_END];
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (isLoading) return;

    if (!isEqual(previousFiltersRef.current, filterAndSortData)) {
      previousFiltersRef.current = filterAndSortData;
      const constantFiltersAdded = {
        filters: [
          ...filterAndSortData.filters,
          { field: 'full', operation: 'equals', value: false },
          { field: 'cancelled', operation: 'equals', value: false },
        ],
        sortBy: [{ descending: false, field: 'start' }],
        limit: LIMIT,
        page: filterAndSortData.page,
      };

      dispatch(getJobOrdersForDashboard(constantFiltersAdded));
    }
  }, [dispatch, filterAndSortData, isLoading]);

  useEffect(() => {
    setFilterAndSortData((prev) => {
      const filters = buildFilterPayloadFromUrl(searchParams, prev?.filters || []);
      if (isEqual(prev?.filters, filters)) {
        return prev;
      }

      if (filters.length > 0) {
        return { ...prev, page: 0, filters };
      }
      return { filters: [], page: 0 };
    });
  }, [searchParams]);

  const initialSelectedShifts = getInitialSelectedShiftOptions(
    searchParams.get(SEARCH_PARAM_KEYS.SHIFT_START),
    searchParams.get(SEARCH_PARAM_KEYS.SHIFT_END)
  );
  const handleOnPageChange = (newPage) => {
    setFilterAndSortData((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  return (
    <PagedFilterList
      isLoading={isLoading}
      content={<RowCards jobOrders={jobOrders?.documents} />}
      limit={LIMIT}
      filters={
        <DashboardFilters
          isLoading={isLoading}
          handleCorporationChange={handleCorporationChange}
          initialSelectedCorporation={searchParams.get(SEARCH_PARAM_KEYS.CORPORATION)}
          shiftOptions={shiftOptions}
          handleShiftOptionChange={handleShiftOptionChange}
          initialSelectedShifts={initialSelectedShifts}
        />
      }
      title={LanguageConverter('dashboard.agent.jobsList.title')}
      currentPageNum={filterAndSortData?.page}
      totalListCount={jobOrders?.count}
      handleOnPageChange={handleOnPageChange}
    />
  );
};

export default DashboardJobs;
