import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { PRIMARY_FONT } from '../../theme/fontConstants';
import theme from '../../theme/theme';
import { convertToDollars, isValidNumber } from '../../utils';
import ScoreChart from '../ScoreChart';

import {
  agencyHeaderStyling,
  agencyNameStyling,
  cardHeaderStyles,
  cardStyles,
  dataStyling,
  locationStyling,
  onBoardingStyling,
  titleStyling,
} from './agencyCardStyles';

const AgencyCard = ({ agencyData, onClick }) => (
  <Grid>
    <Card sx={cardStyles} data-testid="agency-card" onClick={onClick}>
      <Box sx={cardHeaderStyles} />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(0.5, 2, 0.5, 2),
        }}
      >
        <Grid container item direction="row" sx={{ justifyContent: 'space-between' }}>
          <Grid
            container
            item
            direction="row"
            sx={{
              alignItems: 'flex-end',
            }}
            xs={6}
          >
            <Typography sx={agencyHeaderStyling}>
              {LanguageConverter('agencyCard.agency')}
            </Typography>
          </Grid>
          <Grid container item xs={6} sx={{ justifyContent: 'flex-end' }}>
            <ScoreChart
              label={LanguageConverter('dashboard.widget.reliability.title')}
              score={agencyData?.averageReliabilityScore}
              description={LanguageConverter('dashboard.widget.reliability.description')}
              displayTooltip={false}
              styles={{
                radius: 45,
                percentageStyle: {
                  fontFamily: PRIMARY_FONT[500],
                  fontSize: `20px`,
                  lineHeight: `58px`,
                  justifySelf: 'center',
                },
                labelStyles: {
                  fontFamily: PRIMARY_FONT[300],
                  fontSize: `14px`,
                  lineHeight: `24px`,
                  justifySelf: 'center',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Typography sx={agencyNameStyling}>{agencyData?.name}</Typography>
        </Grid>
        <Grid container item>
          <Typography sx={onBoardingStyling}>
            {`${LanguageConverter('agencyCard.onboardingID')} ${agencyData?.onboardingID || '--'}`}
          </Typography>
        </Grid>
        <Grid container item sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
          <Grid container item sx={{ justifyContent: 'space-between' }}>
            <Typography sx={titleStyling}>
              {LanguageConverter('agencyCard.activeWorkers')}
            </Typography>
            <Typography sx={dataStyling}>
              {isValidNumber(agencyData?.activeWorkers) ? agencyData?.activeWorkers : '--'}
            </Typography>
          </Grid>
          <Grid container item sx={{ justifyContent: 'space-between' }}>
            <Typography sx={titleStyling}>
              {LanguageConverter('agencyCard.availableRevenue')}
            </Typography>
            <Typography sx={dataStyling}>
              {isValidNumber(agencyData?.availableRevenue)
                ? convertToDollars(agencyData.availableRevenue)
                : '--'}
            </Typography>
          </Grid>
          <Grid container item sx={{ justifyContent: 'space-between' }}>
            <Typography sx={titleStyling}>
              {LanguageConverter('agencyCard.scheduledRevenue')}
            </Typography>
            <Typography sx={dataStyling}>
              {isValidNumber(agencyData.scheduledRevenue)
                ? convertToDollars(agencyData.scheduledRevenue)
                : '--'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row" sx={{ margin: theme.spacing(1, 0, 0, 0) }}>
          <Grid container item direction="column" xs={4}>
            <Typography sx={titleStyling}>{LanguageConverter('agencyCard.openings')}</Typography>
            <Typography sx={{ ...dataStyling, margin: theme.spacing(0, 0, 0, 0) }}>
              {isValidNumber(agencyData?.openings) ? agencyData?.openings : '--'}
            </Typography>
          </Grid>
          <Grid container item direction="column" xs={4}>
            <Typography sx={titleStyling}>{LanguageConverter('agencyCard.placements')}</Typography>
            <Typography sx={{ ...dataStyling, margin: theme.spacing(0, 0, 0, 0) }}>
              {isValidNumber(agencyData?.placements) ? agencyData?.placements : '--'}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={3}
            sx={{ margin: theme.spacing(0, 0, 0, 2.5) }}
          >
            <Typography sx={titleStyling}>{LanguageConverter('agencyCard.fillRate')}</Typography>
            <Typography sx={{ ...dataStyling, margin: theme.spacing(0, 0, 0, 0) }}>
              {`${agencyData?.fillRate}%`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="column" sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
          <Typography sx={titleStyling}>
            {LanguageConverter('agencyCard.primaryLocation')}
          </Typography>
          <Typography sx={locationStyling}>{agencyData?.location || '--'}</Typography>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

AgencyCard.propTypes = {
  onClick: PropTypes.func,
  agencyData: PropTypes.shape({
    activeWorkers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onboardingID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    averageReliabilityScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    availableRevenue: PropTypes.number,
    scheduledRevenue: PropTypes.number,
    openings: PropTypes.number,
    placements: PropTypes.number,
    fillRate: PropTypes.number,
    location: PropTypes.string,
  }),
};

export default AgencyCard;
