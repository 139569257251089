import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import DNRReasons from '@careerstart/wae-common/src/main/constants/DNR-reasons';
import { Box, Grid, Typography } from '@mui/material';

import { updateDNA } from '../../../../../../main-deprecated/views/users/reducer';
import Alert from '../../../../../assets/Alert.svg';
import AddDeleteAccordionListCard from '../../../../../components/AddDeleteAccordionListCard';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';

import DNABodyContent from './AccordionBodyContent/DNABodyContent';
import { reasonAutoComplete } from './FormFieldData/UserDetailAccordionFormFieldData';
import { emptyContentTitleTextStyle } from './UserDetailAccordionCardsStyles';

const dnaReasonOptions = [];

Object.keys(DNRReasons).forEach((key) => {
  dnaReasonOptions.push({ label: DNRReasons[key] });
});

const DNAWorkersAccordionCard = ({ dnaList, isProcessing }) => {
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const dnaContentList = dnaList?.map((content) => ({
    key: content.id,
    content: (
      <DNABodyContent
        id={content.id}
        date={content.date}
        reason={content.reason}
        requester={content.requester}
      />
    ),
    deleteApiRequest: {
      id,
      remove: true,
    },
  }));

  const addContentFormComponentBody = () => (
    <Grid>
      <Box>{buildFormField(reasonAutoComplete({ reasons: dnaReasonOptions }))}</Box>
    </Grid>
  );

  const submitAddDNA = (value) => {
    const submitDNA = {
      id,
      reason: value?.reason.label,
    };
    dispatch(updateDNA(submitDNA));
  };
  const deleteDNA = (apiRequestBody) => {
    dispatch(updateDNA(apiRequestBody));
  };

  const modalContent = {
    title: 'corporation.accordion.deleteDNAModalTitle',
    body: 'corporation.accordion.deleteModalBody',
  };

  const dnaDetailEmptyContentBody = (
    <Box>
      <Box>
        <Typography sx={emptyContentTitleTextStyle}>
          {intl.formatMessage({ id: 'userDetail.DNAAccordion.emptyTitleText' })}
          <ul>
            <li>{intl.formatMessage({ id: 'userDetail.DNRAndDNAAccordion.viewingJobs' })}</li>
            <li>{intl.formatMessage({ id: 'userDetail.DNRAndDNAAccordion.signUpForJobs' })}</li>
            <li>
              {intl.formatMessage({ id: 'userDetail.DNRAndDNAAccordion.gettingInvitedToJobs' })}
            </li>
          </ul>
        </Typography>
      </Box>
    </Box>
  );
  return (
    <AddDeleteAccordionListCard
      addButtonName="corporation.accordion.addDNA"
      addContentFormBody={addContentFormComponentBody()}
      accordionDetailEmptyContentBody={dnaDetailEmptyContentBody}
      accordionDetailContent={dnaContentList}
      handleOnDelete={deleteDNA}
      modalContent={modalContent}
      icon={dnaList?.length > 0 && Alert}
      isAddDeleteEnabled
      accordionSummaryTitle="userDetail.DNA.summaryTitle"
      isProcessing={isProcessing}
      onAddFormSubmit={submitAddDNA}
      wrapDetailContentInBorder
    />
  );
};

DNAWorkersAccordionCard.propTypes = {
  dnaList: PropTypes.arrayOf(PropTypes.shape({})),
  isProcessing: PropTypes.bool,
};
export default DNAWorkersAccordionCard;
