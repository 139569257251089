import theme from '../../../../../theme';

export const contentTitleTextStyle = {
  ...theme.components.userDetailAccordion.companyText.palette,
  ...theme.components.userDetailAccordion.companyText.typography,
  margin: theme.spacing(0, 0, 0.75, 0),
};

export const contentBodyNameTextStyle = {
  ...theme.components.userDetailAccordion.corporation.palette,
  ...theme.components.userDetailAccordion.corporation.typography,
  margin: theme.spacing(0, 0, 2, 0),
};

export const emptyContentTitleTextStyle = {
  ...theme.components.userDetailAccordion.emptyTitleContent.palette,
  ...theme.components.userDetailAccordion.emptyTitleContent.typography,
};

export const emptyContentBodyTextStyle = {
  ...theme.components.userDetailAccordion.emptyBodyContent.palette,
  ...theme.components.userDetailAccordion.emptyBodyContent.typography,
};
