import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import theme from '../../../theme';
import { postRecrutierAgencyUpdate } from '../../../views/usersList/usersListReducer';
import { DropdownSearchBar } from '../../DropdownSearchBar/DropdownSearchBar';

const styles = theme?.components?.userCard;

const AgencySelectionModal = ({ userAgency, open, onClose, userName, userId, userRole }) => {
  const [selectedAgency, setSelectedAgency] = useState(userAgency ?? null);

  const dispatch = useDispatch();
  const onRecruiterAgencyChangeSubmit = () => {
    const changeRecruiterAgencyPayload = {
      agencyId: selectedAgency._id,
      recruiterId: userId,
    };
    dispatch(postRecrutierAgencyUpdate(changeRecruiterAgencyPayload));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography sx={styles.agencyHeaderStyle}>
          {LanguageConverterV2(
            userRole === UserRole.RECRUITER
              ? 'userCard.recruiter.agency.header'
              : 'userCard.candidate.agency.header',
            { userName }
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DropdownSearchBar
          onSubmitApiCallData={{
            httpMethod: 'POST',
            route: 'agency/read',
          }}
          optionDisplayField="name"
          placeholder={LanguageConverter('userCard.agency.placeholder')}
          sx={{}}
          value={selectedAgency}
          fullWidth
          onChange={(event, newValue) => {
            setSelectedAgency(newValue);
          }}
        />
        <Box sx={styles.agencyButtonContainer}>
          <Button variant="outlined" onClick={onClose} sx={styles.backButton}>
            {LanguageConverter('buttonText.goBack')}
          </Button>
          <Button
            variant="contained"
            sx={styles.agencyChangeButton}
            onClick={onRecruiterAgencyChangeSubmit}
          >
            {LanguageConverter('buttonText.changeAgency')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AgencySelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  userAgency: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default AgencySelectionModal;
