import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Card, CardContent, Typography } from '@mui/material';

import DeactivateConfirmationModal from '../../../../../../main-deprecated/views/users/userList/detail/subComponent/DeactivateConfirmationModal';
import WaeButton from '../../../../../components/Button';
import theme from '../../../../../theme';

const styles = theme?.components?.userDetail;
const RemoveAccountCard = ({ userId, user }) => {
  const [isDeactivateUserDialogOpen, setIsDeactivateUserDialogOpen] = useState(false);
  const isDeactivated = user?.status === 'deactivated';

  const handleDeactivateClick = () => {
    if (!isDeactivated) {
      setIsDeactivateUserDialogOpen(true);
    }
  };

  return (
    <Box>
      <Card variant="outlined" sx={styles.cardStyle}>
        <CardContent>
          <Typography sx={styles.subHeaderStyle} data-testid="remove-account-header">
            {LanguageConverter('userDetail.removeAccount.header')}
          </Typography>
          <Typography sx={styles.textStyle}>
            {LanguageConverter('userDetail.removeAccount.body')}
          </Typography>
          <WaeButton
            variant="contained"
            fullWidth
            sx={styles.deactivateButtonStyle}
            onClick={handleDeactivateClick}
            disabled={isDeactivated}
          >
            {LanguageConverter('userDetail.removeAccount.deactivate')}
          </WaeButton>
        </CardContent>
      </Card>
      <DeactivateConfirmationModal
        selectedUserID={userId}
        isDialogOpen={isDeactivateUserDialogOpen}
        setIsDialogOpen={setIsDeactivateUserDialogOpen}
      />
    </Box>
  );
};

RemoveAccountCard.propTypes = {
  userId: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default RemoveAccountCard;
