import React, { useCallback, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Tooltip } from '@mui/material';

import { PRIMARY_FONT } from '../../../theme/fontConstants';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import { TIMEKEEPING_TYPE } from '../constants';

import DailyTimekeeping from './DailyTimekeeping';
import WeeklyTimekeeping from './WeeklyTimekeeping';

const TimekeepingGridView = ({ flags }) => {
  const [tabValue, setTabValue] = useState(TIMEKEEPING_TYPE.WEEKLY);
  const isDailyTabDisabled = !launchDarklyToggles(flags, 'isHackathonTimesheetsViewEnabled');

  const handleTabChange = useCallback(
    (event, newTabValue) => {
      if (newTabValue === TIMEKEEPING_TYPE.DAILY && isDailyTabDisabled) return;
      setTabValue(newTabValue);
    },
    [isDailyTabDisabled]
  );

  return (
    <Box sx={{ padding: '12px' }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange}>
            <Tab
              sx={{ fontFamily: PRIMARY_FONT['700'], fontWeight: 'bold' }}
              value={TIMEKEEPING_TYPE.WEEKLY}
              label={LanguageConverter('timekeeping.weekly')}
              key="Weekly"
            />
            <Tab
              sx={{ fontFamily: PRIMARY_FONT['700'], fontWeight: 'bold' }}
              value={TIMEKEEPING_TYPE.DAILY}
              label={
                <Tooltip title={isDailyTabDisabled && 'Coming soon'}>
                  <span>{LanguageConverter('timekeeping.daily')}</span>
                </Tooltip>
              }
              key="Daily"
              style={{ cursor: isDailyTabDisabled ? 'not-allowed' : 'pointer' }}
            />
          </TabList>
        </Box>
        <TabPanel value={TIMEKEEPING_TYPE.WEEKLY}>
          <WeeklyTimekeeping flags={flags} />
        </TabPanel>

        <TabPanel value={TIMEKEEPING_TYPE.DAILY}>
          <DailyTimekeeping />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default withLDConsumer()(TimekeepingGridView);

TimekeepingGridView.propTypes = {
  flags: PropTypes.shape({}),
};
