import { PRESET_SHIFTS, SHIFT_TYPES } from './constant';

export const startEndObjToArr = (times) =>
  Object.keys(times)
    .filter((key) => times[key] === true)
    .map((key) => parseInt(key, 10));

export const findStartAndEnd = (presetTimes) => {
  if (!Array.isArray(presetTimes) || presetTimes.length === 0) {
    return { start: null, end: null };
  }

  const sortedPresetTimes = [...presetTimes].sort((a, b) => a - b);

  let start = sortedPresetTimes[0];
  let end = sortedPresetTimes[sortedPresetTimes.length - 1];

  for (let i = 0; i < sortedPresetTimes.length - 1; i += 1) {
    if (sortedPresetTimes[i + 1] - sortedPresetTimes[i] > 1) {
      start = sortedPresetTimes[i + 1];
      end = sortedPresetTimes[i];
      break;
    }
  }

  return { start, end };
};

export const getSelectedShiftTimes = (presetTimes) => {
  const matchingShifts = [];
  if (
    presetTimes.start === PRESET_SHIFTS.MORNING.start &&
    presetTimes.end === PRESET_SHIFTS.EVENING.start
  ) {
    matchingShifts.push(PRESET_SHIFTS.MORNING);
  } else if (
    presetTimes.start === PRESET_SHIFTS.MORNING.start &&
    presetTimes.end === PRESET_SHIFTS.NIGHT.start
  ) {
    matchingShifts.push(PRESET_SHIFTS.MORNING, PRESET_SHIFTS.EVENING);
  } else if (
    presetTimes.start === PRESET_SHIFTS.EVENING.start &&
    presetTimes.end === PRESET_SHIFTS.NIGHT.start
  ) {
    matchingShifts.push(PRESET_SHIFTS.EVENING);
  } else if (
    presetTimes.start === PRESET_SHIFTS.EVENING.start &&
    presetTimes.end === PRESET_SHIFTS.MORNING.start
  ) {
    matchingShifts.push(PRESET_SHIFTS.EVENING, PRESET_SHIFTS.NIGHT);
  } else if (
    presetTimes.start === PRESET_SHIFTS.NIGHT.start &&
    presetTimes.end === PRESET_SHIFTS.MORNING.start
  ) {
    matchingShifts.push(PRESET_SHIFTS.NIGHT);
  } else if (
    presetTimes.start === PRESET_SHIFTS.NIGHT.start &&
    presetTimes.end === PRESET_SHIFTS.EVENING.start
  ) {
    matchingShifts.push(PRESET_SHIFTS.MORNING, PRESET_SHIFTS.NIGHT);
  } else if (presetTimes.start === 0 && presetTimes.end === 23) {
    matchingShifts.push(PRESET_SHIFTS.MORNING, PRESET_SHIFTS.EVENING, PRESET_SHIFTS.NIGHT);
  } else {
    return [
      {
        start: presetTimes.start,
        end: presetTimes.end,
        type: SHIFT_TYPES.CUSTOM,
      },
    ];
  }

  return matchingShifts;
};

export const convertPreferredTimesFromApiToUiFormat = (preferredTimes) =>
  getSelectedShiftTimes(findStartAndEnd(startEndObjToArr(preferredTimes)));

export const convertPreferredTimesToCustomShiftRange = (preferredTimes = []) => {
  if (preferredTimes?.length === 0) return {};

  if (preferredTimes?.length === 1) {
    return { customStart: preferredTimes[0].start, customEnd: preferredTimes[0].end };
  }
  if (preferredTimes?.length === 3) {
    return { customStart: PRESET_SHIFTS.MORNING.start, customEnd: PRESET_SHIFTS.NIGHT.end };
  }
  if (preferredTimes?.length === 2) {
    const availableTypes = preferredTimes.map((shift) => shift.type);
    if (
      availableTypes.includes(SHIFT_TYPES.MORNING) &&
      availableTypes.includes(SHIFT_TYPES.EVENING)
    ) {
      return { customStart: PRESET_SHIFTS.MORNING.start, customEnd: PRESET_SHIFTS.EVENING.end };
    }

    if (
      availableTypes.includes(SHIFT_TYPES.MORNING) &&
      availableTypes.includes(SHIFT_TYPES.NIGHT)
    ) {
      return { customStart: PRESET_SHIFTS.NIGHT.start, customEnd: PRESET_SHIFTS.MORNING.end };
    }

    if (
      availableTypes.includes(SHIFT_TYPES.EVENING) &&
      availableTypes.includes(SHIFT_TYPES.NIGHT)
    ) {
      return { customStart: PRESET_SHIFTS.EVENING.start, customEnd: PRESET_SHIFTS.NIGHT.end };
    }
  }
  return {};
};

export const formatPhoneNumber = (phone) => {
  if (!phone) return '';

  const cleanNumber = phone.replace(/\D/g, '');

  if (cleanNumber.length !== 10) return cleanNumber;

  return `${cleanNumber.slice(0, 3)}.${cleanNumber.slice(3, 6)}.${cleanNumber.slice(6)}`;
};

export const formatReliabilityScore = (score) => {
  if (!score && score !== 0) return 'N/A';
  return `${Math.round(100 * score)}%`;
};
