import React from 'react';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { Box } from '@mui/material';

import selectUser from '../../../store/selectors/appSelector';
import { selectUserDetail } from '../../../store/selectors/userListSelector';

import RemoveAccountCard from './components/workerComponents/RemoveAccountCard';
import WAEOnboardingStatusCard from './components/workerComponents/WAEOnboardingStatusCard';
import WorkExperienceCard from './components/workerComponents/WorkExperienceCard';
import WorkPreferencesCard from './components/workerComponents/WorkPreferenceCard';
import UserDetailCardView from './userDetailCards/UserDetailCardView';

const UserDetailLeft = () => {
  const selectedUser = useSelector(selectUserDetail)?.[0];
  const user = useSelector(selectUser);
  const isUserAdmin = user?.role === UserRole.ADMIN;

  const isUserDeactivated = selectedUser?.status === 'deactivated';
  const candidateWorkExperience = selectedUser?.candidate?.experience;
  const preference = {
    distance: selectedUser?.candidate?.distance,
    preferredShiftTimes: selectedUser?.candidate?.preferredShiftTimes,
    minPay: selectedUser?.candidate?.minPay,
    desiredWeeklyHours: selectedUser?.candidate?.desiredWeeklyHours,
  };

  const hasAnyPreferenceValue =
    preference &&
    (preference.desiredWeeklyHours !== undefined ||
      preference.distance !== undefined ||
      preference.minPay !== undefined ||
      preference.preferredShiftTimes !== undefined);

  const onboardingDoc = selectedUser?.candidate?.onboardingDoc;
  const eVerify = selectedUser?.candidate?.eVerify;
  const selectedUserId = selectedUser?.id;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {selectedUser && <UserDetailCardView selectedUser={selectedUser} isEditable={isUserAdmin} />}
      {selectedUser?.role === UserRole.CANDIDATE && (
        <Box>
          {user?.role === UserRole.ADMIN && (
            <WAEOnboardingStatusCard
              onboardingDoc={onboardingDoc}
              eVerify={eVerify}
              selectedUserId={selectedUserId}
            />
          )}
          {candidateWorkExperience && (
            <WorkExperienceCard
              workExperience={candidateWorkExperience}
              industry={selectedUser?.candidate?.industry}
            />
          )}
          {hasAnyPreferenceValue && <WorkPreferencesCard preference={preference} />}
        </Box>
      )}
      {user?.role === UserRole.ADMIN && !isUserDeactivated && (
        <RemoveAccountCard userId={selectedUser?.id} />
      )}
    </Box>
  );
};

export default UserDetailLeft;
