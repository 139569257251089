import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import Zendesk from 'react-zendesk';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import AppTraining from '../../../main-deprecated/components/AppTraining';
import selectUser, {
  selectIsProcessingAppTraining,
} from '../../../main-deprecated/store/selectors/appSelector';
import launchDarklyToggles from '../../../main-deprecated/utils/launchDarklyToggles';
import { history } from '../../../main-deprecated/utils/navigator';
import { updateUserData } from '../../../main-deprecated/views/app';
import MobileDirect from '../../../main-deprecated/views/Candidate/mobileDirect';
import Login from '../../../main-deprecated/views/login';
import MaintenancePage from '../../../main-deprecated/views/maintenance';
import Snackbar from '../../components/Snackbar';
import { getItemFromLocalStorage, LOCAL_STORAGE_KEYS } from '../../store/utils';
import { setLanguage } from '../../utils/internationalization';

import { generateRoutesJSX } from './RouteBuilding/generateRouteHelpers';
import Navigation from './Navigation';

const AppRouter = ({ flags }) => {
  const [dictData, setDictData] = useState({});
  const user = useSelector(selectUser);
  const isProcessingAppTraining = useSelector(selectIsProcessingAppTraining);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const userData = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userDataKey);

      if (userData) {
        dispatch(updateUserData(userData));
        // TODO: (TICKET PENDING) Longer term, we'll want just the token and we'll do a lookup to get the
        // rest of the user data if it is a valid token. That way, either the token is invalid and
        // user is forced to reauth, or the token is valid and we get fresh data for the user.
      }
    }
  }, [dispatch, user]);

  const maintenance = launchDarklyToggles(flags, 'isMaintenanceModeEnabled');
  const token = get('token', user);
  const isEmployee = user?.role === UserRole.CANDIDATE;
  const { REACT_APP_EMPLOYER_ZENDESK_KEY: EMPLOYER_ZENDESK_KEY } = process.env;

  // Set the language in the internationalization file
  useEffect(() => {
    setLanguage(setDictData);
  }, []);

  if (Object.keys(dictData).length !== 0) {
    return (
      <IntlProvider messages={dictData} locale="en" defaultLocale="en">
        <HistoryRouter history={history}>
          <Snackbar />
          {token && <AppTraining user={user} loading={isProcessingAppTraining} />}
          <Zendesk zendeskKey={EMPLOYER_ZENDESK_KEY} />
          {maintenance ? (
            <MaintenancePage />
          ) : (
            <Routes>
              {isEmployee && <Route path="*" element={<MobileDirect />} />}
              <Route element={isEmployee || !user?.role ? null : <Navigation role={user?.role} />}>
                {generateRoutesJSX(user?.role, flags)}
                <Route path="*" element={<Login />} />
              </Route>
            </Routes>
          )}
        </HistoryRouter>
      </IntlProvider>
    );
  }
  return null;
};

AppRouter.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(AppRouter);
