import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import FreeTextSearchFilter, {
  SEARCHBAR_BACKGROUND,
} from '../../../components/FreeTextSearchFilter';

const AgencyFilters = ({ isLoading, handleOnNameSearchChange, initialValue }) => (
  <FreeTextSearchFilter
    disabled={isLoading}
    placeholder={LanguageConverter('dashboard.agencies.agencyName')}
    onValueChange={handleOnNameSearchChange}
    field="name"
    operation="icontains"
    background={SEARCHBAR_BACKGROUND.DEFAULT}
    initialValue={initialValue}
  />
);

AgencyFilters.propTypes = {
  isLoading: PropTypes.bool,
  handleOnNameSearchChange: PropTypes.func,
  initialValue: PropTypes.shape({}),
};
export default AgencyFilters;
