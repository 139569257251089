import theme from '../../theme';

const muiAccordionSummaryStyle = {
  borderRadius: '16px',
  border: '1px solid #CCCCD6',
  padding: theme.spacing(0.5, 0, 0.5, 0),
};

export const accordionCardStyles = {
  width: '294px',

  boxShadow: '0px 0px 0px 0px white',
  '.MuiAccordionDetails-root': {
    borderRadius: '16px',
    border: '1px solid #CCCCD6',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(3, 2, 3, 2),
    maxHeight: '413px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme?.components?.scrollBar?.altThumbColorGray,
      borderRadius: '50px',
      height: '50px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme?.components?.scrollBar?.thumbHover,
    },
  },
  '.MuiAccordionSummary-root': muiAccordionSummaryStyle,
  '.MuiAccordionSummary-root.Mui-expanded': muiAccordionSummaryStyle,
};

export const titleStyles = {
  ...theme.components.workerAccordion.title.palette,
  ...theme.components.workerAccordion.title.typography,
  margin: theme.spacing(0, 0, 0, 1),
};

export const countStyle = {
  ...theme.components.workerAccordion.count.palette,
  ...theme.components.workerAccordion.count.typography,
  margin: theme.spacing(3, 0, 4, 0),
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'center',
};

export const accordionUpDownIconStyle = {
  height: 12,
  width: 12,
  margin: theme.spacing(0.5, 3.5, 0, 0),
};
export const accordionTrashIconStyle = {
  height: 14.25,
  width: 13,
  margin: theme.spacing(0.5, 3.5, 0, 0),
};

export const addContentBoxStyles = {
  height: 200,
};

export const modalStyle = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  border: '1px solid #CCCCD6',
  boxShadow: 24,
  p: 4,
  height: 254,
  width: 386,
};

export const addPopupButtonStyle = {
  ...theme.components.workerAccordion.addPopupButton.palette,
  ...theme.components.workerAccordion.addPopupButton.typography,
  margin: theme.spacing(0, 0, 2, 0),
  textDecoration: 'underline',
};

export const bodyContentAndAndDeleteStyle = {
  borderRadius: '16px',
  border: '1px solid #CCCCD6',
  padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
  margin: theme.spacing(0, 0, 2, 0),
};

export const bodyContentAndAndDeleteStyleWithoutBorder = {
  padding: theme.spacing(0, 1.5, 0, 1.5),
  margin: theme.spacing(0, 0, 1.25, 0),
};

export const modalTitleStyle = {
  ...theme.components.workerAccordion.modalTitle.palette,
  ...theme.components.workerAccordion.modalTitle.typography,
  margin: theme.spacing(0, 0, 1, 0),
};
export const modalBodyStyle = {
  ...theme.components.workerAccordion.modalBody.palette,
  ...theme.components.workerAccordion.modalBody.typography,
  margin: theme.spacing(0, 0, 3, 0),
};
