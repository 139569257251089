import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Grid, Typography } from '@mui/material';

import { BLACK, PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import {
  epochToDateInReadableFormat,
  epochToTimeInReadableFormat,
  getHoursDifferenceFromNow,
} from '../../../utils';

const Title = ({ text }) => (
  <Typography
    sx={{
      fontSize: '12px',
      color: BLACK[70],
      textTransform: 'uppercase',
      fontFamily: PRIMARY_FONT[300],
    }}
  >
    {text}
  </Typography>
);

Title.propTypes = {
  text: PropTypes.string,
};

const MIN_NUM_HOURS = 0;
const MAX_NUM_HOURS = 24;

const RowCards = ({ jobOrders = [] }) => {
  const intl = useIntl();
  return jobOrders.length > 0 ? (
    jobOrders?.map((jobOrder) => (
      <Grid
        container
        sx={{
          border: `1px solid ${BLACK[40]}`,
          borderRadius: '5px',
        }}
        key={jobOrder?._id}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            height: '43px',
            backgroundColor: BLACK[10],
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[400],
              paddingLeft: '12px',
            }}
          >
            {epochToDateInReadableFormat(jobOrder.start)}
          </Typography>
          {getHoursDifferenceFromNow(jobOrder.start) >= MIN_NUM_HOURS &&
            getHoursDifferenceFromNow(jobOrder.start) <= MAX_NUM_HOURS && (
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: PRIMARY_FONT[400],
                  color: '#B23E3E',
                  paddingLeft: '12px',
                }}
              >
                {intl.formatMessage(
                  { id: 'dashboard.agent.jobsList.jobStartsInHours' },
                  {
                    hours: getHoursDifferenceFromNow(jobOrder.start),
                  }
                )}
              </Typography>
            )}
        </Grid>
        <Grid container direction="row" flexWrap="nowrap" sx={{ padding: '12px' }}>
          <Grid container alignItems="center" direction="row" flexWrap="nowrap">
            {getHoursDifferenceFromNow(jobOrder.start) >= MIN_NUM_HOURS &&
              getHoursDifferenceFromNow(jobOrder.start) <= MAX_NUM_HOURS && (
                <ErrorOutlineIcon sx={{ color: '#B23E3E', marginRight: '8px' }} />
              )}
            <Grid container direction="column">
              <Title text={LanguageConverter('dashboard.agent.jobsList.company')} />
              <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                {jobOrder?.corporation?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('dashboard.agent.jobsList.position')} />
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: PRIMARY_FONT[300],
                color: PRIMARY_COLOR[70],
                textDecoration: 'underline',
              }}
            >
              {jobOrder.name}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('dashboard.agent.jobsList.times')} />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {`${epochToTimeInReadableFormat(jobOrder.start)}-${epochToTimeInReadableFormat(
                jobOrder.end
              )}`}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('dashboard.agent.jobsList.totalOpenings')} />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {jobOrder.numOpenings + jobOrder.numOverstaff}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('dashboard.agent.jobsList.availableOpenings')} />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[700] }}>
              {jobOrder.numOpenings + jobOrder.numOverstaff - jobOrder.placementsCount}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))
  ) : (
    <Grid
      container
      justifyContent="center"
      sx={{
        border: `1px solid ${BLACK[40]}`,
        borderRadius: '5px',
      }}
    >
      <Typography sx={{ padding: '36px', fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}>
        {LanguageConverter('dashboard.agent.jobsList.noJobs')}
      </Typography>
    </Grid>
  );
};

RowCards.propTypes = {
  jobOrders: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RowCards;
