import * as React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Grid, IconButton, Skeleton, Typography } from '@mui/material';

import { PRIMARY_FONT } from '../../theme/fontConstants';

const PagedFilterList = ({
  isLoading,
  content,
  limit,
  filters,
  title,
  currentPageNum = 0,
  totalListCount = 0,
  handleOnPageChange,
  styles,
}) => {
  const currentPagesStart = totalListCount ? limit * currentPageNum + 1 : 0;
  const currentPagesEnd =
    currentPagesStart + limit - 1 < totalListCount ? currentPagesStart + limit - 1 : totalListCount;
  return (
    <Box sx={{ width: '100%', borderRadius: '8px', gap: '8px' }} data-testid="paged-list">
      <>
        <Grid container justifyContent="space-between" direction="row" flexWrap="nowrap">
          <Typography sx={{ fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}>{title}</Typography>
          <Grid item>
            <Grid container sx={{ gap: '8px' }}>
              {filters}
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', borderRadius: '8px', gap: '8px', ...styles }}>
          {isLoading && (
            <Skeleton
              animation="wave"
              height="640px"
              variant="rectangular"
              sx={{ borderRadius: '8px' }}
            />
          )}
          <>
            {!isLoading && (
              <Grid container gap="12px" sx={{ paddingBlock: '18px' }}>
                {content}
              </Grid>
            )}
            <Grid container justifyContent="flex-end" alignItems="center" width="100%" gap={1}>
              <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                {`${currentPagesStart} - ${currentPagesEnd} of ${totalListCount}`}
              </Typography>
              <IconButton
                disabled={currentPageNum === 0 || totalListCount === 0}
                onClick={() => handleOnPageChange(currentPageNum - 1)}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                disabled={
                  !totalListCount || Math.ceil((totalListCount || 0) / limit) === currentPageNum + 1
                }
                onClick={() => handleOnPageChange(currentPageNum + 1)}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Grid>
          </>
        </Box>
      </>
    </Box>
  );
};
PagedFilterList.propTypes = {
  isLoading: PropTypes.bool,
  content: PropTypes.element,
  limit: PropTypes.number,
  filters: PropTypes.element,
  title: PropTypes.string,
  currentPageNum: PropTypes.number,
  totalListCount: PropTypes.number,
  handleOnPageChange: PropTypes.func,
  styles: PropTypes.PropTypes.shape({}),
};
export default PagedFilterList;
