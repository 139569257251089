import React, { useCallback, useEffect } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import EmployerCreateSchema from '@careerstart/wae-common/schema/users/create-employer/createEmployer.post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Drawer from '@mui/material/Drawer';

import { validateSchema } from '../../../../components/Form/validations';
import WorkFlow from '../../../../components/WorkFlow';
import { selectCorporations } from '../../../../store/selectors/corporationListSelectors';
import {
  selectIsProcessingPostUser,
  selectPostUserError,
} from '../../../../store/selectors/createUserSelectors';
import { getCorporationsList } from '../../../corporations/corporationList';
import { clearPostUserError, postBullhornEmployers, postEmployer, postUser } from '../../reducer';

import {
  formFieldDataForAdmin,
  formFieldDataForBullHorn,
  formFieldDataForCandidates,
  formFieldDataForEmployer,
  formFieldDataForRecruiter,
  readOnlyValues,
} from './FormFieldData/formFieldData';

const BULLHORN_IMPORT_STRATEGY = 'bullhorn-import';

const UserListDrawer = ({ isBhDecouple, isOpen, onClose, userCreateStrategy }) => {
  const corporations = useSelector(selectCorporations);
  const isProcessingPostUser = useSelector(selectIsProcessingPostUser);
  const postUserError = useSelector(selectPostUserError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCorporationsList());
  }, [dispatch]);

  const formFieldDataByRole = () => {
    switch (userCreateStrategy) {
      case UserRole.ADMIN:
        return formFieldDataForAdmin(corporations);
      case UserRole.RECRUITER:
        return formFieldDataForRecruiter(corporations);
      case UserRole.EMPLOYER:
        return formFieldDataForEmployer(corporations);
      case UserRole.CANDIDATE:
        return formFieldDataForCandidates;
      case BULLHORN_IMPORT_STRATEGY:
        return formFieldDataForBullHorn;
      default:
        return [];
    }
  };

  const handleOnClose = useCallback(() => {
    onClose();
    dispatch(clearPostUserError());
  }, [dispatch, onClose]);

  const formValuesForAdmin = (values) => {
    const name = get(['name'], values);
    const email = get(['email'], values);
    return {
      fields: {
        role: userCreateStrategy,
        name,
        email,
      },
    };
  };

  const formValuesForRecruiter = (values) => ({
    fields: {
      role: userCreateStrategy,
      name: values?.name,
      email: values?.email,
      recruiter: {
        agency: values?.agency?._id,
      },
    },
  });

  const legacyFormValuesForEmployer = (values) => {
    const name = values?.name;
    const email = values?.email;
    const corporationID = values?.corporations?._id;
    return {
      fields: {
        role: userCreateStrategy,
        name,
        email,
        employer: {
          corporation: corporationID,
        },
      },
    };
  };

  const formValuesForEmployer = (values) => {
    const name = values?.name;
    const email = values?.email;
    const corporationID = values?.corporations?._id;
    return {
      fields: {
        name,
        email,
        corporation: corporationID,
      },
    };
  };

  const formValuesForCandidate = (values) => {
    const name = get(['name'], values);
    const email = get(['email'], values);
    const phoneNumber = get('phoneNumber', values);
    const industry = get('industry', values);
    const distance = parseInt(get(['distance', 'name'], values), 10);
    return {
      fields: {
        role: userCreateStrategy,
        name,
        email,
        candidate: {
          phoneNumber,
          industry,
          distance,
        },
      },
    };
  };

  const formValuesForBullhorn = (values) => {
    const bullhornID = get(['bullhornID'], values);

    return {
      fields: {
        bullhornID,
      },
    };
  };
  const handleSubmitValues = (values) => {
    switch (userCreateStrategy) {
      case UserRole.ADMIN:
        return formValuesForAdmin(values);
      case UserRole.RECRUITER:
        return formValuesForRecruiter(values);
      case UserRole.EMPLOYER:
        return isBhDecouple ? formValuesForEmployer(values) : legacyFormValuesForEmployer(values);
      case UserRole.CANDIDATE:
        return formValuesForCandidate(values);
      case BULLHORN_IMPORT_STRATEGY:
        return formValuesForBullhorn(values);
      default:
        throw Error(`Submitted values are incorrect`);
    }
  };

  const attemptToHandleAPICall = (values) => {
    const payload = handleSubmitValues(values);

    // if not decoupled, use old creates
    if (!isBhDecouple) {
      dispatch(
        userCreateStrategy === BULLHORN_IMPORT_STRATEGY
          ? postBullhornEmployers(payload)
          : postUser(payload)
      );
      return true;
    }

    // if is decoupled and making employer, use createEmployer action
    if (userCreateStrategy === UserRole.EMPLOYER) {
      const validtation = validateSchema(payload, EmployerCreateSchema);
      if (validtation?.FORM_ERROR) {
        return false;
      }
      dispatch(postEmployer(payload));
      return true;
    }
    // if decoupled and not making employer, use generic post user
    dispatch(postUser(payload));
    return true;
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          width: '576px',
        },
      }}
    >
      <WorkFlow
        confirmationPageButtonText={LanguageConverter('button.return.users')}
        formSpec={formFieldDataByRole()}
        onClose={handleOnClose}
        readOnlyValues={readOnlyValues}
        attemptToHandleAPICall={attemptToHandleAPICall}
        isApiCallProcessing={isProcessingPostUser}
        processingMessage="message.generic.processing"
        successMessage="success.user.create"
        errorMessage={postUserError}
      />
    </Drawer>
  );
};

UserListDrawer.propTypes = {
  isBhDecouple: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userCreateStrategy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default UserListDrawer;
