import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import FormTextField from '../../../components/Form/FormTextField/FormTextField';
import { composeValidators, isAnObject, isValidEmail } from '../../../components/Form/validations';

const onboardingFormTextField = (value) => ({
  ComponentType: FormTextField,
  name: 'email',
  testId: 'email',
  charLimit: 100,
  placeholder: LanguageConverter('dashboard.onboarding.enterEmailAddress'),
  validate: composeValidators(isAnObject(value), isValidEmail),
});

export default onboardingFormTextField;
