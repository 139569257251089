import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { FormControlLabel, RadioGroup, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import theme from '../../../../theme';
import { epochToDateInReadableFormat } from '../../../../utils';
import JOB_TYPE from '../constants';

const titleStyle = {
  fontFamily: theme.components.detailLeft.header.typography.fontFamily,
  fontSize: '20px',
  marginTop: '5px',
  marginBottom: '1px',
};

const boldText = {
  fontFamily: theme.components.detailLeft.header.typography.fontFamily,
  fontSize: '16px',
};
const subTitleStyle = {
  fontFamily: theme.components.detailLeft.title.typography.fontFamily,
  fontSize: theme.components.detailLeft.title.typography.fontSize,
  marginTop: '3px',
};

const subText = {
  fontFamily: theme.components.detailLeft.subText.typography.fontFamily,
  fontSize: theme.components.detailLeft.subText.typography.fontSize,
};

const buttonStyle = {
  width: '100%',
  marginTop: 1,
  fontFamily: theme.components.detailLeft.button.typography.fontFamily,
};

const isInvalidInputValue = (value) => value === null || value === undefined || value < 0;

const OverstaffOpenings = ({
  jobStart,
  extGroupStart,
  extGroupEnd,
  capitalizedDays,
  overstaffInitialValue,
  extGroupOverstaffInitialValue,
  handleUpdateValue,
}) => {
  const [newSingleOverstaffValue, setNewSingleOverstaffValue] = useState();
  const [newGroupOverstaffValue, setNewGroupOverstaffValue] = useState();
  const [overStaffOption, setOverstaffOption] = useState(JOB_TYPE.THIS_SHIFT);
  return (
    <Box data-testid="overstaffOpeningsBox">
      <Typography sx={{ ...titleStyle, marginTop: 3 }}>
        {LanguageConverter('jobDetails.changeOverstaffSettings')}
      </Typography>
      <Typography sx={subTitleStyle}>
        {extGroupStart
          ? LanguageConverter('jobDetails.changeOptions.overStaff')
          : LanguageConverter('jobDetails.changeShift')}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="options"
          name="options"
          value={overStaffOption}
          onChange={(e) => setOverstaffOption(e.target.value)}
          orientation="vertical"
        >
          <FormControlLabel
            value={JOB_TYPE.THIS_SHIFT}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: 1,
                  borderColor: 'grey.300',
                  borderRadius: 1,
                  color:
                    overStaffOption === JOB_TYPE.THIS_SHIFT ? 'text.primary' : 'text.secondary',
                }}
              >
                <Box sx={boldText}>{LanguageConverter('jobDetails.thisShift')}</Box>
                <Box>{epochToDateInReadableFormat(jobStart)}</Box>
                {overStaffOption === JOB_TYPE.THIS_SHIFT && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '60%',
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ border: 'none', padding: '8px 8px 8px 0' }}>
                            <Box>
                              <Typography sx={{ ...subText, textTransform: 'uppercase' }}>
                                {LanguageConverter('jobDetails.currentOverstaffSettings')}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ border: 'none', width: '16px', padding: '8px' }} />
                          <TableCell sx={{ border: 'none', padding: '8px 0' }}>
                            <Box>
                              <Typography sx={{ ...subText, textTransform: 'uppercase' }}>
                                {LanguageConverter('jobDetails.newOverstaffSettings')}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ border: 'none', padding: '8px 8px 8px 0' }}>
                            <TextField
                              sx={{ width: '60px' }}
                              type="number"
                              variant="outlined"
                              size="small"
                              value={overstaffInitialValue}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ border: 'none', width: '16px', padding: '8px' }} />
                          <TableCell sx={{ border: 'none', padding: '8px 0' }}>
                            <TextField
                              type="number"
                              sx={{ width: '60px' }}
                              variant="outlined"
                              size="small"
                              value={newSingleOverstaffValue}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(event) => {
                                setNewSingleOverstaffValue(event.target.value);
                              }}
                              error={newSingleOverstaffValue < 0}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </Box>
            }
          />
          {extGroupStart ? (
            <FormControlLabel
              value={JOB_TYPE.ENTIRE_SERIES}
              control={<Radio />}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: 1,
                    borderColor: 'grey.300',
                    borderRadius: 1,
                    color:
                      overStaffOption === JOB_TYPE.ENTIRE_SERIES
                        ? 'text.primary'
                        : 'text.secondary',
                  }}
                >
                  <Box sx={boldText}>{LanguageConverter('jobDetails.series')}</Box>
                  <Box sx={subTitleStyle}>
                    {epochToDateInReadableFormat(extGroupStart)}-
                    {extGroupEnd
                      ? epochToDateInReadableFormat(extGroupEnd)
                      : LanguageConverter('jobDetails.ongoing')}
                  </Box>
                  <Box sx={subText}>{capitalizedDays?.join(', ')}</Box>
                  {overStaffOption !== JOB_TYPE.THIS_SHIFT && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '60%',
                      }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ border: 'none', padding: '8px 8px 8px 0' }}>
                              <Box>
                                <Typography sx={{ ...subText, textTransform: 'uppercase' }}>
                                  {LanguageConverter('jobDetails.currentOverstaffSettings')}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ border: 'none', width: '16px', padding: '8px' }} />
                            <TableCell sx={{ border: 'none', padding: '8px 0' }}>
                              <Box>
                                <Typography sx={{ ...subText, textTransform: 'uppercase' }}>
                                  {LanguageConverter('jobDetails.newOverstaffSettings')}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ border: 'none', padding: '8px 8px 8px 0' }}>
                              <TextField
                                sx={{ width: '60px' }}
                                type="number"
                                variant="outlined"
                                size="small"
                                value={extGroupOverstaffInitialValue}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ border: 'none', width: '16px', padding: '8px' }} />
                            <TableCell sx={{ border: 'none', padding: '8px 0' }}>
                              <TextField
                                type="number"
                                sx={{ width: '60px' }}
                                variant="outlined"
                                size="small"
                                value={newGroupOverstaffValue}
                                onChange={(event) => {
                                  setNewGroupOverstaffValue(event.target.value);
                                }}
                                error={newGroupOverstaffValue < 0}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  )}
                </Box>
              }
            />
          ) : null}
        </RadioGroup>
      </FormControl>
      <Box>
        <Button
          disabled={isInvalidInputValue(
            overStaffOption === JOB_TYPE.THIS_SHIFT
              ? newSingleOverstaffValue
              : newGroupOverstaffValue
          )}
          onClick={() => {
            handleUpdateValue({
              isThisShift: overStaffOption === JOB_TYPE.THIS_SHIFT,
              field: 'numOverstaff',
              singleValue: newSingleOverstaffValue,
              groupValue: newGroupOverstaffValue,
            });
          }}
          variant="outlined"
          sx={buttonStyle}
        >
          {LanguageConverter('button.overstaff.update')}
        </Button>
      </Box>
    </Box>
  );
};

export default OverstaffOpenings;

OverstaffOpenings.propTypes = {
  jobStart: PropTypes.number,
  extGroupStart: PropTypes.number,
  extGroupEnd: PropTypes.number,
  capitalizedDays: PropTypes.arrayOf(PropTypes.string),
  extGroupOverstaffInitialValue: PropTypes.number,
  overstaffInitialValue: PropTypes.number,
  handleUpdateValue: PropTypes.func,
};
