import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import AddDeleteAccordionListCard from '../../../../../components/AddDeleteAccordionListCard';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';
import { updateUser } from '../../../usersListReducer';

import { certificationAutoComplete } from './FormFieldData/UserDetailAccordionFormFieldData';
import { emptyContentTitleTextStyle } from './UserDetailAccordionCardsStyles';

const CertificationWorkersAccordionCard = ({ userCertificationList, isProcessing }) => {
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const certificationListIds = userCertificationList?.map((certs) => certs.id);

  const certificationContentList = userCertificationList?.map((content) => ({
    key: content.id,
    content: <Box>{content.name} </Box>,
    deleteApiRequest: {
      id,
      certificationId: content.id,
    },
  }));

  const addContentFormComponentBody = () => (
    <Grid>
      <Box>{buildFormField(certificationAutoComplete({ userCerts: certificationListIds }))}</Box>
    </Grid>
  );

  const submitAddCertification = (value) => {
    const submitCertsReqBody = {
      id,
      fields: {
        candidate: {
          certifications: [...certificationListIds, value.certifications._id],
        },
      },
    };
    dispatch(updateUser(submitCertsReqBody));
  };
  const deleteCertification = (value) => {
    const deleteCertsReqBody = {
      id,
      fields: {
        candidate: {
          certifications: certificationListIds.filter((ids) => ids !== value.certificationId),
        },
      },
    };
    dispatch(updateUser(deleteCertsReqBody));
  };

  const modalContent = {
    title: 'corporation.accordion.deleteCertsModalTitle',
    body: 'corporation.accordion.deleteModalBody',
  };

  const certsDetailEmptyContentBody = (
    <Box>
      <Typography sx={emptyContentTitleTextStyle}>
        {intl.formatMessage({ id: 'userDetail.CertsAccordion.emptyTitleText' })}
      </Typography>
    </Box>
  );
  return (
    <AddDeleteAccordionListCard
      addButtonName="corporation.accordion.addCerts"
      addContentFormBody={addContentFormComponentBody()}
      accordionDetailEmptyContentBody={certsDetailEmptyContentBody}
      accordionDetailContent={certificationContentList}
      handleOnDelete={deleteCertification}
      modalContent={modalContent}
      isAddDeleteEnabled
      accordionSummaryTitle="userDetail.certification.summaryTitle"
      isProcessing={isProcessing}
      onAddFormSubmit={submitAddCertification}
    />
  );
};

CertificationWorkersAccordionCard.propTypes = {
  userCertificationList: PropTypes.arrayOf(PropTypes.shape({})),
  isProcessing: PropTypes.bool,
};
export default CertificationWorkersAccordionCard;
