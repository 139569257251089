import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ROUTE_CONSTANTS } from '../../../../../main/views/app/RouteBuilding/routeConstants';
import Button, { BUTTON_VARIANT } from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import theme from '../../../../theme';

const RecruiterOverview = ({ initialValues, onDelete }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const componentsDetailViewSectionHeaders = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];

  const componentsDetailViewFieldHeaders = [
    'components',
    'detailView',
    'typography',
    'fieldHeaders',
  ];

  const componentsDetailViewSemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];

  const viewHeaderStyling = {
    fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
  };

  const fieldHeaderStyling = {
    fontSize: get([...componentsDetailViewFieldHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewFieldHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewFieldHeaders, 'fontColor'], theme),
    margin: theme.spacing(4, 0, 1, 1),
  };

  const readOnlyFieldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 0, 1),
  };

  const isApproved = get('approved', initialValues) ? 'Approved' : 'Not Approved';

  const possibleLocationData = get('locations', initialValues)
    ?.map((loc) => get('name', loc))
    .toString();

  const possibleDepartmentData = get('departments', initialValues)
    ?.map((dep) => get('name', dep))
    .toString();

  const filledIconsColor = theme?.button?.palette?.whiteIcon;

  const handleJobButtonClick = () => {
    const url = `/${ROUTE_CONSTANTS.JOB_ORDERS}`;
    const ptParams = `corporation=${initialValues?.corporation?._id}&position=${initialValues?.positionInfo?.name}`;
    navigate(`${url}?${ptParams}`);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(0, 0, 1, 0) }}>
          Position Overview
        </Typography>

        <IconButton
          iconElement={<FormatListBulletedIcon sx={{ color: filledIconsColor, fontSize: 30 }} />}
          onClick={handleJobButtonClick}
          size="small"
          sx={{ marginLeft: 0.5, marginTop: 0.5 }}
          labelSx={{
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Barlow',
            color: `${filledIconsColor}`,
          }}
          label={intl.formatMessage({ id: 'buttonText.goToJobs' })}
        />
      </Box>
      <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
        <Typography sx={fieldHeaderStyling}>Position Title</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{get(['name'], initialValues)}</Typography>
        <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(2, 0, 1, 1) }}>
          Position Corporation
        </Typography>
        <Typography sx={readOnlyFieldValueStyling}>
          {get(['corporation', 'name'], initialValues)}
        </Typography>
        <Typography sx={fieldHeaderStyling}>Position Description</Typography>
        <Typography sx={readOnlyFieldValueStyling}>
          {get(['description'], initialValues)}
        </Typography>
        <Typography sx={fieldHeaderStyling}> Is this job approved?</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{isApproved}</Typography>
        <Typography sx={fieldHeaderStyling}> Position Location</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{possibleLocationData}</Typography>
        <Typography sx={fieldHeaderStyling}> Position Department</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{possibleDepartmentData}</Typography>
        <Button
          onClick={() => onDelete(true)}
          text={LanguageConverter('button.delete')}
          variant={BUTTON_VARIANT.DELETE}
          sx={{
            width: '146px',
            height: '32px',
            margin: theme.spacing(4, 0, 2, 1),
          }}
        />
      </Grid>
    </Box>
  );
};

RecruiterOverview.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    corporation: PropTypes.shape({
      _id: PropTypes.string,
    }),
    positionInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onDelete: PropTypes.func,
};

export default RecruiterOverview;
