import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectTimeSheet = (state) => get('timeSheet', state);

export const selectTimeSheetPlacementDetail = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timeSheetPlacementDetail
);

export const selectTimekeepingOverviewData = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timekeepingOverviewData
);

export const selectIsTimekeepingOverviewDataLoading = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isTimekeepingOverviewDataLoading
);

export const selectTimeCards = createSelector(selectTimeSheet, (timeSheet) => timeSheet.timecards);

export const selectSelectedTimeCard = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.selectedTimeCard
);
export const selectTimecardUpdateError = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timecardUpdateError
);

export const selectTimeCardsIsProcessing = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timecardsIsProcessing
);

export const selectIsReloadDailyTimecards = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isReloadDailyTimecards
);
export const selectDailyTimecards = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.dailyTimecards
);
export const selectTotalDailyTimecardsRowCount = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.totalDailyTimecardsRowCount
);
export const selectIsDailyTimecardsLoading = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isDailyTimecardsLoading
);

export const selectIsDisputedTimecardUpdating = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isDisputedTimecardUpdating
);

export const selectIsWeeklyTimecardsLoading = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isWeeklyTimecardsLoading
);

export const selectWeeklyTimecards = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.weeklyTimecards
);

export const selectIsTimecardUpdateHoursLoadingLoading = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isTimecardUpdateHoursLoading
);

export const selectTimeSheetPlacementDetailIsProcessing = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timeSheetDetailIsProcessing
);

export const selectTimeSheetGridTotalRowCount = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timeSheetGridTotalRowCount
);

export const selectTimecardActionIsProcessing = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timecardActionIsProcessing
);

export const selectTimeCardJobOrder = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timeCardJobOrder
);

export const selectTimeCardIsUpdating = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timeCardIsUpdating
);

export const selectTimeCardsFilterProps = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.timecardFilterProps
);

export const selectIsProcessingBatchApproval = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.isProcessingBatchApproval
);

export const selectBatchApprovalError = createSelector(
  selectTimeSheet,
  (timeSheet) => timeSheet.batchApprovalError
);
