import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, CircularProgress, Typography } from '@mui/material';

import CorporationIcon from '../../assets/icons/CorporationIcon.svg';
import PercentIcon from '../../assets/icons/PercentIcon.svg';
import StatusIcon from '../../assets/icons/StatusIconInFilters.svg';
import WaeButton, { BUTTON_VARIANT } from '../../components/Button';
import DateRangePickerFilter from '../../components/DateRangePickerFilter/DateRangePickerFilter';
import FreeTextSearchFilter, { SEARCHBAR_BACKGROUND } from '../../components/FreeTextSearchFilter';
import MultipleSelectDropdownChip from '../../components/MultipleSelectDropdownChip/MultipleSelectDropdownChip';
import NoResultsCard from '../../components/NoResultsCard/NoResultsCard';
import { SearchableSelectDropdownFilter } from '../../components/SearchableSelectDropdownFilter/SearchableSelectDropdownFilter';
import { SelectDropdownFilter } from '../../components/SelectDropdownFilter/SelectDropdownFilter';
import ShiftCard from '../../components/ShiftCard/ShiftCard';
import selectUser from '../../store/selectors/appSelector';
import {
  selectIsJobOrdersLoading,
  selectJobOrders,
  selectJobOrdersGroupedByStart,
  selectJobOrdersTotalRowCount,
  selectTimeCardIsUpdating,
  selectTimecardUpdateError,
} from '../../store/selectors/jobOrdersSelector';
import { PRIMARY_COLOR } from '../../theme/colorConstants';
import { getTimeZone } from '../../utils';

import { SEARCH_PARAM_KEYS, SHIFT_OPTION_KEYS } from './filterConstants';
import {
  FIVE_AM_IN_MIN,
  INITIAL_FILTERS,
  JOB_ORDER_STATUSES,
  NINE_THIRTY_PM_IN_MIN,
  ONE_THIRTY_PM_IN_MIN,
  SORT_VALUES,
  STATUS_UPDATE_ACTION,
} from './jobOrderConstants';
import {
  buildFilterPayloadFromUrl,
  getInitialSelectedShiftOptions,
  getNewShiftFilters,
  getTimeStatus,
} from './jobOrderHelpers';
import {
  clearTimeCardError,
  getJobOrders,
  postPlacementApproval,
  postPlacementFinalize,
  postPlacementResolve,
  postPlacementUnFinalize,
  updateJobOrderCards,
  updatePlacementCheckInStatus,
  updateTimeCard,
} from './jobOrdersReducer';

const corporationsAPICallback = {
  httpMethod: 'POST',
  route: 'corporations/read',
  generateBody: (searchTerm) => ({
    filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
  }),
};

const statusOptions = [
  { value: 'false', name: 'Active' },
  { value: 'true', name: 'Cancelled' },
];

const fillRateOptions = [
  { value: 'true', name: 'Full Shifts' },
  { value: 'false', name: 'Unfilled Shifts' },
];

const shiftOptionsPayload = {
  [SHIFT_OPTION_KEYS.FIVE_AM_TO_ONE_THIRTY_PM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: FIVE_AM_IN_MIN, end: ONE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  [SHIFT_OPTION_KEYS.ONE_THIRTY_PM_TO_NINE_THIRTY_PM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: ONE_THIRTY_PM_IN_MIN, end: NINE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  [SHIFT_OPTION_KEYS.NINE_THIRTY_PM_TO_FIVE_AM]: {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: NINE_THIRTY_PM_IN_MIN, end: FIVE_AM_IN_MIN, zone: getTimeZone() },
  },
};

const shiftOptions = Object.keys(shiftOptionsPayload);

const JobOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const sentinelRef = useRef(null);
  const isLoading = useSelector(selectIsJobOrdersLoading);
  const jobOrders = useSelector(selectJobOrders);
  const jobOrdersGroupedByStart = useSelector(selectJobOrdersGroupedByStart);
  const timecardIsUpdating = useSelector(selectTimeCardIsUpdating);
  const timecardUpdateError = useSelector(selectTimecardUpdateError);

  const [flippedStates, setFlippedStates] = useState({});
  const totalRowCount = useSelector(selectJobOrdersTotalRowCount);

  const [isResettingFilters, setIsResettingFilters] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filterAndSortData, setFilterAndSortData] = useState(() => {
    const parsedUrlFilters = buildFilterPayloadFromUrl(searchParams, []);

    return { ...INITIAL_FILTERS, filters: [...INITIAL_FILTERS.filters, ...parsedUrlFilters] };
  });

  const previousFiltersRef = useRef();

  const initialFillRateValue =
    fillRateOptions.find(
      (fillRate) => searchParams.get(SEARCH_PARAM_KEYS.FILL_RATE) === fillRate.value
    ) || null;

  const startDateParam = searchParams.get(SEARCH_PARAM_KEYS.START_DATE);
  const endDateParam = searchParams.get(SEARCH_PARAM_KEYS.END_DATE);
  const initialDateRange =
    startDateParam && endDateParam
      ? [parseInt(startDateParam, 10), parseInt(endDateParam, 10)]
      : null;

  const initialStatus =
    statusOptions.find((status) => searchParams.get(SEARCH_PARAM_KEYS.STATUS) === status.value) ||
    null;

  const initialSelectedShifts = getInitialSelectedShiftOptions(
    searchParams.get(SEARCH_PARAM_KEYS.SHIFT_START),
    searchParams.get(SEARCH_PARAM_KEYS.SHIFT_END)
  );

  const isUserAdminOrRecruiter = user?.role === UserRole.ADMIN || user?.role === UserRole.RECRUITER;

  useEffect(() => {
    if (isLoading) return;
    const payload = {
      filters: filterAndSortData.filters,
      limit: filterAndSortData.pageSize,
      page: filterAndSortData.page,
      sortBy: filterAndSortData.sort.map((item) => ({
        field: item.field,
        descending: item.sort === SORT_VALUES.DESC,
      })),
    };

    if (!isEqual(previousFiltersRef.current, payload)) {
      previousFiltersRef.current = payload;
      dispatch(getJobOrders(payload));
    }
  }, [dispatch, filterAndSortData, isLoading, searchParams]);

  useEffect(() => {
    setFilterAndSortData((prev) => {
      const filters = buildFilterPayloadFromUrl(searchParams, prev.filters);

      if (isEqual(prev.filters, filters)) {
        return prev;
      }

      if (filters.length > 0) {
        return { ...prev, page: 0, filters };
      }
      return { ...INITIAL_FILTERS, page: 0 };
    });
  }, [searchParams]);

  useEffect(() => {
    if (isResettingFilters) {
      setIsResettingFilters(false);
      setFilterAndSortData(INITIAL_FILTERS);
      setSearchParams({});
    }
  }, [isResettingFilters, setSearchParams]);

  const handleIntersection = useCallback(
    (entries) => {
      if (entries?.[0].isIntersecting && totalRowCount > jobOrders?.length && !isLoading) {
        setFilterAndSortData((prevVal) => ({
          ...prevVal,
          page: prevVal.page + 1,
        }));
      }
    },
    [jobOrders, totalRowCount, isLoading]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [handleIntersection]);

  const handleFlipClick = useCallback((date, cardId) => {
    setFlippedStates((prev) => ({
      ...prev,
      [`${date}_${cardId}`]: !prev[`${date}_${cardId}`],
    }));
  }, []);

  const handleCorporationChange = useCallback(
    (corporationId) => {
      if (corporationId !== searchParams.get(SEARCH_PARAM_KEYS.CORPORATION)) {
        const newParams = { ...Object.fromEntries(searchParams) };
        if (corporationId) {
          newParams[SEARCH_PARAM_KEYS.CORPORATION] = corporationId;
        } else {
          delete newParams[SEARCH_PARAM_KEYS.CORPORATION];
        }
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleFillRateChange = useCallback(
    (fillRate) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!fillRate) {
        delete newParams[SEARCH_PARAM_KEYS.FILL_RATE];
      } else {
        newParams[SEARCH_PARAM_KEYS.FILL_RATE] = fillRate;
      }

      if (
        newParams[SEARCH_PARAM_KEYS.FILL_RATE] !== searchParams.get(SEARCH_PARAM_KEYS.FILL_RATE)
      ) {
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleDateRangeChange = useCallback(
    (newDateRange) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      const startDate = newDateRange.find((date) => date.field === 'start')?.value;
      const endDate = newDateRange.find((date) => date.field === 'end')?.value;

      if (startDate && endDate) {
        newParams[SEARCH_PARAM_KEYS.START_DATE] = startDate;
        newParams[SEARCH_PARAM_KEYS.END_DATE] = endDate;
      } else {
        delete newParams[SEARCH_PARAM_KEYS.START_DATE];
        delete newParams[SEARCH_PARAM_KEYS.END_DATE];
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const handleStatusChange = useCallback(
    (newStatus) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!newStatus) {
        delete newParams[SEARCH_PARAM_KEYS.STATUS];
      } else {
        newParams[SEARCH_PARAM_KEYS.STATUS] = newStatus;
      }

      if (newParams[SEARCH_PARAM_KEYS.STATUS] !== searchParams.get(SEARCH_PARAM_KEYS.STATUS)) {
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleShiftOptionChange = useCallback(
    (selectedShifts) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      const selectedShiftFilters = selectedShifts.map((shift) => shiftOptionsPayload[shift]);
      const parsedShiftTime = getNewShiftFilters(selectedShiftFilters);
      const shiftStart = parsedShiftTime?.[0]?.value?.start;
      const shiftEnd = parsedShiftTime?.[0]?.value?.end;

      if (shiftStart !== undefined && shiftEnd !== undefined) {
        newParams[SEARCH_PARAM_KEYS.SHIFT_START] = shiftStart;
        newParams[SEARCH_PARAM_KEYS.SHIFT_END] = shiftEnd;
      } else {
        delete newParams[SEARCH_PARAM_KEYS.SHIFT_START];
        delete newParams[SEARCH_PARAM_KEYS.SHIFT_END];
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const handlePositionSearchChange = useCallback(
    (newPositionFilter) => {
      const positionSearchTerm = newPositionFilter?.[0]?.value;
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!positionSearchTerm) {
        delete newParams[SEARCH_PARAM_KEYS.POSITION];
      } else {
        newParams[SEARCH_PARAM_KEYS.POSITION] = positionSearchTerm;
      }
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const onClearTimeCardError = useCallback(() => {
    dispatch(clearTimeCardError());
  }, [dispatch]);

  const handleTimecardEditSubmit = useCallback(
    (data) => {
      dispatch(updateTimeCard(data));
    },
    [dispatch]
  );

  const moveCard = useCallback(
    (date, fromIndex, toIndex) => {
      if (fromIndex !== undefined && toIndex !== undefined) {
        const updatedGroups = { ...jobOrdersGroupedByStart };
        const cards = [...updatedGroups[date].cards];

        // Remove the card from the original position
        const [movedCard] = cards.splice(fromIndex, 1);

        // Add the card to the new position
        cards.splice(toIndex, 0, movedCard);

        updatedGroups[date] = {
          ...updatedGroups[date],
          cards,
        };

        dispatch(updateJobOrderCards(updatedGroups));
      }
    },
    [dispatch, jobOrdersGroupedByStart]
  );

  const onPlacementCheckStatusUpdate = useCallback(
    (data) => {
      dispatch(updatePlacementCheckInStatus(data));
    },
    [dispatch]
  );

  const onStatusUpdate = (data) => {
    const status = data?.status;
    const placementId = data?.placementId;
    if (status === STATUS_UPDATE_ACTION.APPROVE) {
      dispatch(postPlacementApproval({ placements: [placementId] }));
    }
    if (status === STATUS_UPDATE_ACTION.RESOLVE) {
      dispatch(postPlacementResolve({ placements: [placementId] }));
    }
    if (status === STATUS_UPDATE_ACTION.FINALIZE) {
      dispatch(postPlacementFinalize({ placements: [placementId] }));
    }
    if (status === STATUS_UPDATE_ACTION.UNFINALIZE) {
      dispatch(postPlacementUnFinalize({ placements: [placementId] }));
    }
  };

  const mainListContent = Object.keys(jobOrdersGroupedByStart).map((date) => {
    const { weekDay, cards } = jobOrdersGroupedByStart[date];
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }} key={date}>
        <Typography
          sx={{ fontFamily: 'Barlow', fontSize: '20px', fontWeight: 400, lineHeight: '42px' }}
        >
          {weekDay} <span style={{ fontWeight: 700 }}> {date} </span>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '25px',
          }}
        >
          {cards.map((card, index) => (
            <ShiftCard
              key={`${date}_${card._id}`}
              index={index}
              date={date}
              moveCard={moveCard}
              jobOrder={card}
              isFlipped={flippedStates[`${date}_${card._id}`] || false}
              handleFlipClick={() => handleFlipClick(date, card._id)}
              onPlacementCheckStatusUpdate={onPlacementCheckStatusUpdate}
              clearTimeCardError={onClearTimeCardError}
              handleTimecardEditSubmit={handleTimecardEditSubmit}
              onStatusUpdate={onStatusUpdate}
              timecardIsUpdating={timecardIsUpdating}
              timecardUpdateError={timecardUpdateError}
              isCancelled={card?.status === JOB_ORDER_STATUSES.CANCELLED}
              timeStatus={getTimeStatus(card?.start, card?.end)}
            />
          ))}
        </Box>
      </Box>
    );
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px', gap: '24px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={() => navigate('/jobs/create')}
        >
          {LanguageConverter('job.create')}
        </WaeButton>
        {!isResettingFilters && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {LanguageConverter('buttonText.filters')}
            </Typography>
            {isUserAdminOrRecruiter && (
              <SearchableSelectDropdownFilter
                disabled={isLoading}
                placeholder="Corporation"
                onValueChange={handleCorporationChange}
                optionsAPICallback={corporationsAPICallback}
                getOptionLabel={(option) => option.name}
                background={SEARCHBAR_BACKGROUND.DEFAULT}
                initialValue={searchParams.get(SEARCH_PARAM_KEYS.CORPORATION)}
              />
            )}
            <SelectDropdownFilter
              disabled={isLoading}
              placeholder="Fill Rate"
              onValueChange={handleFillRateChange}
              options={fillRateOptions}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 13,
                    width: 12,
                  }}
                  alt="Fill Rate"
                  src={PercentIcon}
                />
              }
              wrapperSx={{ width: '180px', padding: '0px 6px' }}
              initialValue={initialFillRateValue}
            />
            <DateRangePickerFilter
              disabled={isLoading}
              placeholder="Dates"
              onValueChange={handleDateRangeChange}
              field={{ start: 'start', end: 'end' }}
              operation={{ start: 'onOrAfter', end: 'onOrBefore' }}
              initialValue={initialDateRange}
            />
            <SelectDropdownFilter
              disabled={isLoading}
              placeholder="Status"
              onValueChange={handleStatusChange}
              options={statusOptions}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  alt="Status"
                  src={StatusIcon}
                />
              }
              initialValue={initialStatus}
            />
            <MultipleSelectDropdownChip
              disabled={isLoading}
              options={shiftOptions}
              placeholder="Shifts"
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                  }}
                  alt="Status"
                  src={CorporationIcon}
                />
              }
              allOptionsLabel="All Shifts"
              onValueChange={handleShiftOptionChange}
              defaultSelectedOptions={initialSelectedShifts}
            />
            <FreeTextSearchFilter
              disabled={isLoading}
              placeholder="Position Name"
              onValueChange={handlePositionSearchChange}
              field="name"
              operation="icontains"
              background={SEARCHBAR_BACKGROUND.DEFAULT}
              initialValue={{ value: searchParams.get(SEARCH_PARAM_KEYS.POSITION) }}
            />
          </Box>
        )}
      </Box>
      {Object.keys(jobOrdersGroupedByStart)?.length === 0 && !isLoading ? (
        <NoResultsCard
          headerMsgKey={null}
          bodyMsgKey="jobOrder.search.noResult"
          onResetFilter={() => {
            setIsResettingFilters(true);
          }}
        />
      ) : (
        <>
          {mainListContent}
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      <div ref={sentinelRef} />
    </Box>
  );
};

export default JobOrders;
