import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, autocompleteClasses, Grid, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import asyncService from '../../../datahub/asyncService';
import selectUser from '../../../main-deprecated/store/selectors/appSelector';
import theme from '../../theme';

import { getStyles } from './styles';

const SearchBarSingle = ({
  testId,
  background,
  data,
  disabled,
  dropDownOptions,
  input,
  onSubmitApiCallData,
  optionDisplayField,
  meta,
  placeholder,
  sx,
  EndIcon,
  errorsToHideText,
  shouldErrorColor,
  customIconColor,
}) => {
  const onChange = input?.onChange;
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const intl = useIntl();

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = user?.token;
  const refreshToken = user?.refreshToken;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(open && options.length === 0);

  useEffect(() => {
    if (!input.value) {
      setOptions([]);
      setSearchTerm('');
    }
  }, [input.value]);

  const { styleForTextField, styleForGrid, styleForIcon, styleForDropDownPaper } = getStyles({
    theme,
    background,
    sx,
    disabled,
    customIconColor,
  });

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (!dropDownOptions && !disabled && open) {
        const filters = data?.filters || [];

        const fieldsFilter = filters.find((f) => f.field === optionDisplayField);
        if (fieldsFilter) {
          filters.pop();
        }

        if (searchTerm) {
          filters.push({
            field: optionDisplayField,
            operation: 'icontains',
            value: searchTerm,
          });
        }

        const searchData = {
          ...data,
          filters,
        };

        setLoading(true);
        setOptions([]);
        asyncService({
          ...onSubmitApiCallData,
          data: searchData,
          onSuccess: (d) => {
            const rawApiQueryResult = d?.data?.documents;
            const apiResultDocuments = rawApiQueryResult;

            setOptions(apiResultDocuments);
            setLoading(false);
          },
          onError: () => {
            setOptions([]);
          },
          dispatch,
          token,
          refreshToken,
        });
      }
    }, 750);

    return () => clearTimeout(delayedSearch);
  }, [
    searchTerm,
    dispatch,
    token,
    onSubmitApiCallData,
    data,
    optionDisplayField,
    dropDownOptions,
    refreshToken,
    disabled,
    open,
  ]);

  const onTagChange = (event, value) => {
    onChange(value);
  };

  const shouldShowError = !!meta.error && (meta.submitFailed || meta.modified || meta.touched);
  const translatedErrorMessage = meta?.error && intl.formatMessage({ id: meta?.error });

  const ExpandIcon = EndIcon ? (
    <EndIcon style={styleForIcon} />
  ) : (
    <ExpandMoreIcon style={styleForIcon} />
  );

  const CollapseIcon = EndIcon ? (
    <EndIcon style={styleForIcon} />
  ) : (
    <ExpandLessIcon style={styleForIcon} />
  );

  const hideErrorText = (errorsToHideText || []).includes(meta?.error);
  return (
    <Grid sx={styleForGrid} item>
      <Autocomplete
        data-testid={testId}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        value={input.value || null}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        disableClearable
        clearIcon={null}
        options={dropDownOptions || options || [{}]}
        getOptionLabel={(option) => option[optionDisplayField] || ''}
        PaperComponent={({ children }) => <Paper sx={styleForDropDownPaper}>{children}</Paper>}
        renderInput={(params) => (
          <TextField
            label={placeholder}
            {...params}
            sx={styleForTextField}
            error={shouldErrorColor || shouldShowError}
            helperText={shouldShowError && !hideErrorText ? translatedErrorMessage : ''}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <Box>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {open ? ExpandIcon : CollapseIcon}
                </Box>
              ),
            }}
            onChange={(e) => {
              setOptions([]);
              setSearchTerm(e.target.value);
            }}
          />
        )}
        sx={{
          [`&& .${autocompleteClasses.inputRoot}`]: {
            paddingRight: theme.spacing(1),
          },
        }}
        onChange={onTagChange}
      />
    </Grid>
  );
};

SearchBarSingle.propTypes = {
  testId: PropTypes.string,
  background: PropTypes.string,
  data: PropTypes.shape({ filters: PropTypes.arrayOf(PropTypes.shape({})) }),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  dropDownOptions: PropTypes.arrayOf(PropTypes.shape([])),
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    error: PropTypes.string,
    modified: PropTypes.bool,
    touched: PropTypes.bool,
  }),
  multiSelect: PropTypes.bool,
  onSubmitApiCallData: PropTypes.shape({}),
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionDisplayField: PropTypes.string,
  placeholder: PropTypes.string,
  preDefinedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  preDefinedOptionText: PropTypes.string,
  selectedSectionTitle: PropTypes.string,
  sx: PropTypes.shape({}),
  EndIcon: PropTypes.shape({}),
  errorsToHideText: PropTypes.arrayOf(PropTypes.string),
  shouldErrorColor: PropTypes.bool,
  customIconColor: PropTypes.string,
};

export default SearchBarSingle;
