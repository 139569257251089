import theme from '../../theme';

export const cardStyles = {
  width: 320,
  height: 425,
  radisu: '8px',
};

export const cardHeaderStyles = {
  width: 320,
  height: 36,
  background: theme?.components?.agencyCard?.headerBanner?.backgroundColor,
};

export const percentageStyling = {
  ...theme?.components?.agencyCard?.percentageText?.typography,
  ...theme?.components?.agencyCard?.percentageText?.palette,
  justifySelf: 'center',
};
export const agencyHeaderStyling = {
  ...theme?.components?.agencyCard?.agencyHeader?.typography,
  ...theme?.components?.agencyCard?.agencyHeader?.palette,
};

export const agencyNameStyling = {
  ...theme?.components?.agencyCard?.agencyName?.typography,
  ...theme?.components?.agencyCard?.agencyName?.palette,
  margin: theme.spacing(0.5, 0, 0, 0),
};

export const onBoardingStyling = {
  ...theme?.components?.agencyCard?.onBoarding?.typography,
  ...theme?.components?.agencyCard?.onBoarding?.palette,
  margin: theme.spacing(0.5, 0, 0, 0),
};
export const titleStyling = {
  ...theme?.components?.agencyCard?.title?.typography,
  ...theme?.components?.agencyCard?.title?.palette,
  margin: theme.spacing(0.5, 0, 0, 0),
};
export const dataStyling = {
  ...theme?.components?.agencyCard?.data?.typography,
  ...theme?.components?.agencyCard?.data?.palette,
  margin: theme.spacing(0.5, 0, 0, 0),
};
export const locationStyling = {
  ...theme?.components?.agencyCard?.location?.typography,
  ...theme?.components?.agencyCard?.location?.palette,
  margin: theme.spacing(0.5, 0, 0, 0),
};
