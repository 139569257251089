import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';
import { updateFavoriteDnr } from '../reducer';

import FavoriteDNRAccordion from './FavoriteDNRAccordion';

const Favorite = ({ initialValues }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const favoriteOrDNRData = get('favorite', initialValues);
  const corporation = initialValues;

  const handleOnUpdate = (data) => {
    const postData = {
      ...data,
      // add other information such as user id
    };
    dispatch(updateFavoriteDnr(postData));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        sx={{
          fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
          fontSize: theme.components.detailView.typography.sectionHeader.fontSize,
          color: theme.components.detailView.typography.sectionHeader.fontColor,
        }}
      >
        {' '}
        Favorite Employees
      </Typography>
      {favoriteOrDNRData?.map((f) => (
        // eslint-disable-next-line react/no-array-index-key

        <ListItem key={f._id} sx={{ padding: '4px' }}>
          <FavoriteDNRAccordion
            favoriteOrDNR="favorite"
            favoriteOrDNREmployee={f}
            handleOnUpdate={handleOnUpdate}
            corporation={corporation}
            user={user}
          />
        </ListItem>
      ))}
    </Box>
  );
};

export default Favorite;
Favorite.propTypes = {
  initialValues: PropTypes.shape({}),
};
