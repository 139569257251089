import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import AdminDashboardReadSchemaRequest from '@careerstart/wae-common/schema/agency/dashboard/admin.agency.dashboard.read.req.json';
import AgencyDashboardReadSchemaRequest from '@careerstart/wae-common/schema/agency/dashboard/recruiter.agency.dashboard.read.req.json';
import AgencyReadSchemaRequest from '@careerstart/wae-common/schema/agency/read.post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';

import EditPencil from '../../assets/EditPencil.svg';
import FormCloseButton from '../../components/Form/FormCloseButton';
import { validateSchema } from '../../components/Form/validations';
import ScoreChart from '../../components/ScoreChart';
import selectUser from '../../store/selectors/appSelector';
import {
  selectAgencyDetail,
  selectIsAgencyLoading,
  selectIsWidgetDataLoading,
  selectWidgetData,
  selectWidgetError,
} from '../../store/selectors/dashboardSelector';
import { PRIMARY_PURPLE } from '../../theme/colorConstants';
import { PRIMARY_FONT } from '../../theme/fontConstants';
import { ROUTE_CONSTANTS } from '../app/RouteBuilding/routeConstants';

import Agencies from './Agencies/Agencies';
import { getFillRate } from './dashboardHelpers';
import DashboardJobs from './DashboardJobs';
import DashboardOnboarding from './DashboardOnboarding';
import { getAgencyForAgencyDetail, getWidgetData } from './dashboardReducer';
import OpeningsAndPlacementsWidget from './OpeningsAndPlacementsWidget';
import PotentialRevenueWidget from './PotentialRevenueWidget';
import WorkerStatusWidget from './WorkerStatusWidget';

const Dashboard = () => {
  const dispatch = useDispatch();
  const widgetData = useSelector(selectWidgetData);
  const widgetError = useSelector(selectWidgetError);
  const user = useSelector(selectUser);

  const { id } = useParams();
  const pathSelectedAgencyId = id;

  const isAdminOrRecruiter = user?.role === UserRole.ADMIN || user?.role === UserRole.RECRUITER;
  const location = useLocation();
  const isWidgetDataLoading = useSelector(selectIsWidgetDataLoading);
  const navigate = useNavigate();

  const agencyDetail = useSelector(selectAgencyDetail);
  const isGetAgencyLoading = useSelector(selectIsAgencyLoading);
  const adminRevenueLabels = {
    title: LanguageConverterV2('dashboard.widget.admin.revenue.title'),
    totalRevenue: LanguageConverterV2('dashboard.widget.admin.revenue.total'),
    scheduled: LanguageConverterV2('dashboard.widget.admin.revenue.booked'),
  };

  const agencyRevenueLabels = {
    title: LanguageConverterV2('dashboard.widget.agency.revenue.title'),
    availableRevenue: LanguageConverterV2('dashboard.widget.agency.revenue.available'),
    totalRevenue: LanguageConverterV2('dashboard.widget.agency.revenue.total'),
    totalRevenueDescription: LanguageConverterV2(
      'dashboard.widget.agency.revenue.total.description'
    ),
    scheduled: LanguageConverterV2('dashboard.widget.agency.revenue.scheduled'),
  };

  const revenueLabels = user?.role === UserRole.ADMIN ? adminRevenueLabels : agencyRevenueLabels;
  const isRecruiter = user?.role === UserRole.RECRUITER;
  const isAdmin = user?.role === UserRole.ADMIN;
  const isAgencyDashboard = isRecruiter || pathSelectedAgencyId;
  const recruitersAgencyId = isRecruiter && user?.recruiter?.agency;
  const allowRecruiterToView = (isRecruiter && recruitersAgencyId === id) || isAdmin;

  const { workers, revenue, futurePlacementsData } = isAgencyDashboard
    ? (agencyDetail?.dashboardData && agencyDetail.dashboardData) || {}
    : widgetData || {};

  useEffect(() => {
    if (!isWidgetDataLoading && widgetData === undefined && !widgetError) {
      const payload = {};

      const schemaErrors = validateSchema(
        payload,
        isRecruiter ? AgencyDashboardReadSchemaRequest : AdminDashboardReadSchemaRequest
      );
      if (Object.keys(schemaErrors).length === 0) {
        dispatch(getWidgetData(payload));
      }
    }
  }, [dispatch, isRecruiter, isWidgetDataLoading, user, widgetData, widgetError]);

  useEffect(() => {
    const payload = {
      filters: [
        {
          operation: 'equalsID',
          field: '_id',
          value: id || recruitersAgencyId,
        },
      ],
    };
    const schemaErrors = validateSchema(payload, AgencyReadSchemaRequest);

    if (
      // dashboard/agency has an id and if recruiter are allow to view
      id &&
      allowRecruiterToView &&
      Object.keys(schemaErrors).length === 0
    ) {
      dispatch(getAgencyForAgencyDetail(payload));
    } else if (
      // current in /dashboard and is an agency dashboard
      isAgencyDashboard &&
      location?.pathname === `/${ROUTE_CONSTANTS.DASHBOARD}` &&
      Object.keys(schemaErrors).length === 0
    ) {
      dispatch(getAgencyForAgencyDetail(payload));
    } else if (
      // if dashboard/agency has an id condition but recruiter do not match that id return back to dashboard
      id &&
      isRecruiter &&
      id !== recruitersAgencyId
    ) {
      const dashboardPath = generatePath(`/${ROUTE_CONSTANTS.DASHBOARD}`);
      navigate(`${dashboardPath}${location?.search}`);
    }
  }, [
    allowRecruiterToView,
    dispatch,
    id,
    isAgencyDashboard,
    isRecruiter,
    location?.pathname,
    location?.search,
    navigate,
    recruitersAgencyId,
  ]);

  return isAdminOrRecruiter ? (
    <Box padding="32px" display="flex" flexDirection="column" gap="32px">
      {id && (
        <Grid container item direction="row" sx={{ justifyContent: 'space-between' }}>
          <FormCloseButton
            onClose={() => navigate(`/${ROUTE_CONSTANTS.DASHBOARD}${location.search}`)}
            message="buttonText.goBack"
          />
          <Typography
            sx={{
              fontFamily: PRIMARY_FONT[400],
              color: PRIMARY_PURPLE[70],
              fontSize: 20,
              textDecoration: 'underline',
            }}
          >
            {LanguageConverterV2('dashboard.agency.editAgencyDetail')}
            <Box
              component="img"
              sx={{ height: 15, width: 15, marginLeft: 1 }}
              alt="Accordion Summary"
              src={EditPencil}
            />
          </Typography>
        </Grid>
      )}
      <Stack>
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="center">
          <Typography sx={{ fontFamily: PRIMARY_FONT[700], fontSize: 36 }}>
            {isAgencyDashboard ? agencyDetail?.name : LanguageConverterV2('dashboard.admin.title')}
          </Typography>
          {!isWidgetDataLoading && workers && (
            <ScoreChart
              label={LanguageConverterV2('dashboard.widget.reliability.title')}
              score={workers.averageReliabilityScore}
              description={LanguageConverterV2('dashboard.widget.reliability.description')}
            />
          )}
        </Stack>
        <Stack direction="row">
          {(user?.role === UserRole.ADMIN || user?.role === UserRole.RECRUITER) && (
            <DashboardOnboarding />
          )}
        </Stack>
      </Stack>

      {!isWidgetDataLoading &&
        !isGetAgencyLoading &&
        (isAgencyDashboard ? Object.keys(agencyDetail).length > 0 : widgetData) && (
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} xl={4}>
              <WorkerStatusWidget
                isLoading={isWidgetDataLoading || isGetAgencyLoading}
                activeWorkers={workers?.active || 0}
                pendingOnboarding={workers?.pendingOnboarding || 0}
                waitingVerification={workers?.waitingVerification || 0}
                atRisk={workers?.atRisk || 0}
                total={workers?.total || 0}
              />
            </Grid>

            <Grid item xs={12} lg={6} xl={4}>
              <PotentialRevenueWidget
                labels={revenueLabels}
                availableAmount={Math.round(revenue?.unscheduled) || 0}
                totalEarnedAmount={Math.round(revenue?.earnedTrailing12Month) || 0}
                scheduledAmount={Math.round(revenue?.scheduled) || 0}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <OpeningsAndPlacementsWidget
                isLoading={isWidgetDataLoading || isGetAgencyLoading}
                totalOpenings={futurePlacementsData?.totalOpenings || 0}
                totalActivePlacements={futurePlacementsData?.totalActivePlacements || 0}
                percentStaffed={getFillRate(
                  futurePlacementsData?.totalActivePlacements,
                  futurePlacementsData?.totalOpenings
                )}
                totalWorkerCancellations={futurePlacementsData?.totalWorkerCancellations || 0}
                cancellationToPlacementRatio={
                  futurePlacementsData?.cancellationToPlacementRatio || 0
                }
              />
            </Grid>
          </Grid>
        )}

      {isWidgetDataLoading ||
        (isGetAgencyLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ))}
      {!isWidgetDataLoading &&
        !isGetAgencyLoading &&
        (isAgencyDashboard ? Object.keys(agencyDetail).length === 0 : !widgetData) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography> {LanguageConverterV2('dashboard.noDataAvailable')} </Typography>
          </Box>
        )}

      {isAgencyDashboard && <DashboardJobs />}
      {user?.role === UserRole.ADMIN && !isAgencyDashboard && <Agencies />}
    </Box>
  ) : null;
};

export default Dashboard;
