import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import { BLACK } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

const LabeledField = ({ title, field }) => (
  <Stack>
    <Typography sx={{ fontFamily: PRIMARY_FONT[400], color: BLACK[70], fontSize: '16px' }}>
      {title}
    </Typography>
    {field}
  </Stack>
);

LabeledField.propTypes = {
  title: PropTypes.string,
  field: PropTypes.node,
};

export default LabeledField;
