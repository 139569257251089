import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';

import AddDeleteAccordionListCard from '../../../../../components/AddDeleteAccordionListCard';

import {
  contentBodyNameTextStyle,
  contentTitleTextStyle,
  emptyContentTitleTextStyle,
} from './UserDetailAccordionCardsStyles';

const FavoritesWorkersAccordionCard = ({ favoriteList, isProcessing }) => {
  const intl = useIntl();

  const favoriteCorporationContent = favoriteList?.map((corp) => ({
    key: corp?.id,
    content: (
      <Box>
        <Box key={`${corp?.id} favoriteCorpContent`}>
          <Typography sx={contentTitleTextStyle}>
            {intl.formatMessage({ id: 'corporation.accordion.company' })}
          </Typography>
          <Typography sx={contentBodyNameTextStyle}>{corp?.name}</Typography>
        </Box>
      </Box>
    ),
  }));

  const favoriteDetailEmptyContentBody = (
    <Box>
      <Typography sx={emptyContentTitleTextStyle}>
        {intl.formatMessage({ id: 'userDetail.FavoriteAccordion.emptyBodyText' })}
      </Typography>
    </Box>
  );
  return (
    <AddDeleteAccordionListCard
      accordionDetailContent={favoriteCorporationContent}
      accordionSummaryTitle="userDetail.favorite.summaryTitle"
      accordionDetailEmptyContentBody={favoriteDetailEmptyContentBody}
      isProcessing={isProcessing}
    />
  );
};

FavoritesWorkersAccordionCard.propTypes = {
  favoriteList: PropTypes.arrayOf(PropTypes.shape({})),
  isProcessing: PropTypes.bool,
};

export default FavoritesWorkersAccordionCard;
