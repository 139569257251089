import React, { useCallback, useState } from 'react';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import UserListDrawer from '../../../main-deprecated/views/users/userList/UserListDrawer/UserListDrawer';
import AddAdminIcon from '../../assets/icons/AddAdminIcon';
import AddEmployerIcon from '../../assets/icons/AddEmployerIcon';
import AddRecruiterIcon from '../../assets/icons/AddRecruiterIcon';
import WaeButton, { BUTTON_VARIANT } from '../../components/Button';
import { PRIMARY_COLOR } from '../../theme/colorConstants';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const menuItems = [
  { role: UserRole.ADMIN, label: 'userList.addAdmin', Icon: AddAdminIcon },
  { role: UserRole.EMPLOYER, label: 'userList.addEmployer', Icon: AddEmployerIcon },
  { role: UserRole.RECRUITER, label: 'userList.addRecruiter', Icon: AddRecruiterIcon },
];

const AddUserMenuButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userCreateStrategy, setUserCreateStrategy] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (role) => {
      setUserCreateStrategy(role);
      setIsDrawerOpen(true);
      handleClose();
    },
    [handleClose]
  );

  return (
    <>
      <WaeButton
        onClick={handleClick}
        variant={BUTTON_VARIANT.DEFAULT}
        actionColor={PRIMARY_COLOR[70]}
      >
        {LanguageConverterV2('userList.addNewUser')}
      </WaeButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map(({ role, label, Icon }) => (
          <MenuItem key={role} onClick={() => handleMenuItemClick(role)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primary={LanguageConverterV2(label)}
              primaryTypographyProps={{
                sx: {
                  fontFamily: PRIMARY_FONT[700],
                  fontSize: '18px',
                  fontWeight: 700,
                },
              }}
            />
          </MenuItem>
        ))}
      </Menu>
      <UserListDrawer
        anchor="right"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        userCreateStrategy={userCreateStrategy}
      />
    </>
  );
};

export default AddUserMenuButton;
