import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import WaeButton from '../../../../../components/Button';
import { BLACK } from '../../../../../theme/colorConstants';
import theme from '../../../../../theme/theme';
import { updateDNA, updateDNR } from '../../../reducer';

import AddDNRorDNAComponent from './AddDNRorDNAComponent';
import DNRorDNAAccordion from './DNRorDNAAccordion';

const DNRandDNAComponent = ({ DNRorDNA, userID, isLoading, message }) => {
  const dispatch = useDispatch();
  const { details, count, type } = DNRorDNA;
  const [open, setOpen] = useState(false);
  const dnaDnrButtonVisibility = !(type === 'DNA' && count === 1);
  const handleAddClose = () => {
    setOpen(false);
  };
  const handleAddClick = () => {
    setOpen(true);
  };

  const handleAddDNAorDNR = (reason, corp) => {
    const postDNAData = {
      id: userID,
      reason,
    };

    const postDNRData = {
      id: get('_id', corp),
      userID,
      reason,
    };

    if (type === 'DNR') {
      dispatch(updateDNR(postDNRData));
    }
    if (type === 'DNA') {
      dispatch(updateDNA(postDNAData));
    }
  };

  const handleDeleteDNAorDNR = (corpID) => {
    const removeDNRData = {
      id: corpID,
      userID,
      remove: true,
    };

    const removeDNAData = {
      id: userID,
      remove: true,
    };
    if (type === 'DNR') {
      dispatch(updateDNR(removeDNRData));
    }
    if (type === 'DNA') {
      dispatch(updateDNA(removeDNAData));
    }
  };

  const detailViewTypographySemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];

  const detailViewTypographyRecordHeader = [
    'components',
    'detailView',
    'typography',
    'recordHeader',
  ];
  const detailViewTypographyRecordText = ['components', 'detailView', 'typography', 'recordText'];
  return (
    <Box
      sx={{
        backgroundColor: BLACK[100],
        padding: '10px',
        borderRadius: '10px',
        marginTop: '10px',
      }}
    >
      <Box
        sx={{
          fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
          color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
          fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
        }}
      >
        {type} History
      </Box>
      {dnaDnrButtonVisibility && (
        <WaeButton
          onClick={handleAddClick}
          disabled={isLoading}
          text={`Add ${type}`}
          sx={{
            float: 'right',
            margin: theme.spacing(-2, 0, 0, 0),
          }}
        />
      )}
      <Box
        sx={{
          fontSize: get([...detailViewTypographyRecordHeader, 'fontSize'], theme),
          color: get([...detailViewTypographyRecordHeader, 'fontColor'], theme),
          fontFamily: get([...detailViewTypographyRecordHeader, 'fontFamily'], theme),
          alignItems: 'center',
          margin: theme.spacing(0, 0, 2, 0),
        }}
      >
        {count} {type}
      </Box>

      <Box
        sx={{
          fontSize: get([...detailViewTypographyRecordText, 'fontSize'], theme),
          color: get([...detailViewTypographyRecordText, 'fontColor'], theme),
          fontFamily: get([...detailViewTypographyRecordText, 'fontFamily'], theme),
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography>{message}</Typography>
      </Box>
      {open && (
        <AddDNRorDNAComponent
          handleAddClose={handleAddClose}
          handleAddDNAorDNR={handleAddDNAorDNR}
          type={type}
        />
      )}
      {details &&
        details.length > 0 &&
        details.map((d) => (
          <DNRorDNAAccordion
            key={d.id}
            accordionInfo={d}
            handleDeleteDNAorDNR={handleDeleteDNAorDNR}
            userID={userID}
          />
        ))}
    </Box>
  );
};

DNRandDNAComponent.propTypes = {
  DNRorDNA: PropTypes.shape({
    details: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          corpName: PropTypes.string,
          corpId: PropTypes.string,
          reason: PropTypes.string,
          requester: PropTypes.string,
          date: PropTypes.string,
          note: PropTypes.string,
        })
      ),
    ]),
    count: PropTypes.number,
    type: PropTypes.string,
  }),
  userID: PropTypes.string,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
};
export default DNRandDNAComponent;
