import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { Box, styled, TextField } from '@mui/material';

import { PRIMARY_COLOR } from '../../../theme/colorConstants';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: PRIMARY_COLOR[50],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: PRIMARY_COLOR[50],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: PRIMARY_COLOR[50],
    },
  },
}));

const parseToEpoch = (dateStr, timeStr) => {
  const combined = `${dateStr} ${timeStr}`;
  const parsed = dayjs(combined, 'MM/DD/YY hh:mm A');
  return parsed.isValid() ? parsed.valueOf() : null;
};

const formatEpoch = (epoch) => {
  if (!epoch) return { date: '', time: '' };
  const d = dayjs(Number(epoch));
  return {
    parsedDate: d.format('MM/DD/YY'),
    parsedTime: d.format('hh:mm A'),
  };
};

const DateTimeInputMaskedField = ({ label, value, onChange }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (value) {
      const { parsedDate, parsedTime } = formatEpoch(value);
      setDate(parsedDate);
      setTime(parsedTime);
    }
  }, [value]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);

    const isDateFullyFilled = newDate.length === 8;
    const isTimeFullyFilled = time.length === 8;

    const isDateValid = dayjs(newDate, 'MM/DD/YY').isValid();
    const isTimeValid = /^([0]?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/.test(time);

    if (isDateFullyFilled && isTimeFullyFilled) {
      const valid = isDateValid && isTimeValid;
      setHasError(!valid);

      if (valid) {
        const epoch = parseToEpoch(newDate, time);
        onChange(epoch);
      }
    } else {
      setHasError(false);
    }
  };

  const handleTimeChange = (e) => {
    const newTime = e.target.value.toUpperCase();
    setTime(newTime);

    const isTimeFullyFilled = newTime.length === 8;
    const isDateFullyFilled = date.length === 8;

    const isTimeValid = /^([0]?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/.test(newTime);
    const isDateValid = dayjs(date, 'MM/DD/YY').isValid();

    if (isDateFullyFilled && isTimeFullyFilled) {
      const valid = isDateValid && isTimeValid;
      setHasError(!valid);

      if (valid) {
        const epoch = parseToEpoch(date, newTime);
        onChange(epoch);
      }
    } else {
      setHasError(false);
    }
  };

  return (
    <Box>
      <StyledTextField
        label={label}
        fullWidth
        error={hasError}
        InputProps={{
          readOnly: true,
          inputProps: { style: { display: 'none' } },
          startAdornment: (
            <Box display="flex" alignItems="center" padding="15px 2px" gap="8px" width="100%">
              <InputMask mask="99/99/99" value={date} onChange={handleDateChange}>
                <input
                  placeholder="MM/DD/YY"
                  style={{
                    border: 'none',
                    outline: 'none',
                    font: 'inherit',
                    background: 'transparent',
                    width: 90,
                  }}
                />
              </InputMask>

              <InputMask mask="99:99 aa" value={time} onChange={handleTimeChange}>
                <input
                  placeholder="hh:mm AM"
                  style={{
                    border: 'none',
                    outline: 'none',
                    font: 'inherit',
                    background: 'transparent',
                    width: 90,
                    textTransform: 'uppercase',
                  }}
                />
              </InputMask>
            </Box>
          ),
        }}
      />
      <Box
        mt={0.5}
        ml={1}
        fontSize="0.75rem"
        minHeight="20px"
        color={hasError ? 'error.main' : 'transparent'}
      >
        {hasError ? 'Invalid date or time' : ' '}
      </Box>
    </Box>
  );
};

DateTimeInputMaskedField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, null]),
  onChange: PropTypes.func.isRequired,
};

export default DateTimeInputMaskedField;
