import { createTheme } from '@mui/material/styles';

import {
  ACTION_BLUE,
  BLACK,
  FEED_BACK,
  PRIMARY_COLOR,
  PRIMARY_PURPLE,
  RED,
} from './colorConstants';
import { PRIMARY_FONT } from './fontConstants';
import fonts from './fonts';

const theme = createTheme({
  typography: {
    fontFamily: PRIMARY_FONT.default,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: fonts,
    },
    scrollBar: {
      trackColor: PRIMARY_COLOR[30],
      thumbColor: PRIMARY_COLOR[50],
      altThumbColorGray: '#D9D9D9',
      thumbHover: PRIMARY_COLOR[80],
    },
    snackBar: {
      palette: {
        backGroundColor: '#CFB9FF',
        fontColor: PRIMARY_COLOR[70],
      },
      typography: {
        fontFamily: PRIMARY_FONT[700],
        fontSize: '18px',
        lineHeight: '20px',
      },
    },
    alert: {
      palette: {
        backgroundColor: {
          errorBgColor: `${FEED_BACK.RED_50}`,
          warningBgColor: `${FEED_BACK.YELLOW_50}`,
          successBgColor: `${FEED_BACK.GREEN_50}`,
        },
        iconColor: `${BLACK[100]}`,
      },
      typography: {
        fontFamily: PRIMARY_FONT[400],
        fontSize: '16px',
        fontColor: `${BLACK[100]}`,
      },
    },
    agencyCard: {
      headerBanner: {
        backgroundColor: BLACK[10],
      },
      agencyHeader: {
        typography: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '14.16px',
          lineHeight: '14.16px',
        },
        palette: { color: BLACK[70] },
      },
      agencyName: {
        typography: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '20px',
          lineHeight: '24px',
          textDecoration: 'underline',
        },
        palette: { color: PRIMARY_COLOR[70] },
      },

      data: {
        typography: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '16px',
          lineHeight: '24px',
        },
        palette: { color: '#000000' },
      },
      location: {
        typography: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          lineHeight: '18px',
        },
        palette: { color: BLACK[70] },
      },
      title: {
        typography: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          lineHeight: '24px',
        },
        palette: { color: '#000000' },
      },
      onBoarding: {
        typography: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '16px',
          lineHeight: '18.75px',
        },
        palette: { color: BLACK[70] },
      },
      percentageText: {
        typography: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '16px',
          lineHeight: 57.43,
        },
        palette: { color: '#000000' },
      },
    },
    barChart: {
      palette: {},
      typography: {
        header: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
        legend: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
      },
    },
    dashboardOnboarding: {
      header: {
        typography: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '18px',
          lineHeight: '24px',
        },
        palette: { color: BLACK[100] },
      },
    },
    pieChart: {
      palette: {},
      typography: {
        header: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
        pieChartDetailHeader: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '30px',
          fontWeight: 800,
          fontColor: `${BLACK[100]}`,
        },
        pieChartDetailText: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '14px',
          fontWeight: 500,
          fontColor: `${BLACK[70]}`,
        },
        legend: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 400,
          fontColor: `${BLACK[100]}`,
        },
      },
    },
    checkbox: {
      typography: {
        dark: {
          fontColor: `${BLACK[0]}`,
          fontSize: '14px',
          fontFamily: PRIMARY_FONT[400],
        },
      },
    },
    dialog: {
      palette: {
        text: { color: BLACK[80] },
      },
      typography: {
        title: {
          fontFamily: PRIMARY_FONT[600],
          fontSize: '30px',
        },
        text: {
          fontSize: '16px',
        },
      },
    },
    drawer: {
      palette: {
        header: `${BLACK[100]}`,
        description: `${BLACK[100]}`,
      },
      typography: {
        header: {
          fontFamily: PRIMARY_FONT[800],
          fontSize: '30px',
        },
        description: {
          fontFamily: PRIMARY_FONT,
          fontSize: '16px',
        },
      },
    },
    candidatePickerCard: {
      palette: {
        backgroundColor: PRIMARY_PURPLE[25],
        defaultBackgroundColor: BLACK[0],
        favoriteIconColor: ACTION_BLUE.DARK_BLUE,
        reliableGreen: FEED_BACK.GREEN_50,
        reliableRed: FEED_BACK.RED_50,
        reliableYellow: FEED_BACK.YELLOW_50,
      },
      typography: {
        title: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '16px',
        },
        subtitle: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '12px',
        },
      },
    },
    createJobConfirmationModal: {
      palette: {
        title: {
          color: PRIMARY_PURPLE[60],
        },
        postButton: {
          color: PRIMARY_PURPLE[70],
        },
        editButton: {
          color: BLACK[70],
          backgroundColor: BLACK[0],
        },
      },
      typography: {
        title: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '36px',
          weight: 800,
        },
        header: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '24px',
        },
        headerText: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '14px',
          lineHeight: '8px',
        },
        text: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '18px',
          lineHeight: '1.5',
        },
      },
    },
    detailLeft: {
      palette: {
        backgroundColor: BLACK[0],
        buttonColor: PRIMARY_PURPLE[50],
        buttonBg: PRIMARY_PURPLE[60],
        greyBg: BLACK[10],
      },
      header: {
        typography: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '24px',
        },
      },
      title: {
        typography: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
        },
      },
      subText: {
        typography: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '12px',
        },
      },
      button: {
        typography: {
          fontFamily: PRIMARY_FONT[500],
        },
      },
    },
    employeeInviteSelection: {
      typography: {
        selectionTextFontFamily: PRIMARY_FONT[400],
        selectionTitleTextFontFamily: PRIMARY_FONT[500],
        selectionTextFontSize: '16px',
        selectionTitleTextFontSize: '20px',
        selectionTextFontLineHeight: '16px',
        selectionTitleTextFontLineHeight: '20px',
        rosterFillSubTitleTextFontFontFamily: PRIMARY_FONT[300],
        rosterFillSubTitleTextFontFontSize: '16px',
        rosterFillSubTitleTextFontLineHeight: '25px',
        invitedWorkerAmountTextFontFontFamily: PRIMARY_FONT[700],
        invitedWorkerAmountTextFontFontSize: '20px',
        invitedWorkerAmountTextTextFontLineHeight: '25px',
        errorFontFamily: PRIMARY_FONT[300],
      },
      palette: {
        light: {
          borderColor: '#000000',
          errorFontColor: '#d32f2f',
          filterBorderColor: PRIMARY_PURPLE[70],
          filterTextColor: PRIMARY_PURPLE[70],
          buttonBackGroundColor: PRIMARY_PURPLE[60],
          selectionTextFontColor: BLACK[70],
          selectionTitleTextFontColor: '#000000',
          rosterFillSubTitleTextFontColor: '#000000',
          invitedWorkerAmountTextFontFontColor: PRIMARY_PURPLE[70],
          invitedWorkerAmountBackgroundColor: PRIMARY_PURPLE[20],
        },
      },
    },
    RadioChips: {
      typography: {
        light: {
          errorFontFamily: 'Roboto,Helvetica,Arial,sans-serif',
          errorFontSize: '12px',
          cardDescriptionFontFamily: PRIMARY_FONT[300],
          cardLabelFontFamily: PRIMARY_FONT[500],
          labelFontFamily: PRIMARY_FONT[500],
        },
        dark: {},
      },
      palette: {
        light: {
          errorFontColor: '#d32f2f',
          radioFillColor: PRIMARY_PURPLE[60],
          radioBorderColor: '#494949',
          cardDescriptionFontColor: '#000000',
          cardLabelFontColor: '#000000',
          labelFontColor: '#000000',
        },
        dark: {
          errorFontColor: `${BLACK[100]}`,
        },
      },
    },
    selectDropdown: {
      typography: {
        fontFamily: 'Barlow-600',
        fontSize: '16px',
      },
      borderRadius: '40px',
      palette: {
        dark: {
          backgroundColor: `${BLACK[80]}`,
          fontColor: `${BLACK[0]}`,
        },
        light: {
          backgroundColor: `${PRIMARY_PURPLE[20]}`,
          fontColor: `${BLACK[100]}`,
        },
        grey: {
          backgroundColor: `${BLACK[20]}`,
          fontColor: `${BLACK[100]}`,
        },
      },
    },
    PillChips: {
      palette: {
        unselected: {
          background: {
            light: `${PRIMARY_PURPLE[30]}`,
          },
          font: {
            light: `${PRIMARY_PURPLE[80]}`,
          },
        },
        selected: {
          background: {
            light: `${PRIMARY_PURPLE[70]}`,
          },
          font: {
            light: `${BLACK[0]}`,
          },
        },
      },
      typography: {
        fontFamily: 'Barlow-600',
        fontSize: '14px',
      },
    },
    modal: {
      typography: {
        title: {
          fontColor: BLACK[80],
          fontFamily: 'Barlow-800',
          fontSize: '30px',
        },
        bodyText: {
          fontColor: BLACK[70],
          fontFamily: 'Barlow-500',
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
    navigationBar: {
      palette: {
        activeColor: PRIMARY_PURPLE[70],
        inactiveColor: BLACK[100],
        backgroundColor: BLACK[0],
      },
      typography: {
        active: PRIMARY_FONT[900],
        inactive: PRIMARY_FONT[500],
      },
    },
    settings: {
      fieldBlock: {
        margin: '64px 0',
      },
      palette: {
        mainHeader: {
          fontColor: `${BLACK[100]}`,
        },
        sectionHeader: {
          fontColor: `${BLACK[100]}`,
        },
        sectionSubText: {
          fontColor: `${BLACK[80]}`,
        },
        subSectionHeader: {
          fontColor: `${BLACK[100]}`,
        },
        subSectionSubText: {
          fontColor: `${BLACK[80]}`,
        },
        fieldHeader: {
          fontColor: `${BLACK[100]}`,
        },
        fieldText: {
          fontColor: `${BLACK[100]}`,
        },
      },
      typography: {
        mainHeader: {
          fontSize: '38px',
          fontWeight: '800',
          fontFamily: `Barlow-800`,
        },
        sectionHeader: {
          fontSize: '24px',
          fontWeight: '800',
          fontFamily: `Barlow-800`,
        },
        sectionSubText: {
          fontSize: '16px',
          fontWeight: '400',
          fontFamily: `Barlow`,
          margin: '0 0 8px 0',
        },
        subSectionHeader: {
          fontSize: '18px',
          fontWeight: '600',
          fontFamily: `Barlow-800`,
        },
        subSectionSubText: {
          fontSize: '16px',
          fontWeight: '400',
          fontFamily: `Barlow`,
          margin: '0 0 8px 0',
        },
        fieldHeader: {
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: `Barlow`,
          lineHeight: 0,
        },
        fieldText: {
          fontSize: '16px',
          fontWeight: '600',
          fontFamily: `Barlow`,
          lineHeight: 0,
        },
      },
    },
    detailView: {
      palette: {
        defaultTextColor: `${BLACK[100]}`,
        backgroundColor: `${BLACK[90]}`,
        closeIconColor: `${BLACK[50]}`,
        closeIconWhiteColor: `${BLACK[0]}`,
        sectionBackgroundColor: `${BLACK[100]}`,
        lightLazyLoadColor: `${BLACK[0]}`,
        sectionTextColor: `${PRIMARY_PURPLE[40]}`,
        corpTextColor: `${BLACK[20]}`,
        employeesNameColor: `${PRIMARY_PURPLE[70]}`,
        lightHeartColor: `${BLACK[60]}`,
        searchBarColor: `${BLACK[20]}`,
        trashCanColor: `${FEED_BACK.RED_50}`,
        dnrTextFieldColor: `${BLACK[0]}`,
        recordButtonColor: `${BLACK[0]}`,
        deletePlacementBgColor: `${BLACK[30]}`,
        cancelledShiftCardBgColor: `${PRIMARY_PURPLE[10]}`,
        cancelledShiftTitleColor: `${PRIMARY_PURPLE[70]}`,
        cancelledShiftSubtitleColor: `${BLACK[80]}`,
        cancelledShiftTimeColor: `${BLACK[100]}`,
        fixedBottomBoxColor: `${BLACK[0]}`,
        dnrTextColor: `${BLACK[80]}`,
        recommendModalHeader: `${BLACK[80]}`,
        recommendModalSubHeader: `${BLACK[70]}`,
      },
      typography: {
        general: {
          fontSize: '16px',
          fontColor: `${BLACK[20]}`,
          fontFamily: PRIMARY_FONT[400],
        },
        fieldHeaders: {
          fontSize: '14px',
          fontFamily: `Barlow-500`,
          fontColor: `${BLACK[40]}`,
        },
        header: {
          fontSize: '30px',
          fontColor: `${BLACK[0]}`,
          fontFamily: 'Barlow-800',
        },
        sectionHeader: {
          fontSize: '18px',
          fontColor: `${BLACK[0]}`,
          fontFamily: 'Barlow-700',
        },
        subheader: {
          fontSize: '18px',
          fontColor: ` ${BLACK[50]}`,
          fontFamily: PRIMARY_FONT[400],
        },
        subSectionHeader: {
          fontSize: '14px',
          fontColor: ` ${BLACK[40]}`,
          fontFamily: 'Barlow-500',
        },
        semiBoldHeader: {
          fontSize: '18px',
          fontColor: ` ${BLACK[50]}`,
          fontFamily: 'Barlow-600',
        },
        semiBoldText: {
          fontSize: '16px',
          fontColor: `${BLACK[0]}`,
          fontFamily: 'Barlow-600',
        },
        detailText: {
          fontSize: '12px',
          fontColor: `${BLACK[40]}`,
          fontFamily: PRIMARY_FONT[400],
        },
        certText: {
          fontSize: '14px',
          fontColor: `${BLACK[30]}`,
          fontFamily: PRIMARY_FONT[400],
        },
        locationText: {
          fontSize: '16px',
          fontColor: `${PRIMARY_PURPLE[40]}`,
          fontFamily: 'Barlow-600',
        },
        employeeText: {
          fontSize: '12px',
          fontColor: `${BLACK[70]}`,
          fontFamily: PRIMARY_FONT[400],
        },

        requireCertText: {
          fontFamily: 'Barlow-500',
          fontSize: '16px',
          fontColor: `${BLACK[0]}`,
        },

        rating: {
          color: `${PRIMARY_PURPLE[40]}`,
        },
        recordHeader: {
          fontFamily: 'Barlow-700',
          fontSize: '20px',
          fontColor: `${PRIMARY_PURPLE[40]}`,
        },
        recordText: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[50]}`,
        },
        reasonTextWhite: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[0]}`,
        },
        reasonTextBlack: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[100]}`,
        },
        corpHeader: {
          fontFamily: 'Barlow-600',
          fontSize: '14px',
          fontColor: `${BLACK[50]}`,
        },
        shiftName: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[20]}`,
        },
        ptShiftName: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontColor: `${BLACK[20]}`,
        },
        dnrTextField: {
          fontFamily: 'Barlow-500',
          fontSize: '16px',
        },
        recordButton: {
          fontFamily: 'Barlow-700',
          fontSize: '16px',
        },
        cancelPlacement: {
          fontFamily: 'Barlow-700',
          fontSize: '16px',
        },
        cancelledShiftTitle: {
          fontFamily: PRIMARY_FONT[800],
          fontSize: '30px',
        },
        cancelledShiftSubtitle: {
          fontFamily: PRIMARY_FONT,
          fontSize: '16px',
        },
        cancelledShiftTime: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '22x',
        },
        departmentName: {
          fontSize: '16px',
          fontColor: `${PRIMARY_PURPLE[40]}`,
          fontFamily: PRIMARY_FONT[600],
        },
        departmentDesc: {
          fontSize: '14px',
          fontColor: `${BLACK[50]}`,
          fontFamily: PRIMARY_FONT[600],
        },
        manageUserName: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '22px',
        },
      },
    },
    invitationCard: {
      dayJobs: {
        backgroundColor: BLACK[100],
      },
      longJobs: {
        backgroundColor: BLACK[90],
      },
    },
    form: {
      palette: {
        dark: { backgroundColor: `${BLACK[100]}` },
      },
      typography: {},
    },
    roleMenu: {
      palette: {
        text: BLACK[90],
      },
      typography: {
        titleFont: PRIMARY_FONT[500],
      },
    },

    readOnlyListField: {
      palette: {},
      typography: {
        fontFamilyMedium: 'Barlow-500',
        fontFamily: 'Barlow-600',
        fontWeightOfKey: 400,
        fontWeightOfValue: 600,
        fontSize: '16px',
        fontColor: `${BLACK[10]}`,
        darkFontColor: `${BLACK[100]}`,
        lineHeight: '24px',
      },
    },
    shiftSelector: {
      palette: {
        unselectedBackgroundColor: BLACK[10],
        selectedBackgroundColor: BLACK[30],
      },
    },
    table: {
      palette: {
        body: {
          light: {},
          dark: {
            odd: {
              backgroundColor: `${BLACK[100]}`,
            },
            backgroundColor: `${BLACK[90]}`,
            fontColor: `${BLACK[0]}`,
          },
        },
      },
      typography: {
        body: {
          fontFamily: 'Barlow-600',
          fontSize: '16px',
        },
        header: {
          fontFamily: 'Barlow-600',
          fontSize: '14px',
        },
      },
    },
    tabPages: {
      palette: {
        tab: {
          light: {},
          dark: {
            unselected: {
              fontColor: `${PRIMARY_PURPLE[40]}`,
              backgroundColor: `${BLACK[100]}`,
            },
            selected: {
              fontColor: `${BLACK[0]}`,
              backgroundColor: `${PRIMARY_PURPLE[70]}`,
            },
          },
        },
      },
      typography: {
        tab: {
          selected: {
            fontFamily: 'Barlow-600',
            fontSize: '14px',
          },
          unselected: { fontFamily: PRIMARY_FONT[400], fontSize: '14px' },
        },
      },
    },

    nav: {
      activeBackgroundColor: {
        default: `${PRIMARY_PURPLE[70]}`,
      },
      arrowColor: {
        default: `${PRIMARY_PURPLE[40]}`,
      },
      backgroundColor: {
        default: `${PRIMARY_PURPLE[80]}`,
      },
      subSectionBackgroundColor: {
        default: `${PRIMARY_PURPLE[90]}`,
      },
      subSectionTypeText: {
        default: `${BLACK[30]}`,
      },
      textColor: {
        default: '#FFF',
      },
      borderBottom: {
        color: `${BLACK[40]}`,
      },
    },
    timecardDateSelector: {
      palette: {
        defaultChipBgColor: BLACK[100],
        conflictChipBgColor: FEED_BACK.YELLOW_50,
        emptyChipBgColor: BLACK[90],
        selectedChipBgColor: PRIMARY_PURPLE[70],
        emptyFontColor: BLACK[50],
        emptyBorderColor: BLACK[70],
        conflictFontColor: FEED_BACK.YELLOW_100,
        defaultFontColor: BLACK[0],
      },
      typography: {
        dayFontFamily: 'Barlow-500',
        dateFontFamily: 'Barlow-600',
        defaultFontSize: '12px',
      },
    },

    timecardDetailPunchCard: {
      palette: {
        dayandDateFontColor: BLACK[50],
      },
      typography: {
        dayAndDateFontFamily: `Barlow-600`,
        dayAndDateFontSize: `24px`,
      },
    },

    workflow: {
      palette: {
        background: {
          default: {
            confirmationPageBackgroundColor: `${PRIMARY_PURPLE[70]}`,
            transitionPageBackgroundColor: `${BLACK[0]}`,
          },
          dark: {
            confirmationPageBackgroundColor: `${PRIMARY_PURPLE[70]}`,
            transitionPageBackgroundColor: `${BLACK[0]}`,
          },
        },
        font: {
          default: {
            defaultLastPageFontColor: `${BLACK[0]}`,
            subHeaderFontColor: BLACK[80],
            transitionPageFontColor: `${BLACK[100]}`,
          },
          dark: {
            defaultLastPageFontColor: `${BLACK[0]}`,
            transitionPageFontColor: `${BLACK[100]}`,
          },
        },
      },
      typography: {
        defaultFont: PRIMARY_FONT[400],
        boldFont: PRIMARY_FONT[800],
        defaultTitleFontSize: '30px',
        defaultFontSize: '18px',
        defaultFontWeight: 800,
        titleLineHeight: '34px',
      },
      timecardEdit: {
        palette: {
          dayAndDatefontColor: BLACK[100],
          descriptionNameFontColor: BLACK[70],
          shiftTimeFontColor: PRIMARY_PURPLE[70],
        },
        typography: {
          dayAndDatefontFamily: 'Barlow-700',
          dayAndDateFontSize: '16px',
          descriptionFontFamily: 'Barlow-500',
          descriptionFontSize: '14px',
          shiftTimeFontFamily: 'Barlow-700',
          shiftTImeFontSize: '20px',
        },
      },
    },
    userDetailAccordion: {
      companyText: {
        palette: {
          color: BLACK[70],
        },
        typography: { fontFamily: PRIMARY_FONT[300], fontSize: '12px', lineHeight: '8px' },
      },
      corporation: {
        palette: {
          color: '#000000',
        },
        typography: { fontFamily: PRIMARY_FONT[400], fontSize: '16px', lineHeight: '24px' },
      },
      emptyTitleContent: {
        palette: {
          color: '#000000',
        },
        typography: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '16px',
          lineHeight: '18px',
        },
      },
      emptyBodyContent: {
        palette: {
          color: '#000000',
        },
        typography: {
          fontFamily: PRIMARY_FONT[300],
          fontSize: '10px',
          lineHeight: '18px',
        },
      },
    },
    workerAccordion: {
      title: {
        palette: {
          color: '#000000',
        },
        typography: { fontFamily: PRIMARY_FONT[700], fontSize: '20px', lineHeight: '24px' },
      },
      count: {
        palette: {
          color: PRIMARY_PURPLE[70],
        },
        typography: { fontFamily: PRIMARY_FONT[500], fontSize: '60px', lineHeight: '57.43px' },
      },
      addPopupButton: {
        palette: {
          color: PRIMARY_PURPLE[70],
        },
        typography: { fontFamily: PRIMARY_FONT[500], fontSize: '16px', lineHeight: '24px' },
      },
      modalTitle: {
        palette: {
          color: BLACK[80],
        },
        typography: { fontFamily: PRIMARY_FONT[700], fontSize: '20px', lineHeight: '24px' },
      },
      modalBody: {
        palette: {
          color: BLACK[70],
        },
        typography: { fontFamily: PRIMARY_FONT[400], fontSize: '16px', lineHeight: '24px' },
      },
    },
    notification: {
      palette: {
        light: `${PRIMARY_PURPLE[20]}`,
        middle: `${PRIMARY_PURPLE[30]}`,
        markRead: `${BLACK[50]}`,
        unRead: `${PRIMARY_PURPLE[80]}`,
      },
      header: {
        fontFamily: PRIMARY_FONT[800],
        fontSize: '30px',
      },
      mainText: {
        fontFamily: PRIMARY_FONT[400],
        fontSize: '14px',
      },
      boldText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '12px',
      },
      deleteText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '14px',
      },
      markReadText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
    },
    profileMenu: {
      palette: {
        border: `${BLACK[20]}`,
        hover: `${PRIMARY_PURPLE[20]}`,
        white: `${BLACK[0]}`,
      },
      font: {
        selection: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '16px',
        },
        email: {
          fontFamily: PRIMARY_FONT[600],
          fontSize: '14px',
        },
        role: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '14px',
        },
      },
    },
    rosterWidget: {
      palette: {
        tabTextColor: `${PRIMARY_PURPLE[40]}`,
        tabWhiteTextColor: `${BLACK[0]}`,
        selected: {
          fontColor: `${BLACK[0]}`,
          backgroundColor: `${PRIMARY_PURPLE[70]}`,
        },
      },
      typography: {
        selected: {
          fontFamily: 'Barlow-600',
          fontSize: '14px',
        },
        cancelledPlacementHeader: {
          fontSize: '30px',
          fontColor: `${BLACK[100]}`,
          fontFamily: 'Barlow-800',
        },
        cancelledPlacementKey: {
          fontSize: '18px',
          fontColor: `${BLACK[100]}`,
          fontFamily: 'Barlow-700',
        },
        cancelledPlacementVal: {
          fontSize: '16px',
          fontColor: `${BLACK[80]}`,
          fontFamily: PRIMARY_FONT[400],
        },
      },
    },
    termsAndConditions: {
      typography: {
        description: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 400,
          color: BLACK[80],
        },

        checkboxLabel: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 400,
          color: BLACK[80],
        },
      },
    },
    trainingModule: {
      typography: {
        header: {
          fontSize: '30px',
          fontFamily: PRIMARY_FONT[800],
        },
        text: {
          fontSize: '16px',
          fontFamily: PRIMARY_FONT[500],
        },
      },
      palette: {
        header: BLACK[80],
        text: BLACK[70],
      },
    },
    gpsMapMarkersFilterSidebar: {
      bgColor: BLACK[90],
      iconButtonColor: BLACK[50],
      title: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '36px',
        fontColor: BLACK[30],
      },
      noDataText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '20px',
        fontColor: BLACK[20],
      },
      checkbox: {
        color: ACTION_BLUE.ACTION_CONTRAST_BLUE,
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
        fontColor: BLACK[40],
      },
    },
    userCard: {
      editableEmailStyle: {
        width: '75%',
        '& .MuiOutlinedInput-root': {
          borderRadius: 1,
          height: '40px',
        },
      },
      subTitleStyle: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
        color: BLACK[80],
      },
      contentStyle: {
        fontFamily: PRIMARY_FONT[300],
        fontSize: '14px',
        color: BLACK[100],
      },
      detailSubTitleStyle: {
        fontFamily: PRIMARY_FONT[300],
        fontSize: '16px',
        color: BLACK[100],
      },
      cardStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '450px',
        width: '340px',
      },
      cardHeader: {
        backgroundColor: BLACK[10],
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      riskText: { color: FEED_BACK.RED_75, fontFamily: PRIMARY_FONT[500], fontSize: '16px' },
      detailHeader: { fontFamily: PRIMARY_FONT[500], fontSize: '16px', color: BLACK[80] },
      lastLoginHeader: { fontFamily: PRIMARY_FONT[400], fontSize: '10px' },
      lastLoginTime: {
        fontFamily: PRIMARY_FONT[300],
        fontSize: '10px',
      },
      roleName: {
        fontSize: '20px',
        fontFamily: PRIMARY_FONT[700],
        color: PRIMARY_PURPLE[70],
        textDecoration: 'underline',
        wordWrap: 'break-word',
        overflowWrap: 'anywhere',
      },
      workerTitle: { fontSize: '14px', color: BLACK[70] },
      copyUser: {
        cursor: 'pointer',
        fontFamily: PRIMARY_FONT[400],
        fontSize: '10px',
        color: BLACK[80],
      },
      corporationStyle: {
        color: PRIMARY_PURPLE[70],
        fontSize: '16px',
        textDecoration: 'underline',
        wordWrap: 'break-word',
        overflowWrap: 'anywhere',
      },
      corporationTitleStyle: {
        color: BLACK[70],
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
      emailStyle: {
        fontFamily: PRIMARY_FONT[300],
        fontSize: '14px',
        color: BLACK[100],
        minWidth: 0,
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
      agencyStyle: {
        fontFamily: PRIMARY_FONT[300],
        fontSize: '16px',
        color: PRIMARY_PURPLE[70],
        textDecoration: 'underline',
      },
      deactivateButtonStyle: {
        mt: 1,
        backgroundColor: PRIMARY_PURPLE[70],
        color: 'white',
        fontFamily: PRIMARY_FONT[700],
        fontSize: '16px',
        textTransform: 'none',
        '&:hover': { backgroundColor: `${PRIMARY_PURPLE[90]}` },
      },
      errorMessage: {
        mt: 1,
        color: FEED_BACK.RED_75,
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
      agencyHeaderStyle: {
        fontSize: '20px',
        fontFamily: PRIMARY_FONT[700],
        color: BLACK[80],
      },
      agencyChangeButton: {
        backgroundColor: PRIMARY_PURPLE[70],
        color: 'white',
        fontFamily: PRIMARY_FONT[700],
        textTransform: 'none',
      },
      backButton: {
        color: BLACK[70],
        fontFamily: PRIMARY_FONT[700],
        borderColor: BLACK[70],
      },
      agencyTextfieldStyle: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: PRIMARY_PURPLE[70],
        },
      },
      agencyButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 2,
        mt: 2,
        '& .MuiButton-root': {
          minWidth: '100px',
        },
      },
      headerStyle: {
        fontSize: '20px',
        fontFamily: PRIMARY_FONT[700],
        my: 1,
      },
      phoneInput: {
        width: '75%',
        '& .MuiOutlinedInput-root': {
          borderRadius: 1,
          height: '40px',
        },
      },
      phoneContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    userDetail: {
      cardStyle: {
        width: '340px',
        borderRadius: '16px',
        p: 1,
      },
      headerStyle: {
        fontSize: '20px',
        fontFamily: PRIMARY_FONT[700],
        my: 1,
      },
      subHeaderStyle: {
        fontSize: '16px',
        fontFamily: PRIMARY_FONT[500],
        my: 0.5,
      },
      subTitle: {
        fontSize: '20px',
      },
      subText: {
        fontSize: '18px',
      },
      textStyle: {
        fontSize: '16px',
      },
      nameStyle: {
        fontSize: '10px',
        my: 0.5,
        marginLeft: 4,
      },
      checkBoxLabel: {
        color: BLACK[100],
        fontSize: '16px',
        fontFamily: PRIMARY_FONT[400],
      },
      underlineStyle: {
        color: PRIMARY_PURPLE[70],
        textDecoration: 'underline',
        fontSize: '16px',
      },
      deactivateButtonStyle: {
        mt: 3,
        backgroundColor: PRIMARY_PURPLE[70],
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        '&:hover': { backgroundColor: `${PRIMARY_PURPLE[90]}` },
      },
      subTitleStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '25%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxHeight: '80vh',
        overflowY: 'auto',
      },
      iconStyle: { fontSize: 40 },
      strikeCardStyle: {
        mb: 2,
        borderRadius: 2,
        border: `1px solid ${BLACK[40]}`,
        elevation: 0,
        boxShadow: 'none',
      },
      strikeContainer: {
        height: 'calc(100% - 140px)',
        overflowY: 'auto',
        padding: 2,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
      suspensionCardStyle: {
        mb: 2,
        borderRadius: 2,
        elevation: 0,
        boxShadow: 'none',
        border: `1px solid ${FEED_BACK.RED_75}`,
        marginY: 2,
      },
      attendanceContainer: {
        borderRadius: 3,
        border: `1px solid ${BLACK[40]}`,
        height: 'auto',
        width: '100%',
        marginLeft: 2,
      },
      onboardingHeader: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '24px',
      },
      onboardingSubText: {
        fontFamily: PRIMARY_FONT[400],
        fontSize: '20px',
        mb: 1,
      },
    },
    remainingActionsCounterCard: {
      buttonStyle: {
        backgroundColor: 'primary.main',
        fontFamily: PRIMARY_FONT[700],
        textTransform: 'none',
      },
      bodyStyle: { fontSize: '20px', textAlign: 'left', ml: 1, lineHeight: '24px' },
      headerStyle: { fontSize: '24px', fontFamily: PRIMARY_FONT[700] },
      subHeaderStyle: { fontSize: '20px', fontFamily: PRIMARY_FONT[400] },
      textContainer: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    timeCardOverviewCard: {
      headerStyle: { fontSize: '24px', fontFamily: PRIMARY_FONT[700] },
      itemNameText: { fontSize: '20px', fontFamily: PRIMARY_FONT[400] },
      itemValueText: { fontSize: '20px', fontFamily: PRIMARY_FONT[500] },
    },
  },
  palette: {
    primary: {
      main: `${PRIMARY_PURPLE[70]}`,
      light: `${PRIMARY_PURPLE[40]}`,
      dark: `${PRIMARY_PURPLE[100]}`,
    },
    secondary: {
      main: `${BLACK[80]}`,
      light: `${BLACK[70]}`,
      dark: `${BLACK[90]}`,
    },
    subtext: {
      main: `${BLACK[50]}`,
      light: `${BLACK[40]}`,
      dark: `${BLACK[60]}`,
    },
    background: {
      default: `${BLACK[0]}`,
      dark: `${BLACK[90]}`,
      paper: `${BLACK[0]}`,
      black: `${BLACK[100]}`,
    },
    text: {
      primary: `${BLACK[100]}`,
      secondary: `${BLACK[40]}`,
      notificationTime: `${BLACK[70]}`,
    },
    neutral: {
      main: `${BLACK[60]}`,
      contrastText: `${BLACK[10]}`,
      contrastBgColor: `${BLACK[30]}`,
    },
    border: {
      grey: `${BLACK[40]}`,
    },
  },
  views: {
    batchJobCreate: {
      typography: {
        requireApprovalTitle: {
          fontSize: '18px',
          fontColor: BLACK[100],
          fontFamily: 'Barlow-700',
        },
        requireApprovalDescription: {
          fontSize: '16px',
          fontFamily: PRIMARY_FONT[400],
          fontColor: BLACK[80],
        },
      },
      palette: {},
    },
    createCorporation: {
      typography: {
        title: {
          fontSize: '40px',
          fontFamily: 'Barlow-600',
          lineHeight: '42px',
        },
        nameTitle: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '20px',
          lineHeight: '24px',
        },
      },
      palette: {
        title: {
          fontColor: BLACK[80],
        },
        nameTitle: {
          fontColor: BLACK[80],
        },
      },
    },
    checkedIn: {
      typography: {
        checkedInText: {
          fontSize: '16px',
          fontColor: BLACK[10],
          fontFamily: 'Barlow-700',
        },
      },
      palette: {},
    },
    mobileDirect: {
      typography: {
        mobileDirectTitle: {
          fontSize: '40px',
          fontColor: BLACK[50],
          fontFamily: PRIMARY_FONT[500],
          lineHeight: '50px',
          textAlign: 'center',
        },
        mobileDirectSubTitle: {
          fontSize: '24px',
          fontFamily: PRIMARY_FONT[400],
          fontColor: BLACK[70],
          lineHeight: '32px',
          textAlign: 'center',
        },
      },
      palette: {},
    },
    reporting: {
      palette: {
        paper: {
          backgroundColor: {
            default: `${BLACK[10]}`,
            dark: {},
          },
        },
        primary: `${PRIMARY_PURPLE[70]}`,
        role: {
          default: {
            primary: `${BLACK[10]}`,
          },
          admin: {
            primary: `${PRIMARY_PURPLE[70]}`,
            secondary: `${PRIMARY_PURPLE[10]}`,
          },
          recruiter: {
            primary: `${FEED_BACK.ORANGE}`,
            secondary: `${FEED_BACK.ORANGE_90}`,
          },
          customer: {
            primary: `${FEED_BACK.GREEN_50}`,
            secondary: `${FEED_BACK.GREEN_90}`,
          },
        },
      },
      linearProgress: {
        filled: `${PRIMARY_PURPLE[90]}`,
        notFilled: `${PRIMARY_PURPLE[70]}`,
      },
      lineChart: {
        line: `${PRIMARY_PURPLE[70]}`,
        dot: `${PRIMARY_PURPLE[100]}`,
      },
      attendance: {
        attendanceColor: `${PRIMARY_PURPLE[70]}`,
        noCallNoShowColor: `${PRIMARY_PURPLE[100]}`,
        insubordinationColor: `${PRIMARY_PURPLE[100]}`,
        misconductColor: `${PRIMARY_PURPLE[50]}`,
        breachOfCompanyPolicyColor: `${FEED_BACK.ORANGE}`,
        walkedOffColor: `${FEED_BACK.YELLOW_50}`,
        unauthorizedPossessionColor: `${FEED_BACK.YELLOW_80}`,
        failureToFollowSupervisorColor: `${FEED_BACK.GREEN_50}`,
        failureToFollowProceduresColor: `${FEED_BACK.GREEN_100}`,
      },

      typography: {
        header: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '38px',
          fontWeight: 800,
          fontColor: `${BLACK[100]}`,
        },
        chartLegendText: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '14px',
          fontWeight: 500,
          fontColor: `${BLACK[70]}`,
        },
        metricsTitle: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
        metricsHeader: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '22px',
          fontWeight: 700,
          fontColor: `${BLACK[80]}`,
        },
        card: {
          content: {
            fontFamily: PRIMARY_FONT[400],
            fontSize: '30px',
            fontWeight: 800,
            fontColor: `${BLACK[100]}`,
          },
          label: {
            fontFamily: PRIMARY_FONT[400],
            fontSize: '14px',
            fontWeight: 500,
            fontColor: `${BLACK[70]}`,
          },
        },
      },
    },
  },
  select: {
    main: BLACK[80],
  },
  button: {
    palette: {
      primary: `${PRIMARY_PURPLE[60]}`,
      hover: `${PRIMARY_PURPLE[80]}`,
      secondary: `${ACTION_BLUE.ACTION_BLUE_REST}`,
      contrast: `${ACTION_BLUE.ACTION_CONTRAST_REST}`,
      delete: `${FEED_BACK.RED_50}`,
      deleteHover: `${FEED_BACK.RED_100}`,
      cancel: `${PRIMARY_PURPLE[90]}`,
      focus: `${PRIMARY_PURPLE[60]}`,
      fill: `${BLACK[40]}`,
      text: `${BLACK[0]}`,
      whiteIcon: `${BLACK[0]}`,
      purpleText: `${PRIMARY_PURPLE[70]}`,
      greyBgColor: `${BLACK[10]}`,
    },
    defaultBorderRadius: '4px',
    fontFamily: PRIMARY_FONT[700],
    fontSize: '14px',
  },

  chip: {
    default: {
      fontSize: '14px',
      iconSize: '10px',
      largeFontSize: '18px',
      largeIconSize: '14px',
      fontFamily: 'Barlow-600',
    },
    color: {
      default: BLACK[0],
      success: `${FEED_BACK.GREEN_100}`,
      danger: `${FEED_BACK.RED_100}`,
    },
    bgColor: {
      darkBgColor: `${PRIMARY_PURPLE[90]}`,
      lightBgColor: `${PRIMARY_PURPLE[30]}`,
      defaultBgColor: `${PRIMARY_PURPLE[70]}`,
      successBgColor: `${FEED_BACK.GREEN_10}`,
      dangerBgColor: `${FEED_BACK.RED_10}`,
    },
    hover: {
      defaultHoverColor: `${PRIMARY_PURPLE[60]}`,
      darkHoverColor: `${PRIMARY_PURPLE[80]}`,
      lightHoverColor: `${PRIMARY_PURPLE[20]}`,
      successHoverColor: `${FEED_BACK.GREEN_10}`,
      dangerHoverColor: `${FEED_BACK.RED_10}`,
    },
    selected: {
      background: {
        light: `${PRIMARY_PURPLE[70]}`,
      },
      font: {
        light: `${BLACK[0]}`,
      },
    },
    unSelected: {
      background: {
        light: `${PRIMARY_PURPLE[30]}`,
      },
      font: {
        light: `${PRIMARY_PURPLE[80]}`,
      },
    },
  },

  label: {
    font: 'Barlow-700',
    positionTemplate: {
      bgColor: {
        approved: FEED_BACK.GREEN_50,
        not_approved: PRIMARY_PURPLE[30],
      },
      color: {
        approved: FEED_BACK.GREEN_100,
        not_approved: PRIMARY_PURPLE[80],
      },
      font: 'Barlow-700',
    },
    users: {
      bgColor: {
        active: FEED_BACK.GREEN_10,
        inactive: BLACK[40],
        unverified: BLACK[40],
        dna: FEED_BACK.RED_10,
        dnr: FEED_BACK.RED_10,
        locked: FEED_BACK.RED_10,
      },
      color: {
        active: FEED_BACK.GREEN_100,
        inactive: BLACK[70],
        unverified: BLACK[70],
        dna: FEED_BACK.RED_100,
        dnr: FEED_BACK.RED_100,
        locked: FEED_BACK.RED_100,
      },
      font: 'Barlow-700',
    },
    job_orders: {
      bgColor: {
        active: FEED_BACK.GREEN_10,
        complete: FEED_BACK.GREEN_50,
        open: FEED_BACK.GREEN_10,
        cancelled: FEED_BACK.RED_10,
      },
      color: {
        active: FEED_BACK.GREEN_100,
        complete: FEED_BACK.GREEN_100,
        open: FEED_BACK.GREEN_100,
        cancelled: FEED_BACK.RED_100,
      },
      font: 'Barlow-700',
    },
    attendance: {
      bgColor: {
        absent: FEED_BACK.RED_10,
        excusedAbsent: FEED_BACK.GREEN_10,
        cancelled: FEED_BACK.RED_10,
      },
      color: {
        absent: FEED_BACK.RED_100,
        excusedAbsent: FEED_BACK.GREEN_100,
        cancelled: FEED_BACK.RED_100,
      },
      font: 'Barlow-700',
    },
    timekeeping: {
      bgColor: {
        approved: FEED_BACK.GREEN_50,
        conflict: FEED_BACK.YELLOW_50,
        pending: PRIMARY_PURPLE[30],
        disputed: FEED_BACK.YELLOW_50,
        resolved: FEED_BACK.GREEN_50,
        finalized: PRIMARY_PURPLE[70],
        overtime: FEED_BACK.YELLOW_50,
      },
      color: {
        approved: FEED_BACK.GREEN_100,
        conflict: FEED_BACK.YELLOW_100,
        pending: PRIMARY_PURPLE[80],
        disputed: FEED_BACK.YELLOW_100,
        resolved: FEED_BACK.GREEN_100,
        finalized: PRIMARY_PURPLE[20],
        overtime: FEED_BACK.YELLOW_100,
      },
      font: 'Barlow-700',
    },
  },
  datePicker: {
    font: {
      defaultFont: PRIMARY_FONT[400],
      boldFont: 'Barlow-800',

      headerFontSize: '20px',
      date: {
        fontSize: '16px',
        fontColor: `${BLACK[100]}`,
      },
      day: {
        fontSize: '14px',
        fontColor: `${BLACK[80]}`,
      },
    },
    iconButton: {
      light: {
        color: `${ACTION_BLUE.ACTION_BLUE}`,
      },
    },
    bgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    dateBgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    selected: {
      backgroundColor: {
        light: `${PRIMARY_PURPLE[30]}`,
      },
    },
  },
  jobRangePicker: {
    borderRadius: '16px',
    font: {
      defaultFont: PRIMARY_FONT[400],
      boldFont: 'Barlow-800',

      headerFontSize: '20px',
      date: {
        fontSize: '16px',
        fontColor: `${BLACK[100]}`,
      },
      day: {
        fontSize: '14px',
        fontColor: `${BLACK[80]}`,
      },
    },
    iconButton: {
      light: {
        color: `${ACTION_BLUE.ACTION_BLUE}`,
      },
    },
    bgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    dateBgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    selected: {
      backgroundColor: {
        dark: `${PRIMARY_PURPLE[90]}`,
      },
    },
    warning: {
      backgroundColor: `${FEED_BACK.ORANGE}`,
    },
    extraDay: {
      typography: {
        font: PRIMARY_FONT[400],
        boldFont: 'Barlow-800',
        fontSize: '16px',
        fontColor: BLACK[100],
      },
    },
  },

  export: {
    checkbox: {
      default: `${ACTION_BLUE.ACTION_BLUE}`,
    },
    typography: {
      font: PRIMARY_FONT[400],
      boldFont: 'Barlow-800',
    },
  },

  textField: {
    errorColor: `${FEED_BACK.RED_50}`,
    endLabel: {
      typography: {
        color: `${BLACK[100]}`,
        font: `${PRIMARY_FONT[500]}`,
        fontSize: '14px',
      },
    },

    borderColor: {
      focused: `${PRIMARY_PURPLE[70]}`,
      error: `${FEED_BACK.RED_50}`,
    },
    inputLabel: {
      iconColor: {
        enabled: `${PRIMARY_PURPLE[70]}`,
        disabled: `${BLACK[50]}`,
      },
      primary: {
        light: `${BLACK[20]}`,
        white: `${BLACK[0]}`,
      },
      outlined: {
        focused: `${BLACK[70]}`,
      },
    },
    background: {
      light: `${BLACK[10]}`,
      white: `${BLACK[0]}`,
      grey: `${BLACK[20]}`,
    },
    darkBkColor: `${BLACK[80]}`,
    error: `${FEED_BACK.RED_50}`,
    typography: {
      input: {
        fontFamily: PRIMARY_FONT[600],
        fontSize: '16px',
      },
    },
  },
  selectDropdown: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },
    listItemCheckbox: {
      color: `${ACTION_BLUE.ACTION_BLUE}`,
    },
    icon: {
      color: `${ACTION_BLUE.ACTION_BLUE}`,
    },
  },
  searchBox: {
    bgColor: {
      darkBkColor: `${BLACK[100]}`,
      lightBkColor: `${BLACK[0]}`,
      defaultBgColor: `${BLACK[0]}`,
      greyBkColor: `${BLACK[10]}`,
    },
    textBox: {
      darkBkColor: `${BLACK[90]}`,
    },
    font: {
      fontFamily: PRIMARY_FONT[400],
      fontSizeDefault: '14px',
      defaultHeaderSize: '17px',
      darkFontColor: `${BLACK[30]}`,
      defaultFontColor: `${BLACK[100]}`,
      lightFontColor: `${BLACK[100]}`,
      preDefinedTextFontFamily: 'Barlow-700',
      preDefinedTextFontColor: `${BLACK[100]}`,
      preDefinedTextFontSize: '18px',
      sectionHeaderFontFamily: 'Barlow-700',
      sectionHeaderFontSize: '18px',
      sectionHeaderFontColor: BLACK[100],
      subSectionFontColor: BLACK[80],
      subSectionFontFamily: 'Barlow-600',
    },
  },
  searchBoxForFilter: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },
    chipBgColor: `${PRIMARY_PURPLE[40]}`,
  },

  searchEmployeeBar: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },
    chipBgColor: `${PRIMARY_PURPLE[40]}`,
  },
  dateRangePickerFilter: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },

    chipBgColor: `${PRIMARY_PURPLE[40]}`,
    dateDisplayBox: {
      bgColor: `${BLACK[20]}`,
      typography: {
        fontSize: '16px',
        fontWeight: 600,
        color: `${BLACK[100]}`,
      },
    },
  },
  timeRangePickerFilter: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },

    chipBgColor: `${PRIMARY_PURPLE[40]}`,
    dateDisplayBox: {
      bgColor: `${BLACK[20]}`,
      typography: {
        fontSize: '16px',
        fontWeight: 600,
        color: `${BLACK[100]}`,
      },
    },
  },
  popoverFilter: {
    bgColor: `${BLACK[10]}`,
  },

  tab: {
    default: {
      bgColor: `${PRIMARY_PURPLE[20]}`,
      bgText: `${PRIMARY_PURPLE[70]}`,
      selected: `${PRIMARY_PURPLE[70]}`,
      selectedText: `${BLACK[10]}`,
      fontFamily: PRIMARY_FONT[400],
      boldFontFamily: 'Barlow-800',
      boldFontSize: '24px',
    },
    dark: {
      bgColor: `${BLACK[100]}`,
      bgText: `${PRIMARY_PURPLE[40]}`,
      selected: `${PRIMARY_PURPLE[70]}`,
      selectedText: `${BLACK[10]}`,
    },
  },

  dataGrid: {
    default: {
      fontSize: '14px',
      iconSize: '10px',
      largeFontSize: '18px',
      largeIconSize: '14px',
      fontFamily: PRIMARY_FONT[400],
      boldFont: 'Barlow-800',
      headerFont: 'Barlow-600',
      textColor: `${BLACK[90]}`,
      textFont: 'Barlow-500',
      headerColor: `${BLACK[100]}`,
      selectedTextColor: `${BLACK[0]}`,
    },
    bgColor: {
      columnHeaderBgColor: `${BLACK[30]}`,
      oddRowBgColor: `${BLACK[10]}`,
      selectedBgColor: `${BLACK[90]}`,
      tabIndicatorColor: `${PRIMARY_PURPLE[70]}`,
      selectedRowBgColor: `${BLACK[0]}`,
    },

    hover: {
      defaultHoverColor: `${PRIMARY_PURPLE[20]}`,
      selectedHoverColor: `${BLACK[80]}`,
    },

    jobInfo: {
      corpColor: `${BLACK[70]}`,
      jobColor: `${BLACK[100]}`,
      light: `${BLACK[20]}`,
      fontFamily: 'Barlow-600',
      longTermFontFamily: 'Barlow-700',
      fontSize: '20px',
      smallFontSize: '12px',
    },
    positionInfo: {
      descriptionColor: `${BLACK[70]}`,
      positionColor: `${BLACK[100]}`,
      fontFamily: 'Barlow-600',
    },
    userInfo: {
      roleColor: `${BLACK[70]}`,
      nameColor: `${BLACK[100]}`,
      fontFamily: 'Barlow-600',
    },
  },
  skillsAndCerts: {
    palette: {
      modalSubText: BLACK[70],
      modalHeader: BLACK[80],
      modalBgColor: BLACK[0],
      modalBorder: BLACK[20],
      newTextBgColor: BLACK[20],
    },
    typography: {
      modalSubText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
      modalHeader: {
        fontSize: '30px',
        fontFamily: PRIMARY_FONT[800],
      },
      placeholder: {
        fontFamily: PRIMARY_FONT[400],
        fontSize: '16px',
      },
    },
  },
  shiftSelector: {
    typography: {
      additionalShiftDescriptionFontFamily: PRIMARY_FONT[400],
      additionalShiftDescriptionFontSize: '14px',
      additionalShiftDescriptionFontColor: BLACK[80],
      additionalShiftDescriptionLineHeight: '18px',
    },
  },

  shiftPicker: {
    typography: {
      defaultFont: PRIMARY_FONT[400],
      boldFont: 'Barlow-700',
      headerFontColor: `${BLACK[100]}`,
      headerFontSize: '18px',
      defaultFontSize: '16px',
      assignedShifts: 'Barlow-500',
      shiftNameHeader: '14px',
      shiftTimeFont: 'Barlow-600',
    },
    palette: {
      assignedShifts: {
        backgroundColor: `${PRIMARY_PURPLE[10]}`,
        textColor: `${PRIMARY_PURPLE[70]}`,
        emptyTextColor: `${BLACK[70]}`,
        shiftNameHeaderColor: `${BLACK[80]}`,
      },
    },
  },
  emptyCard: {
    bgColor: `${BLACK[10]}`,
    header: {
      typography: {
        font: PRIMARY_FONT[700],
        fontSize: '32px',
        color: `${BLACK[80]}`,
      },
    },
    body: {
      typography: {
        font: PRIMARY_FONT[500],
        fontSize: '18px',
        color: `${BLACK[70]}`,
      },
    },
  },
  icon: {
    checkOutlinedIcon: {
      color: `${BLACK[0]}`,
      fontSize: '30px',
    },
  },
  accountDeactivationAcknowledgmentCheckbox: {
    header: {
      fontSize: '18px',
      fontColor: BLACK[100],
      font: PRIMARY_FONT[700],
    },
    subHeader: {
      fontSize: '16px',
      fontColor: BLACK[80],
      font: PRIMARY_FONT[400],
    },
    checkboxColor: ACTION_BLUE.ACTION_CONTRAST_BLUE,
    labels: {
      fontSize: '16px',
      fontColor: BLACK[100],
      font: PRIMARY_FONT[500],
    },
  },
  jobOrders: {
    detail: {
      header: {
        titleText: {
          jobView: {
            active: { color: FEED_BACK.GREEN_70, fontFamily: PRIMARY_FONT[500] },
            upcoming: { color: BLACK[80], fontFamily: PRIMARY_FONT[500] },
            cancelled: { color: BLACK[80], fontFamily: PRIMARY_FONT[700] },
            completed: { color: PRIMARY_PURPLE[60], fontFamily: PRIMARY_FONT[500] },
          },
          rosterView: { color: BLACK[100], fontFamily: PRIMARY_FONT[500] },
        },
      },

      roster: {
        rosterCard: {
          bgColorMain: BLACK[0],
          headerStatus: {
            checked: PRIMARY_PURPLE[20],
            uncheckedPrimary: BLACK[0],
            uncheckedSecondary: BLACK[40],
          },
          checkBox: {
            root: BLACK[100],
            checked: PRIMARY_PURPLE[70],
          },
          linkButtonColor: BLACK[100],
          candidateNameTextStyles: {
            color: BLACK[100],
            fontFamily: PRIMARY_FONT[700],
            fontSize: '16px',
          },
          punchHeaderTextStyles: {
            fontSize: '10px',
            color: BLACK[90],
            fontFamily: PRIMARY_FONT[400],
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
          },
          punchDetailTextStyles: {
            fontSize: '12px',
            color: BLACK[100],
            fontFamily: PRIMARY_FONT[400],
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
          },
          statusTextColors: {
            approved: FEED_BACK.GREEN_80,
            resolved: FEED_BACK.GREEN_80,
            disputed: BLACK[90],
            finalized: PRIMARY_PURPLE[90],
          },
        },
        employeeRosterCard: {
          headerText: {
            fontFamily: 'Barlow',
            color: BLACK[90],
            fontWeight: 500,
          },
          checkInStatus: {
            fontFamily: 'Barlow',
            color: RED[50],
          },
          employeeName: {
            fontFamily: PRIMARY_FONT[300],
          },
          punchTime: {
            fontFamily: 'Barlow',
            color: BLACK[100],
            fontWeight: 500,
          },
          actionButton: {
            actionColor: PRIMARY_COLOR[70],
            borderColor: PRIMARY_COLOR[80],
            hoverColor: PRIMARY_COLOR[100],
          },
        },
      },
    },
    createPage: {
      header: {
        fontSize: '20px',
        fontColor: BLACK[100],
        fontFamily: PRIMARY_FONT[700],
        lineHeight: '20px',
      },
      sectionHeader: {
        fontSize: '18px',
        fontColor: BLACK[100],
        font: PRIMARY_FONT[700],
      },
      subHeader: {
        fontSize: '18px',
        fontColor: BLACK[100],
        fontFamily: PRIMARY_FONT[500],
      },
      overtimeAlert: {
        fontSize: '16px',
        fontColor: BLACK[100],
        font: PRIMARY_FONT[400],
        boldWeight: 700,
        iconColor: `${FEED_BACK.RED_50}`,
      },
      sectionDescription: {
        fontSize: '16px',
        fontColor: BLACK[100],
        font: PRIMARY_FONT[400],
      },
      addJobHeaderTypography: {
        fontSize: '24px',
        fontColor: PRIMARY_COLOR[70],
        font: PRIMARY_FONT[800],
      },
      ongoingCheckBox: {
        backgroundColor: BLACK[20],
        checkboxColor: '#494949',
        typography: {
          font: PRIMARY_FONT[500],
          fontSize: '16px',
          color: BLACK[100],
        },
      },
      cadence: {
        selectedBackgroundColor: PRIMARY_COLOR[40],
        unSelectedBackgroundColor: BLACK[0],
        typography: {
          color: BLACK[100],
          font: PRIMARY_FONT[500],
          fontSize: '14px',
        },
        wrapperBackgroundColor: BLACK[20],
        errorColor: `${FEED_BACK.RED_50}`,
      },
    },
    createCard: {
      closeButtonTypography: {
        fontSize: '16px',
        fontColor: BLACK[70],
        font: PRIMARY_FONT[600],
      },
    },
  },
});

export default theme;

export const drawerWidth = '240px';
export const drawerWidthHidden = `calc(${theme.spacing(9)} + 1px)`;
export const drawerWidthInternal = drawerWidth - 1;
export const groupListCompanyImageSize = { height: '70px', width: '70px' };
export const dashboardCompanyImageSize = { height: '50px', width: '50px' };
export const listHeaderHeight = '40px';
export const paperElevation = 1;
export const dataGridFiltersHeight = '40px';
export const tabHeight = '35px';
export const navigationBarHeight = '55px';
export const mobileViewContentHeight = `calc(80vh - ${navigationBarHeight})`;
