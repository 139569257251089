import * as React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

const TwoStrikesIcon = ({ sx }) => (
  <SvgIcon sx={sx}>
    <svg width="49" height="17" viewBox="0 0 49 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.3131 2.46622C5.41363 1.73087 6.70751 1.33838 8.03111 1.33838C8.90995 1.33838 9.78018 1.51148 10.5921 1.8478C11.4041 2.18411 12.1418 2.67706 12.7632 3.29849C13.3847 3.91992 13.8776 4.65767 14.2139 5.46961C14.5503 6.28155 14.7233 7.15178 14.7233 8.03062C14.7233 9.35422 14.3309 10.6481 13.5955 11.7486C12.8602 12.8492 11.815 13.7069 10.5921 14.2134C9.36927 14.72 8.02369 14.8525 6.72552 14.5943C5.42735 14.336 4.23491 13.6987 3.29898 12.7627C2.36306 11.8268 1.72568 10.6344 1.46746 9.33621C1.20924 8.03804 1.34177 6.69246 1.84829 5.46961C2.35481 4.24676 3.21257 3.20158 4.3131 2.46622Z"
        fill="#B23E3E"
      />
      <path
        d="M10.536 6.35221C10.7644 6.12387 10.7644 5.75367 10.536 5.52534C10.3077 5.297 9.93748 5.297 9.70915 5.52534L8.03043 7.20405L6.35164 5.52525C6.1233 5.29692 5.7531 5.29692 5.52477 5.52525C5.29643 5.75359 5.29643 6.12379 5.52477 6.35212L7.20356 8.03092L5.52481 9.70968C5.29647 9.93801 5.29647 10.3082 5.52481 10.5365C5.75314 10.7649 6.12334 10.7649 6.35168 10.5365L8.03043 8.85779L9.7091 10.5365C9.93744 10.7648 10.3076 10.7648 10.536 10.5365C10.7643 10.3081 10.7643 9.93793 10.536 9.70959L8.8573 8.03092L10.536 6.35221Z"
        fill="white"
      />
      <path
        d="M20.3746 2.46622C21.4752 1.73087 22.769 1.33838 24.0926 1.33838C24.9715 1.33838 25.8417 1.51148 26.6536 1.8478C27.4656 2.18411 28.2033 2.67706 28.8248 3.29849C29.4462 3.91992 29.9391 4.65767 30.2755 5.46961C30.6118 6.28155 30.7849 7.15178 30.7849 8.03062C30.7849 9.35422 30.3924 10.6481 29.657 11.7486C28.9217 12.8492 27.8765 13.7069 26.6536 14.2134C25.4308 14.72 24.0852 14.8525 22.787 14.5943C21.4889 14.336 20.2964 13.6987 19.3605 12.7627C18.4246 11.8268 17.7872 10.6344 17.529 9.33621C17.2708 8.03804 17.4033 6.69246 17.9098 5.46961C18.4163 4.24676 19.2741 3.20158 20.3746 2.46622Z"
        fill="#B23E3E"
      />
      <path
        d="M26.5975 6.35221C26.8259 6.12387 26.8259 5.75367 26.5975 5.52534C26.3692 5.297 25.999 5.297 25.7707 5.52534L24.092 7.20405L22.4132 5.52525C22.1848 5.29692 21.8146 5.29692 21.5863 5.52525C21.358 5.75359 21.358 6.12379 21.5863 6.35212L23.2651 8.03092L21.5863 9.70968C21.358 9.93801 21.358 10.3082 21.5863 10.5365C21.8147 10.7649 22.1849 10.7649 22.4132 10.5365L24.092 8.85779L25.7706 10.5365C25.999 10.7648 26.3692 10.7648 26.5975 10.5365C26.8258 10.3081 26.8258 9.93793 26.5975 9.70959L24.9188 8.03092L26.5975 6.35221Z"
        fill="white"
      />
      <path
        d="M36.4361 2.46622C37.5367 1.73087 38.8306 1.33838 40.1542 1.33838C41.033 1.33838 41.9032 1.51148 42.7152 1.8478C43.5271 2.18411 44.2649 2.67706 44.8863 3.29849C45.5077 3.91992 46.0007 4.65767 46.337 5.46961C46.6733 6.28155 46.8464 7.15178 46.8464 8.03062C46.8464 9.35422 46.4539 10.6481 45.7185 11.7486C44.9832 12.8492 43.938 13.7069 42.7152 14.2134C41.4923 14.72 40.1467 14.8525 38.8486 14.5943C37.5504 14.336 36.358 13.6987 35.422 12.7627C34.4861 11.8268 33.8487 10.6344 33.5905 9.33621C33.3323 8.03804 33.4648 6.69246 33.9713 5.46961C34.4779 4.24676 35.3356 3.20158 36.4361 2.46622Z"
        fill="#DADADA"
      />
      <path
        d="M42.6591 6.35221C42.8874 6.12387 42.8874 5.75367 42.6591 5.52534C42.4307 5.297 42.0605 5.297 41.8322 5.52534L40.1535 7.20405L38.4747 5.52525C38.2463 5.29692 37.8761 5.29692 37.6478 5.52525C37.4195 5.75359 37.4195 6.12379 37.6478 6.35212L39.3266 8.03092L37.6479 9.70968C37.4195 9.93801 37.4195 10.3082 37.6479 10.5365C37.8762 10.7649 38.2464 10.7649 38.4747 10.5365L40.1535 8.85779L41.8322 10.5365C42.0605 10.7648 42.4307 10.7648 42.659 10.5365C42.8874 10.3081 42.8874 9.93793 42.659 9.70959L40.9803 8.03092L42.6591 6.35221Z"
        fill="#9999AD"
      />
    </svg>
  </SvgIcon>
);

TwoStrikesIcon.propTypes = {
  sx: PropTypes.shape({}),
};

export default TwoStrikesIcon;
