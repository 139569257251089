import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Button } from '@mui/material';

import ResendVerificationEmailModal from '../components/workerComponents/ResendVerificationModal';

import styles from './styles';

const ResendVerificationEmailBtn = ({
  userId,
  email,
  isVerificationEmailButtonDisabled,
  isUserDeactivated,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => setIsOpen(true)}
        disabled={isVerificationEmailButtonDisabled}
        data-testid="resend-email-button"
        fullWidth
        sx={styles.deactivateButtonStyle}
      >
        {LanguageConverter('buttonText.resendVerification')}
      </Button>
      <ResendVerificationEmailModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        userID={userId}
        isDisabled={isUserDeactivated}
        userEmail={email}
      />
    </Box>
  );
};

ResendVerificationEmailBtn.propTypes = {
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isVerificationEmailButtonDisabled: PropTypes.bool,
  isUserDeactivated: PropTypes.bool,
};

export default ResendVerificationEmailBtn;
