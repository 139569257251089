import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';

import { BLACK, PRIMARY_COLOR } from '../../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import {
  epochToDateInReadableFormat,
  minutesFromMidnightToReadableTime,
} from '../../../../../utils';

const Title = ({ text }) => (
  <Typography
    sx={{
      fontSize: '12px',
      color: BLACK[70],
      textTransform: 'uppercase',
      fontFamily: PRIMARY_FONT[300],
    }}
  >
    {text}
  </Typography>
);

Title.propTypes = {
  text: PropTypes.string,
};

const RowCards = ({ jobs = [] }) =>
  jobs.length > 0 ? (
    jobs?.map((job) => (
      <Grid
        container
        sx={{
          border: `1px solid ${BLACK[40]}`,
          borderRadius: '5px',
        }}
        key={job?._id}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            height: '43px',
            backgroundColor: BLACK[10],
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[500],
              paddingLeft: '12px',
            }}
          >
            {LanguageConverter('userDetail.pendingOffers.status')}
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: PRIMARY_FONT[400],
              paddingLeft: '12px',
            }}
          >
            {LanguageConverter('userDetail.pendingOffers.offerPending')}
          </Typography>
        </Grid>
        <Grid container direction="row" flexWrap="nowrap" sx={{ padding: '12px' }}>
          <Grid container alignItems="center" direction="row" flexWrap="nowrap">
            <Grid container direction="column">
              <Title text={LanguageConverter('userDetail.pendingOffers.company')} />
              <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
                {job?.jobOrderInfo?.corporation?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.pendingOffers.position')} />
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: PRIMARY_FONT[300],
                color: PRIMARY_COLOR[70],
                textDecoration: 'underline',
              }}
            >
              {job.jobOrderInfo.name}
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.pendingOffers.startDateEndDate')} />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {`${epochToDateInReadableFormat(job.start)}-${
                job.end
                  ? epochToDateInReadableFormat(job.end)
                  : LanguageConverter('userDetail.pendingOffers.ongoing')
              }`}
            </Typography>
          </Grid>
          <Grid container direction="column">
            <Title text={LanguageConverter('userDetail.pendingOffers.times')} />
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {`${minutesFromMidnightToReadableTime(
                job.jobOrderInfo?.shift?.start
              )}-${minutesFromMidnightToReadableTime(job.jobOrderInfo?.shift?.end)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))
  ) : (
    <Grid
      data-testid="pending-offers-empty-row-card"
      container
      justifyContent="center"
      sx={{
        backgroundColor: BLACK[10],
        borderRadius: '5px',
        height: '40px',
      }}
    />
  );
RowCards.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RowCards;
