import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@material-ui/core';
import { Dialog as MuiDialog, Typography } from '@mui/material/';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { PRIMARY_FONT } from '../../../main/theme/fontConstants';
import Button from '../Button';

const ConfirmationModal = ({
  body,
  cancelBtnText,
  confirmationBtnText,
  isOpen,
  onConfirm,
  onClose,
  title,
}) => {
  const handleOnConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <MuiDialog open={isOpen} onClose={onClose}>
      <Box sx={{ padding: '12px' }}>
        <DialogTitle>
          <Typography sx={{ fontSize: '28px', fontFamily: PRIMARY_FONT[400] }}>{title}</Typography>
        </DialogTitle>

        <DialogContent sx={{ overflowY: 'unset' }}>
          <Typography sx={{ fontSize: '18px', fontFamily: PRIMARY_FONT[400] }}> {body} </Typography>
        </DialogContent>

        <DialogActions sx={{ padding: '20px' }}>
          <Button
            text={cancelBtnText || LanguageConverter('buttonText.cancel')}
            key="handle-cancel-button"
            onClick={onClose}
          />
          <Button
            text={confirmationBtnText || LanguageConverter('buttonText.confirm')}
            key="handle-confirm-button"
            onClick={handleOnConfirm}
          />
        </DialogActions>
      </Box>
    </MuiDialog>
  );
};

ConfirmationModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelBtnText: PropTypes.string,
  confirmationBtnText: PropTypes.string,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default ConfirmationModal;
